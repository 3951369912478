<mat-tab-group mat-align-tabs="center">
  <mat-tab label="Informacion De La Cuenta">
    <form [formGroup]="addressForm" novalidate>
      <mat-card class="shipping-card">
        <h1>Cuenta Bancaria</h1>
        <hr />
        <mat-accordion>
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title> Informacion General </mat-panel-title>
              <mat-panel-description>
                Presiona para mostrar los datos bancario
              </mat-panel-description>
            </mat-expansion-panel-header>
            <hr />
            <!-- Datos de la Empresa... Fila 1 -->
            <div class="row">
              <!-- estado -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Estado" formControlName="estado" readonly="»readonly»" />
                  <mat-icon matSuffix>monetization_on_white_18dp</mat-icon>
                </mat-form-field>
              </div>
              <!-- Solicita -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Solicita" formControlName="nombreResponsable" readonly="»readonly»" />
                  <mat-icon matSuffix>account_circle</mat-icon>
                </mat-form-field>
              </div>
              <!-- jefeAutoriza -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Autoriza" formControlName="jefeAutoriza" readonly="»readonly»" />
                  <mat-icon matSuffix>supervisor_account</mat-icon>
                </mat-form-field>
              </div>
            </div>

            <!-- Fila 2 -->
            <div class="row">
              <!-- fechaSolicitud -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Fecha Solicitud" formControlName="fechaSolicitud"
                    readonly="»readonly»" />
                  <mat-icon matSuffix>calendar_today</mat-icon>
                </mat-form-field>
              </div>
              <!--Hora Solicitud -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Hora Solicitud" formControlName="horaSolicitud" readonly="»readonly»" />
                  <mat-icon matSuffix>watch_later</mat-icon>
                </mat-form-field>
              </div>

              <div class="col">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Hora Autorizado/Rechazado" formControlName="horaEstado"
                    readonly="»readonly»" />
                  <mat-icon matSuffix>watch_later</mat-icon>
                </mat-form-field>
              </div>

            </div>


            <!-- Fila 2 -->
            <div class="row">
              <!-- Empresa q asume -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Cuenta Asume Gasto" formControlName="asumeFactura"
                    readonly="»readonly»" />
                  <mat-icon matSuffix>shop</mat-icon>
                </mat-form-field>
              </div>
              <!-- Rut Empresa -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Rut Empresa" formControlName="RutEmpresa" readonly="»readonly»" />
                  <mat-icon matSuffix>account_circle</mat-icon>
                </mat-form-field>
              </div>
              <!--Cuenta q Asume -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="N° Cuenta empresa" formControlName="NumeroCuentaAsume"
                    readonly="»readonly»" />
                  <mat-icon matSuffix>account_balance_wallet</mat-icon>
                </mat-form-field>
              </div>
            </div>

            <!-- Fila 3 -->
            <div class="row">
              <!-- Banco Empresa -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Banco Origen" formControlName="banco" readonly="»readonly»" />
                  <mat-icon matSuffix>account_balance</mat-icon>
                </mat-form-field>
              </div>
              <!-- Tipo de Gasto  -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Tipo de Egreso" formControlName="inversion" readonly="»readonly»" />
                  <mat-icon matSuffix>assignment_turned_in</mat-icon>
                </mat-form-field>
              </div>
              <!-- Trasferir a -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Pagar a" formControlName="nombreTransferencia" readonly="»readonly»" />
                  <mat-icon matSuffix>assignment_ind</mat-icon>
                </mat-form-field>
              </div>
            </div>

            <!-- Fila 4 -->
            <div class="row">
              <!--Rut Cliente -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Rut" formControlName="rut" readonly="»readonly»" />
                  <mat-icon matSuffix>account_box</mat-icon>
                </mat-form-field>
              </div>
              <!--NumeroCuenta Cliente -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Cuenta Destino" formControlName="NumeroCuenta" readonly="»readonly»" />
                  <mat-icon matSuffix>account_balance</mat-icon>
                </mat-form-field>
              </div>
              <!--Banco a Trasferir -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Banco Destino" formControlName="bancoCliente" readonly="»readonly»" />
                  <mat-icon matSuffix>account_balance</mat-icon>
                </mat-form-field>
              </div>
            </div>
            <!-- fila 5  -->

            <!--Fila-->
            <div class="row">
              <!-- montoPago -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Monto Pago" formControlName="montoPago" readonly="»readonly»" />
                  <span matPrefix>$&nbsp;</span>
                  <mat-icon matSuffix>monetization_on_white_18dp</mat-icon>
                </mat-form-field>
              </div>

              <!-- Tipo Cuenta   -->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Tipo de Cuenta" formControlName="tipoCuenta" readonly="»readonly»" />
                  <mat-icon matSuffix>account_balance_18dp</mat-icon>
                </mat-form-field>
              </div>
              <!--Correo-->
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Correo Destino" formControlName="correo" readonly="»readonly»" />
                  <mat-icon matSuffix>email</mat-icon>
                </mat-form-field>
              </div>
            </div>
            <!-- Sucursal -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <input matInput placeholder="Sucursal" formControlName="nombreSucursal" readonly="»readonly»" />
                <mat-icon matSuffix>store</mat-icon>
              </mat-form-field>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field appearance="legacy">
                  <input matInput placeholder="Hora Pagado/Transferida" formControlName="horapago"
                    readonly="»readonly»" />
                  <mat-icon matSuffix>watch_later</mat-icon>
                </mat-form-field>
              </div>
            </div>
            <!-- fila 6 -->

            <!--Hora Autoriza/rechaza-->
            <div class="row">
              <div class="col">
                <label>Link de pago: </label>
                  <a mat-button (click)="verificarURL()" href="{{ linkValue }}" target="_blank">{{ linkValue }}
                    <mat-icon matSuffix>https</mat-icon>
                  </a>
              </div>
            </div>

            <!-- Fila 7 -->
            <div class="row">
              <!--Motivo Pago -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Motivo Pago" formControlName="motivoPago" readonly="»readonly»" />
                  <mat-icon matSuffix>question_answer</mat-icon>
                </mat-form-field>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <br />
        <mat-accordion>
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title> Informacion Adicional </mat-panel-title>
              <mat-panel-description>
                Presiona para mostrar los datos Adicionales
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-card-content>
              <mat-card>
                <div class="row">
                  <div class="col">
                    <mat-form-field appearance="legacy">
                      <mat-label>Selecciona Estado </mat-label>
                      <mat-select formControlName="estado">
                        <mat-option value="Pagado">Pagado</mat-option>
                        <mat-option value="Rechazada">Rechazada</mat-option>
                        <mat-option value="Devuelto">Devuelto</mat-option>

                      </mat-select>
                      <mat-icon matSuffix>ballot</mat-icon>
                    </mat-form-field>
                  </div>

                  <div class="col" *ngIf="addressForm.get('estado').value === 'Rechazada'">
                    <mat-form-field appearance="legacy">
                      <input matInput placeholder="Razon de Rechazo" formControlName="razonRechazo" />
                    </mat-form-field>
                  </div>

                  <div class="col"
                    *ngIf="addressForm.get('estado').value === 'Pagado' || addressForm.get('estado').value === 'Devuelto'">
                    <!-- Capturar img -->
                    <div>
                      <input mat-stroked-button (change)="capturarFile($event,addressForm.get('estado').value)"
                        type="file" />
                    </div>
                    <br />
                  </div>
                </div>
                <div
                  *ngIf="addressForm.get('estado').value === 'Pagado' || addressForm.get('estado').value === 'Devuelto'">
                  <br />
                  <h3>Imagen Previsualizacion</h3>
                  <hr />
                </div>
                <!-- Preview img -->
                <div class="row">
                  <div class="col" *ngIf="previsualizacion">
                    <img width="400px" [src]="previsualizacion" alt="img" />
                  </div>
                  <div class="col" *ngIf="previsualizacionDevuelto">
                    <img width="400px" [src]="previsualizacionDevuelto" alt="img" />
                  </div>
                </div>
              </mat-card>
            </mat-card-content>
          </mat-expansion-panel>
        </mat-accordion>
        <br>
        <mat-accordion *ngIf="addressForm.get('estado').value === 'Pagado'">
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title> Informacion Factura </mat-panel-title>
              <mat-panel-description>
                Presiona para mostrar los datos de factura
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-card-content>
              <mat-card>
                <div class="row">

                  <div class="col">
                    <mat-form-field appearance="legacy">
                      <mat-label>Selecciona Estado </mat-label>
                      <mat-select formControlName="estadoFactura">
                        <mat-option value="Por Emitir" *ngIf="addressForm.get('estadoFactura').value === 'Por Emitir'||
                          addressForm.get('estadoFactura').value === null ||
                          addressForm.get('estadoFactura').value === 'Por Confirmar'">Por Emitir</mat-option>
                        <mat-option value="Por Confirmar">Emitida</mat-option>
                      </mat-select>
                      <mat-icon matSuffix>ballot</mat-icon>
                    </mat-form-field>
                  </div>

                  <!-- Capturar img -->
                  <div class="col" *ngIf="addressForm.get('estadoFactura').value === 'Por Confirmar'">
                    <div>
                      <input mat-stroked-button (change)="capturarFactura($event)" type="file" />
                    </div>
                    <br />
                    <div class="col">
                      <mat-radio-group formControlName="confirmadoPorFinanzas" [disabled]="rolUsuario === 'User' ||
                        rolUsuario === 'Operaciones' || rolUsuario === 'RecursoHumano' ">Confirmado por finanzas
                        <mat-radio-button [value]="true" name="si">Si</mat-radio-button>
                        <mat-radio-button [value]="false" name="no">No</mat-radio-button>
                      </mat-radio-group>
                    </div>


                  </div>

                </div>
                <div *ngIf="addressForm.get('estadoFactura').value === 'Por Confirmar'">
                  <br />
                  <h3>Imagen Previsualizacion</h3>
                  <hr />
                </div>

                <!-- Preview img -->
                <div class="row">
                  <div class="col" *ngIf="previsualizacion2">
                    <img width="400px" [src]="previsualizacion2" alt="img" />
                  </div>
                </div>
              </mat-card>
            </mat-card-content>
          </mat-expansion-panel>
        </mat-accordion>
        <br />
        <mat-card-actions>
          <button mat-stroked-button color="accent" style="margin-left: 1rem; margin-bottom: 3px"
            (click)="closeDialog()">
            <mat-icon>cancel_white_18dp</mat-icon>Cancelar
          </button>
          <button
            *ngIf="addressForm.get('estado').value == 'Pagado' || addressForm.get('estado').value == 'Devuelto' || addressForm.get('estadoFactura').value == 'Por Confirmar' "
            mat-stroked-button color="primary" type="submit" [disabled]="loading" (click)="subirIMG()"
            (click)="subirFactura()" (click)="CuentaBancaria()" (click)="onSubmit()">
            {{ loading ? 'Cargando....' : 'Guardar' }}
            <mat-icon>send</mat-icon>
          </button>
          <button *ngIf="addressForm.get('estado').value == 'Rechazada'" mat-stroked-button color="primary"
            type="submit" [disabled]="loading" (click)="CuentaBancaria()" (click)="onSubmit()" [disabled]="
              addressForm.get('razonRechazo').value == null ||
              addressForm.get('razonRechazo').value == '' ||
              this.est === 'Saldado' ||
              this.est === 'Pagado' ||
              this.est === 'Rechazada'
            ">
            {{ loading ? 'Cargando....' : 'Guardar' }}
            <mat-icon>send</mat-icon>
          </button>

          <!-- <button
          *ngIf="addressForm.get('estadoFactura').value == 'Por Confirmar' "
          mat-stroked-button
          color="primary"
          type="submit"
          [disabled]="loading"
          (click)="subirFactura()"
          (click)="CuentaBancaria()"
          (click)="onSubmit()"
        >
          {{ loading ? 'Cargando....' : 'Guardar' }}
          <mat-icon>send</mat-icon>
        </button> -->
        </mat-card-actions>
      </mat-card>
    </form>



  </mat-tab>
</mat-tab-group>