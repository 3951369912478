import {
  Component,
  Input,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { modeloSolicitudBancaria } from '@app/_models/consolidadoBancario/solicitudBancaria'
import { ConsolidadoBancarioService } from '../../consolidado-bancario.service'
import { HttpClient } from '@angular/common/http'
import { environment } from '@environments/environment'
import * as saveAs from 'file-saver'
import { SelectionModel } from '@angular/cdk/collections'
import { format } from 'rut.js'
import { AlertHelper } from '@app/_helpers/alert.helper';
import { Usuario } from '@app/_models/shared/usuario'
import { ConsolidadoBancarioSharedService } from '../../consolidado-bancario-shared.service'

@Component({
  selector: 'app-pago-table',
  templateUrl: './pago-table.component.html',
  styleUrls: ['./pago-table.component.scss']
})
export class PagoTableComponent implements OnInit {
  // ? childrens
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>()
  @ViewChild(MatSort) sort = null

  // ? Inputs & Outputs
  @Input() refrescar = ''
  usuario: Usuario = JSON.parse(localStorage.getItem('usuario') + '')

  //------inicio tabla-------
  displayedColumns: string[] = [
    'select',
    'id',
    'jefeAutoriza',
    'rut',
    'nombreTransferencia',
    'NumeroCuenta',
    'fechaSolicitud',
    'motivoPago',
    'perfilUsuario',
    'nombreAutoriza',
    'razonRechazo',
    
    //'apruebo_pagado',
   // 'correo',
    'estado',
    'confirmadoPorFinanzas',
    'montoPago',
    'acciones',
    //'razonRechazo'
  ]
  dataSource: MatTableDataSource<modeloSolicitudBancaria> =
    new MatTableDataSource()
  dataSolicitudBancaria: modeloSolicitudBancaria[] = []
  dataPendiente: modeloSolicitudBancaria[] = []
  dataAceptada: modeloSolicitudBancaria[] = []
  dataRechazada: modeloSolicitudBancaria[] = []
  dataSaldado: modeloSolicitudBancaria[] = []
  totalSeleccion = 0
  cantidadPendientes = []
  selection = new SelectionModel<modeloSolicitudBancaria>(true, [])
  selectedRows!: any[]
  mostrarAlertaPorEmitir = false;

  //Modelo de Solicitud bancaria
  modeloDia: modeloSolicitudBancaria = new modeloSolicitudBancaria()
  rolUsuario: string

  formFilter = new FormGroup({
    id: new FormControl(),
    nombreTransferencia: new FormControl(),
    start: new FormControl(),
    end: new FormControl(),
    rut: new FormControl(),
    NumeroCuenta: new FormControl(),
    jefeAutoriza: new FormControl(),
    estado: new FormControl(),
    montoPago: new FormControl()
  })

  constructor(
    public dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private consolidadoBancarioService: ConsolidadoBancarioService,
    private http: HttpClient,
    private alert: AlertHelper,
    private sharedService:ConsolidadoBancarioSharedService
  ) {
    this.sharedService.recargarTabla$.subscribe(async () =>{
      this.getAllSolicitudBancaria()
      console.log(this.dataAceptada,this.dataPendiente,this.dataRechazada,this.dataSaldado,this.dataSolicitudBancaria)
      if(this.dataAceptada.length >0){
        this.aceptada()
      }
      if(this.dataPendiente.length >0){
        this.pendiente()

      }
      if(this.dataRechazada.length >0 && this.dataAceptada.length<=0){
        this.rechazada()

      }
      if(this.dataSaldado.length >0){
        this.saldado()
      }

    })
  }

  ngOnInit(): void {
    this.getAllSolicitudBancaria()
    this.aplicarfiltros()

  }
  ngOnChanges(changes: SimpleChanges): void {
    this.getAllSolicitudBancaria()
    this.aplicarfiltros()
  }

  getAllSolicitudBancaria() {
    this.consolidadoBancarioService.getAllSolicitudBacaria().subscribe(
      (data: modeloSolicitudBancaria[]) => {
        data = data.filter(element => element.estado !== 'Pendiente'); // Filtrar elementos que no son 'Pendiente'
        data.map((element) => {
          if (
            element.estado == 'Aceptada' ||
            element.estado == 'Saldado' ||
            element.estado == 'Autorizada' ||
            element.estado == 'Pagado' ||
            element.estado == 'Rechazada'
          ) {
            //console.log(element)
            this.dataSolicitudBancaria.push(element)
            this.dataSource = new MatTableDataSource(this.dataSolicitudBancaria)
            this.dataSource.paginator = this.paginator.toArray()[0]
            this.dataSource.sort = this.sort
            if (this.dataSolicitudBancaria.some(element => element.estadoFactura === 'Por Emitir')) {
              this.mostrarAlertaPorEmitir = true;
            }
          }
        })
  
        this.dataSolicitudBancaria = data.map((Solicitud) => {
          Solicitud.rut = format(Solicitud.rut)
          Solicitud.perfilUsuario = Solicitud.Usuario?.nombreUsuario
          return Solicitud
        })
        this.rolUsuario = this.usuario.rol
      },
      (error) => {
        console.log('Se presentó el siguiente error: ', error)
      }
    )
  }
  
  

  //Boton para actualizar tabla de facturas
  actualizar(): void {
    window.location.reload()
  }
  //Boton de busqueda
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage()
    }
  }
  //Boton de acciones
  accionesPagoCuentaBancaria(id: any) {
    localStorage.setItem('idPagoBancario', id)
    this.consolidadoBancarioService.openDialogEditPagoCuentaBancaria()
  }
  //Boton de acciones
  PagoBanco(id: any) {
    this.consolidadoBancarioService.getByIdPagoBancario(id).subscribe(
      (data: any) => {
        if (data[0].estado === 'Aceptada' || data[0].estado === 'Autorizada'  ) {
          if (data[0].banco === 'BancoEstado') {
            window.open(
              'https://www.bancoestado.cl',
              '_blank',
              'toolbar=yes,scrollbars=yes,resizable=yes,top=800,left=300,width=800,height=800'
            )
          } else if (data[0].banco === 'BancoChile') {
            window.open(
              'https://portales.bancochile.cl/personas',
              '_blank',
              'toolbar=yes,scrollbars=yes,resizable=yes,top=800,left=300,width=800,height=800'
            )
          } else if (data[0].banco === 'BancoSantander') {
            window.open(
              'https://banco.santander.cl/personas',
              '_blank',
              'toolbar=yes,scrollbars=yes,resizable=yes,top=800,left=300,width=800,height=800'
            )
          } else if (data[0].banco === 'BancoScotiabank') {
            window.open(
              'https://appservtrx.scotiabank.cl/portalempresas/login?_ga=2.124175555.890232252.1666729010-1462344458.1666729010',
              '_blank',
              'toolbar=yes,scrollbars=yes,resizable=yes,top=800,left=300,width=800,height=800'
            )
          } else if (data[0].banco === 'BancoBice') {
            window.open(
              'https://banco.bice.cl/empresas',
              '_blank',
              'toolbar=yes,scrollbars=yes,resizable=yes,top=800,left=300,width=800,height=800'
            )
          } else if (data[0].banco === 'BancoItau') {
            window.open(
              'https://banco.itau.cl/wps/portal/newiol/web/login',
              '_blank',
              'toolbar=yes,scrollbars=yes,resizable=yes,top=800,left=300,width=800,height=800'
            )
          } else if (data[0].banco === 'BancoFalabella') {
            window.open(
              'https://www.bancofalabella.cl/',
              '_blank',
              'toolbar=yes,scrollbars=yes,resizable=yes,top=800,left=300,width=800,height=800'
            )
          } else if (data[0].banco === 'BancoSecurity') {
            window.open(
              'https://empresas.bancosecurity.cl/',
              '_blank',
              'toolbar=yes,scrollbars=yes,resizable=yes,top=800,left=300,width=800,height=800'
            )
          } else if (data[0].banco === 'BancoRipley') {
            window.open(
              'https://www.bancoripley.cl/',
              '_blank',
              'toolbar=yes,scrollbars=yes,resizable=yes,top=800,left=300,width=800,height=800'
            )
          } else {
            this.snackBar.open(
              `😭 Link no encontrado Por favor buscar en el navegador`,
              'X',
              {
                duration: 4000,
                verticalPosition: 'top'
              }
            )
          }
        }
        if (data[0].estado === 'Saldado' || data[0].estado === 'Pagado') {
          this.snackBar.open(`Este Pago ya fue Saldado 🤙`, 'X', {
            duration: 4000,
            verticalPosition: 'top'
          })
        }
        if (data[0].estado === 'Rechazada') {
          this.snackBar.open(`Este Pago ya fue Rechazado 🤌`, 'X', {
            duration: 4000,
            verticalPosition: 'top'
          })
        }
      },
      (error) => {
        console.log('Se apresentado el siguiente error: ', error)
      }
    )
  }
  //Boton mostrar todos los datos
  todos() {
    this.getAllSolicitudBancaria()
  }

  //Boton pendiente
  pendiente() {
    this.dataPendiente = []
    this.consolidadoBancarioService.getAllSolicitudBacaria().subscribe(
      (dato: any) => {
        dato = dato.filter(element => element.estado !== 'Pendiente'); // Filtrar elementos que no son 'Pendiente'
        dato.map((element) => {
          if (element.estado == 'Pendiente') {
            this.dataPendiente.push(element)
            this.dataSource = new MatTableDataSource(this.dataPendiente)
            this.dataSource.paginator = this.paginator.toArray()[0]
            this.dataSource.sort = this.sort
          }
        })
        this.dataSolicitudBancaria = dato.map((Solicitud) => {
          Solicitud.rut = format(Solicitud.rut)
          return Solicitud
        })
      },
      (error) => {
        console.log('Se presentó el siguiente error: ', error)
      }
    )
  }
  
  //Boton aceptada
  aceptada() {
    this.dataAceptada = []
    this.consolidadoBancarioService.getAllSolicitudBacaria().subscribe(
      (dato: any) => {
        dato.map((element) => {
          if (element.estado == 'Aceptada' || element.estado == 'Autorizada') {
            this.dataAceptada.push(element)
            this.dataSource = new MatTableDataSource(this.dataAceptada)
            this.dataSource.paginator = this.paginator.toArray()[0]
            this.dataSource.sort = this.sort
          }
        })
        this.dataSolicitudBancaria = dato.map((Solicitud) => {
          Solicitud.rut = format(Solicitud.rut)
          return Solicitud
        })
      },
      (error) => {
        console.log('Se apresentado el siguiente error: ', error)
      }
    )
  }

  //Boton rechazada
  rechazada() {
    this.dataRechazada = []
    this.consolidadoBancarioService.getAllSolicitudBacaria().subscribe(
      (dato: any) => {
        dato.map((element) => {
          if (element.estado == 'Rechazada') {
            this.dataRechazada.push(element)
            this.dataSource = new MatTableDataSource(this.dataRechazada)
            this.dataSource.paginator = this.paginator.toArray()[0]
            this.dataSource.sort = this.sort
          }
        })
        this.dataSolicitudBancaria = dato.map((Solicitud) => {
          Solicitud.rut = format(Solicitud.rut)
          return Solicitud
        })
      },
      (error) => {
        console.log('Se a presentado el siguiente error: ', error)
      }
    )
  }

  Devuelto() {
    this.dataRechazada = []
    this.consolidadoBancarioService.getAllSolicitudBacaria().subscribe(
      (dato: any) => {
        dato.map((element) => {
          if (element.estado == 'Devuelto') {
            this.dataRechazada.push(element)
            this.dataSource = new MatTableDataSource(this.dataRechazada)
            this.dataSource.paginator = this.paginator.toArray()[0]
            this.dataSource.sort = this.sort
          }
        })
        this.dataSolicitudBancaria = dato.map((Solicitud) => {
          Solicitud.rut = format(Solicitud.rut)
          return Solicitud
        })
      },
      (error) => {
        console.log('Se a presentado el siguiente error: ', error)
      }
    )
  }

  downloadImage(id: any) {
    localStorage.setItem('idPagoOrdenCompra', id)
    this.http
      .get(`${environment.apiUrl}/solicitudBancaria/download-image/${id}`, {
        responseType: 'blob'
      })
      .subscribe((response) => {
        const url = URL.createObjectURL(response)
        const fileName = 'my-image.jpg'
        fetch(url)
          .then((res) => res.blob())
          .then((blob) => {
            saveAs(blob)
          })
      })
  }
  /*
    downloadImage(id: any) {
    localStorage.setItem('idPagoOrdenCompra', id)
    this.http
      .get(`${environment.apiUrl}/solicitudBancaria/download-image/${id}`, {
        responseType: 'blob'
      })
      .subscribe((response) => {
        const url = URL.createObjectURL(response)
        const fileName = 'my-image.jpg'
        fetch(url)
          .then((res) => res.blob())
          .then((blob) => {
            saveAs(blob)
          })
      })
  }

  */

  //Boton saldado
  saldado() {
    this.dataSaldado = []
    this.consolidadoBancarioService.getAllSolicitudBacaria().subscribe(
      (dato: any) => {
        dato.map((element) => {
          if (element.estado == 'Saldado' || element.estado == 'Pagado') {
            this.dataSaldado.push(element)
            this.dataSource = new MatTableDataSource(this.dataSaldado)
            this.dataSource.paginator = this.paginator.toArray()[0]
            this.dataSource.sort = this.sort
          }
        })
        this.dataSolicitudBancaria = dato.map((Solicitud) => {
          Solicitud.rut = format(Solicitud.rut)
          return Solicitud
        })
      },
      (error) => {
        console.log('Se apresentado el siguiente error: ', error)
      }
    )
  }

  aplicarfiltros() {
    this.formFilter.valueChanges.subscribe((res) => {
      const { id, monto } = res
      let dataFiltered = this.dataSolicitudBancaria

      if (res.nombreTransferencia) {
        dataFiltered = dataFiltered.filter((data: modeloSolicitudBancaria) =>
          data.nombreTransferencia.toString().includes(res.nombreTransferencia)
        )
      }
      if (res.rut) {
        dataFiltered = dataFiltered.filter((data: modeloSolicitudBancaria) =>
          data.rut.toString().includes(res.rut)
        )
      }
      if (res.NumeroCuenta) {
        dataFiltered = dataFiltered.filter((data: modeloSolicitudBancaria) =>
          data.NumeroCuenta.toString().includes(res.NumeroCuenta)
        )
      }
      if (res.jefeAutoriza) {
        dataFiltered = dataFiltered.filter((data: modeloSolicitudBancaria) =>
          data.jefeAutoriza.toString().includes(res.jefeAutoriza)
        )
      }
      if (res.estado) {
        dataFiltered = dataFiltered.filter((data: modeloSolicitudBancaria) =>
          data.estado.toString().includes(res.estado)
        )
      }
      if (res.montoPago) {
        dataFiltered = dataFiltered.filter((data: modeloSolicitudBancaria) =>
          data.montoPago.toString().includes(res.montoPago)
        )
      }

      if (res.start && res.end) {
        dataFiltered = dataFiltered.filter(
          (data: modeloSolicitudBancaria) =>
            new Date(data.fechaSolicitud) >= res.start &&
            new Date(data.fechaSolicitud) <= res.end
        )
      }

      this.dataSource = new MatTableDataSource(dataFiltered)
      this.dataSource.paginator = this.paginator.toArray()[0]
      this.totalSeleccion = 0
      this.dataSource.sort = this.sort
      this.selection.clear()
    })
  }

  //SEELECCION DE ID
  isAllSelected() {
    const numSelected = this.selection.selected.length
    const numRows = this.dataSource.data.length
    return numSelected === numRows
  }

  // Metodo que sirve para la seleccion de un campo de la tabla
  masterToggle() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.filteredData.forEach((row) => {
          this.selection.select(row)
        })
  }

  //exportar execel
  exportAsXLSX(): void {
    this.selectedRows = []
    this.selection.selected.forEach((x) => this.selectedRows.push(x))
    this.consolidadoBancarioService.exportAsExcelFile(
      this.selectedRows,
      'PAGO-ORDEN-DE-COMPRAS'
    )
  }
}
