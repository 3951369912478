import { EgresosFijoImportadora } from './../../../../../_models/importadora/egresoFijoImportadora'
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper'
import { HttpClient } from '@angular/common/http'
import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { DomSanitizer } from '@angular/platform-browser'
import { modeloSolicitudBancaria } from '@app/_models/consolidadoBancario/solicitudBancaria'
import { EgresosRentacar } from '@app/_models/rentacar/egresoRentacar'
import { AdministracionService } from '@app/_pages/administracion/administracion.service'
import { ConsolidadoBancarioService } from '@app/_pages/consolidado-bancario/consolidado-bancario.service'
import { ImportadoraService } from '@app/_pages/importadora/importadora.service'
import { RentacarService } from '@app/_pages/rentacar/rentacar.service'
import { first } from 'rxjs/operators'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { EgresoLubricentro } from '../../../../../_models/lubricentro/egresoLubricentro'
import { LubricentroService } from '@app/_pages/lubricentro/lubricentro.service'
import { RegistroEgresoFirma } from '../../../../../_models/abogados/egresosFirma'
import { EgresoHostal } from '@app/_models/hostal/egresoHostal'
import { AbogadosService } from '@app/_pages/abogados/abogados.service'
import { HostalService } from '../../../../hostal/hostal.service'
import { EgresosInmobiliaria } from '@app/_models/inmobiliaria/egresoInmobiliaria'
import { InmobiliariaService } from '@app/_pages/inmobiliaria/inmobiliaria.service'
import { Sucursal } from '@app/_models/shared/sucursal'
import { AlertHelper } from '@app/_helpers/alert.helper'
import { SucursalSharedService } from '@app/_pages/shared/shared-services/sucursal-shared.service'
import { EgresoPersonal } from '@app/_models/gastoPersonal/egresoGastoPersonal'
import { GastoService } from '@app/_pages/gasto-personal/gasto.service'
import { ConsolidadoBancarioSharedService } from '../../../consolidado-bancario-shared.service'
import { Usuario } from '@app/_models/shared/usuario'
import { environment } from '@environments/environment'
import { pipe } from 'rxjs'

@Component({
  selector: 'app-pago-acciones',
  templateUrl: './pago-acciones.component.html',
  styleUrls: ['./pago-acciones.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    }
  ]
})
export class PagoAccionesComponent implements OnInit {
  @Output() formularioListo = new EventEmitter<string>()
  private servicioCompartidoConsolidado: ConsolidadoBancarioSharedService

  usuario: Usuario = JSON.parse(localStorage.getItem('usuario') + '')
  nombreUsuario = this.usuario.nombre
  apellidoUsuario = this.usuario.apellido
  nombreCompleto = this.usuario.nombre + ' ' + this.usuario.apellido
  panelOpenState = false
  blockSelect = true
  State = false
  clickButton = true
  showAlert: boolean = true
  descomponerArray: any
  UpdateCuentaBancaria = []
  rolUsuario: string
  confirmadoPorFinanzas: string

  dataArrayImg = []
  dataArrayDevuelto = []
  public archivos: any = []
  public archivoDevuelto: any[]
  public previsualizacion: string
  public previsualizacionDevuelto: string
  public loading: boolean

  dataArrayFactura = []
  public fotoFactura: any = []
  estadoFactura: any
  public previsualizacion2: string
  public loading2: boolean
  public fechaFormateada: any
  public horaPagado: any

  //data
  idPagoBancario: any
  NumeroCuenta: any
  nombreResponsable: any
  rut: any
  jefeAutoriza: any
  fechaSolicitud: any
  estado: any
  montoPago: any
  NumeroCuentaAsume: any
  inversion: any
  motivoPago: any
  correo: any
  idSucursal: any
  idUsuario: any
  razonRechazo: any
  nombreTransferencia: any
  bancoCliente: any
  est: any
  horaEstado: Date
  horaSolicitud: Date
  fechassss: string
  RutEmpresa: any
  estadoDevuelto: string
  sucursales: Sucursal[]
  sucursal: any
  asumeFactura: any
  link: any
  linkValue: any
  tipoCuenta: any
  Empresa: string

  //Modelo de Solicitud bancaria
  modelo: modeloSolicitudBancaria = new modeloSolicitudBancaria()
  modeloEgresoRentcar: EgresosRentacar = new EgresosRentacar()
  modeloEgresoImportadora: EgresosFijoImportadora = new EgresosFijoImportadora()
  modeloEgresoLubricentro: EgresoLubricentro = new EgresoLubricentro()
  modeloEgresoFirmaAbogado: RegistroEgresoFirma = new RegistroEgresoFirma()
  modeloEgresoHostal: EgresoHostal = new EgresoHostal()
  modeloEgresoInmobiliaria: EgresosInmobiliaria = new EgresosInmobiliaria()
  modeloEgresoGastoPersonal: EgresoPersonal = new EgresoPersonal()
  factura: any
  imagen: any

  constructor(
    private administracionService: AdministracionService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private consolidadoBancarioService: ConsolidadoBancarioService,
    private rentacarService: RentacarService,
    private importadoraService: ImportadoraService,
    private lubricentroService: LubricentroService,
    private firmaAbogadoService: AbogadosService,
    private hostalService: HostalService,
    private inmobiliariaService: InmobiliariaService,
    private gastoService: GastoService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private sucursalService: SucursalSharedService,
    private alert: AlertHelper,
    private consolidadoBancarioSharedService: ConsolidadoBancarioSharedService // Inicializa la instancia
  ) {
    this.servicioCompartidoConsolidado = consolidadoBancarioSharedService
  }

  ngOnInit(): void {
    this.idPagoBancario = localStorage.getItem('idPagoBancario')
    this.getPagoCuentaBancaria()
    this.rolUsuario = this.usuario.rol
  }

  updateServicioCompartidoConsolidado() {
    this.servicioCompartidoConsolidado.recargarTabla()
  }
  ////----------------------------Cambiar estado ------
  //Configuracion de formulario
  addressForm = this.fb.group({
    NumeroCuenta: null,
    nombreResponsable: null,
    rut: null,
    jefeAutoriza: null,
    fechaSolicitud: null,
    estado: ['', [Validators.required]],
    archivos: null,
    archivoDevuelto: null,
    montoPago: null,
    motivoPago: null,
    inversion: null,
    correo: null,
    NumeroCuentaAsume: null,
    // empresaAsumeGasto: null,
    banco: null,
    nombreTransferencia: null,
    bancoCliente: null,
    razonRechazo: null,
    razonApruebo: null,
    nombreSucursal: null,
    asumeFactura: null,
    horaEstado: null,
    horaSolicitud: null,
    horapago: null,
    RutEmpresa: null,
    link: null,
    linkValue: null,
    fotoFactura: null,
    estadoFactura: null,
    confirmadoPorFinanzas: null,
    Usuario: null,
    nombreCompleto: null,
    idUsuario: null,
    tipoCuenta: null //----
  })

  get f() {
    return this.addressForm.controls
  }
  // //////////////////////////////////////MODIFICACION ISAI ///////////////////////////////////////
  obtenerSucursales(id: number) {
    this.sucursalService
      .getById(id)
      .pipe(first())
      .subscribe((x: any) => {
        console.log('Respuesta de la API:', x)

        // Verificar si el objeto x tiene el campo idEmpresa
        if (x && x.idEmpresa) {
          this.Empresa = x.idEmpresa
        } else {
          console.log('No se encontró idEmpresa en la respuesta o es null') // Asignar null explícitamente si no se encuentra idEmpresa
        }

        console.log('idEmpresa obtenido:', this.Empresa)

        // Aquí puedes continuar con la lógica después de obtener idEmpresa
      })
  }
  // //////////////////////////////////////MODIFICACION ISAI ///////////////////////////////////////

  getPagoCuentaBancaria() {
    this.consolidadoBancarioService
      .getByIdPagoBancario(this.idPagoBancario)
      .pipe(first())
      .subscribe((x: any) => {
        if (x[0].id == this.idPagoBancario) {
          this.f.Usuario.setValue(x[0].Usuario)
          this.est = x[0].estado
          this.f.NumeroCuenta.setValue(x[0].NumeroCuenta)
          this.f.nombreResponsable.setValue(x[0].nombreResponsable)
          this.f.rut.setValue(x[0].rut)
          this.f.jefeAutoriza.setValue(x[0].jefeAutoriza)
          this.f.fechaSolicitud.setValue(x[0].fechaSolicitud.slice(0, 10))
          this.f.estado.setValue(x[0].estado)
          this.f.archivos.setValue(x[0].archivos)
          this.f.archivoDevuelto.setValue(x[0].archivoDevuelto)
          this.f.montoPago.setValue(x[0].montoPago)
          this.f.inversion.setValue(x[0].inversion)
          this.f.motivoPago.setValue(x[0].motivoPago)
          this.f.correo.setValue(x[0].correo)
          this.f.razonRechazo.setValue(x[0].razonRechazo)
          this.f.NumeroCuentaAsume.setValue(x[0].NumeroCuentaAsume)
          // this.f.empresaAsumeGasto.setValue(x[0].empresaAsumeGasto)
          this.f.asumeFactura.setValue(x[0].asumeFactura)
          this.f.banco.setValue(x[0].banco)
          this.f.nombreTransferencia.setValue(x[0].nombreTransferencia)
          this.f.bancoCliente.setValue(x[0].bancoCliente)
          this.f.correo.setValue(x[0].correo)
          this.f.link.setValue(x[0].link)
          this.f.tipoCuenta.setValue(x[0].tipoCuenta)
          this.f.idUsuario.setValue(x[0].idUsuario)

          // confirmado
          this.f.confirmadoPorFinanzas.setValue(x[0].confirmado)
          console.log(this.f.confirmadoPorFinanzas.setValue(x[0].confirmado))
          if (this.f.confirmadoPorFinanzas.value === true) {
            this.addressForm.get('confirmadoPorFinanzas').setValue('Confirmado')
          } else {
            this.addressForm
              .get('confirmadoPorFinanzas')
              .setValue('No confirmado')
          }
          console.log(this.f.confirmadoPorFinanzas)

          const fechaHora = new Date(x[0].createdAt)
          const hora = fechaHora.getHours()
          const minutos = fechaHora.getMinutes()
          const horaFormateada = `${hora.toString().padStart(2, '0')}:${minutos
            .toString()
            .padStart(2, '0')}`
          this.f.horaSolicitud.setValue(horaFormateada)

          const fechaEstado = new Date(x[0].updatedAt)
          const horas = fechaEstado.getHours()
          const minuto = fechaEstado.getMinutes()
          this.fechaFormateada = `${horas.toString().padStart(2, '0')}:${minuto
            .toString()
            .padStart(2, '0')}`
          localStorage.setItem('HoraAutorizacion', this.fechaFormateada)

          if (
            this.f.estado.value === 'Autorizada' ||
            this.f.estado.value === 'Rechazada' ||
            this.f.estado.value === 'Transferida'
          ) {
            const fechaEstadoPago = new Date(x[0].updatedAt)
            const horaPago = fechaEstadoPago.getHours()
            const minutoPago = fechaEstadoPago.getMinutes()
            this.horaPagado = `${horaPago
              .toString()
              .padStart(2, '0')}:${minutoPago.toString().padStart(2, '0')}`
            localStorage.setItem('HoraPago', this.horaPagado)

            const fechaFormateadaAutorizacion =
              localStorage.getItem('HoraAutorizacion')
            if (fechaFormateadaAutorizacion) {
              this.f.horaEstado.setValue(fechaFormateadaAutorizacion)
            }
          }
          if (
            this.f.estado.value === 'Pagado' ||
            this.f.estado.value === 'Rechazada'
          ) {
            const fechaFormateadaAutorizacion = localStorage.getItem('HoraPago')
            const horaPago = localStorage.getItem('HoraAutorizacion')
            if (fechaFormateadaAutorizacion && horaPago) {
              this.f.horaEstado.setValue(fechaFormateadaAutorizacion)
              this.f.horapago.setValue(horaPago)
            }
          }
        }
        if (
          this.f.asumeFactura.value ===
          'Empresa Teresa del Carmen Garrido Rojas e hijos' /* 'TERESA 1 BANCO ITAU 76.791.832-1' */
        ) {
          this.f.RutEmpresa.setValue('76.791.832-1')
        } else if (
          this.f.asumeFactura.value === 'Empresa Firma Abogados'
          /* 'FIRMA ABOGADOS CHILE BANCO ITAU 76.438.914-K' */
        ) {
          this.f.RutEmpresa.setValue('76.438.914-K')
        } else if (
          this.f.asumeFactura.value === 'SOC. GARRIDO BANCO ITAU 76.971.509-6'
        ) {
          this.f.RutEmpresa.setValue('76.971.509-6')
        } else if (
          this.f.asumeFactura.value ===
          'MIGUEL VARGAS E HIJOS BANCO ITAU 76.849.793-1'
        ) {
          this.f.RutEmpresa.setValue('76.849.793-1')
        } else if (
          this.f.asumeFactura.value ===
          'Empresa Teresa II' /* 'TERESA 2 BANCO ITAU 77.265.668-8' */
        ) {
          this.f.RutEmpresa.setValue('77.265.668-8')
        } else if (
          this.f.asumeFactura.value ===
          'Señora  Teresa' /* 'TERESA GARRIDO ROJAS BANCO ESTADO' */
        ) {
          this.f.RutEmpresa.setValue('11070934-K')
        } else if (
          this.f.asumeFactura.value ===
          'Diego Vargas' /* 'DIEGO VARGAS GARRIDO ITAU' */
        ) {
          this.f.RutEmpresa.setValue('18891594-9')
        } else if (
          this.f.asumeFactura.value ===
          'Miguel Vargas' /* 'MIGUEL VARGAS GARRIDO SANTANDER' */
        ) {
          this.f.RutEmpresa.setValue('17886328-2')
        } else if (
          this.f.asumeFactura.value ===
          'Miguel A Vargas Espinoza' /* 'MIGUEL VARGAS ESPINOZA BANCO ESTADO' */
        ) {
          this.f.RutEmpresa.setValue('8781641-9')
        } else if (
          this.f.asumeFactura.value === 'BRAULIO VARGAS GARRIDO SCOTIABANCK'
        ) {
          this.f.RutEmpresa.setValue('18228581-1')
        } else if (this.f.asumeFactura.value === 'JAVIER VARGAS GARRIDO ITAU') {
          this.f.RutEmpresa.setValue('19474232-0')
        } else if (
          this.f.asumeFactura.value ===
          'SOC. VARGAS II' /* 'SOC. VARGAS 2 BANCO ITAU 77.258.157-2' */
        ) {
          this.f.RutEmpresa.setValue('77.258.157-2')
        } else if (
          this.f.asumeFactura.value ===
          'SOC. VARGAS I' /* 'SOC. VARGAS 1 BANCO ITAU 76.457.046-4' */
        ) {
          this.f.RutEmpresa.setValue('76.457.046-4')
          this.f.razonApruebo.setValue(x[0].razonApruebo)
        } else if (
          this.f.asumeFactura.value ===
          'Empresa Solanch Macarena Tejos Carrasco'
        ) {
          this.f.RutEmpresa.setValue('19.105.142-4')
        }
        this.previsualizacion = x[0].archivos
        this.previsualizacionDevuelto = x[0].archivoDevuelto
        this.previsualizacion2 = x[0].fotoFactura
        this.NumeroCuentaAsume = x[0].NumeroCuentaAsume
        this.idSucursal = x[0].idSucursal
        this.idUsuario = x[0].idUsuario
        this.linkValue = x[0].link

        if (
          this.linkValue !== null &&
          this.linkValue !== undefined &&
          this.link !== ''
        ) {
          if (!this.linkValue?.startsWith('http')) {
            this.linkValue = 'https://' + this.linkValue.trim()
          }
          if (this.linkValue.startsWith('"')) {
            this.linkValue = this.linkValue.slice(1)
            console.log('link 1 ::::::', this.linkValue)
          }
          if (this.linkValue.startsWith('\\')) {
            this.linkValue = this.linkValue.slice(1)
            console.log('link 2 ::::::', this.linkValue)
          }
          if (this.linkValue.startsWith('"') && this.linkValue.endsWith('"')) {
            this.linkValue = this.linkValue.slice(1, -1)
          }
        }

        this.sucursalService
          .getAll()
          .pipe(first())
          .subscribe((data: any) =>
            data.forEach((element) => {
              if (element.id === this.idSucursal) {
                this.sucursal = element.razonSocial
                this.f.nombreSucursal.setValue(this.sucursal)
              }
            })
          )
      })

    this.consolidadoBancarioService
      .getAllCuentaBancaria()
      .pipe(first())
      .subscribe((data: any) => {
        data.forEach((element) => {
          if (element.numeroCuenta === this.NumeroCuentaAsume) {
            const MontoPago = this.f.montoPago.value
            const MontoTotal = element.totalMonto
            const data = MontoTotal - MontoPago
            element.totalMonto = data
            this.UpdateCuentaBancaria.push(element)
          }
        })
      })
  }

  //Capturar imagen
  capturarFile(event, estado) {
    let nuevoNombre
    this.archivos = []
    this.archivoDevuelto = []
    this.dataArrayImg = []
    this.dataArrayDevuelto = []
    const archivoCapturado = event.target.files[0]
    const extension = archivoCapturado.name.split('.').pop()
    if (estado === 'Devuelto') {
      nuevoNombre = 'pagoOC' + estado + Date.now() + '.' + extension
      this.estadoDevuelto = nuevoNombre
      this.dataArrayDevuelto.push(nuevoNombre)
    } else {
      nuevoNombre = 'pagoOC' + Date.now() + '.' + extension
      this.dataArrayImg.push(nuevoNombre)
    }
    const archivoNuevo = new File([archivoCapturado], nuevoNombre, {
      type: archivoCapturado.type
    })
    this.extraerBase64(archivoNuevo).then((imagen: any) => {
      this.previsualizacion = imagen.base
    })
    if (estado === 'Devuelto') {
      this.archivoDevuelto.push(archivoNuevo)
    } else {
      this.archivos.push(archivoNuevo)
    }
    this.clickButton = false
  }

  //Capturar factura
  capturarFactura(event) {
    this.fotoFactura = []
    this.dataArrayFactura = []
    const archivoCapturado = event.target.files[0]
    const extension = archivoCapturado.name.split('.').pop()
    const nuevoNombre = 'factura' + Date.now() + '.' + extension
    this.dataArrayFactura.push(nuevoNombre)
    const archivoNuevo = new File([archivoCapturado], nuevoNombre, {
      type: archivoCapturado.type
    })
    this.extraerBase64Factura(archivoNuevo).then((imagen: any) => {
      this.previsualizacion2 = imagen.base
    })
    this.fotoFactura.push(archivoNuevo)
    this.clickButton = false
  }

  //Extraer la img a base 64 Factura para poder visualizar
  extraerBase64Factura = async ($event: any) =>
    new Promise((resolve, reject) => {
      try {
        const unsafeImg = window.URL.createObjectURL($event)
        const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg)
        const reader = new FileReader()
        reader.readAsDataURL($event)
        reader.onload = () => {
          resolve({
            base: reader.result
          })
        }
        reader.onerror = (error) => {
          resolve({
            base: null
          })
        }
      } catch (e) {
        return null
      }
    })

  //Extraer la img a base 64 para poder visualizar
  extraerBase64 = async ($event: any) =>
    new Promise((resolve, reject) => {
      try {
        const unsafeImg = window.URL.createObjectURL($event)
        const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg)
        const reader = new FileReader()
        reader.readAsDataURL($event)
        reader.onload = () => {
          resolve({
            base: reader.result
          })
        }
        reader.onerror = (error) => {
          resolve({
            base: null
          })
        }
      } catch (e) {
        return null
      }
    })

  subirIMG() {
    if (this.addressForm.value.estado == 'Rechazada') {
      this.snackBar.open('Debe Actulizar Estado para Avanzar', 'Cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
    } else {
      try {
        this.loading = true
        const formularioDeDatos = new FormData()
        const formularioDevuelto = new FormData()
        this.archivos.forEach((archivo) => {
          formularioDeDatos.append('File', archivo)
        })
        this.archivoDevuelto.forEach((archivo) => {
          formularioDevuelto.append('File', archivo)
        })
        if (this.archivos.length > 0) {
          this.http
            .post(
              //`http://localhost:3000/api/solicitudBancaria/upload`,
              `${environment.apiUrl}/solicitudBancaria/upload`,
              formularioDeDatos
            )
            .subscribe((res) => {
              this.loading = false
              this.snackBar.open(`Imagen cargada con exito`, 'Cerrar', {
                duration: 2000,
                verticalPosition: 'top'
              })
            })
        } else {
          this.http
            .post(
              //`http://localhost:3000/api/solicitudBancaria/uploadDevuelto`,
              `${environment.apiUrl}/solicitudBancaria/upload`,
              formularioDevuelto
            )
            .subscribe((res) => {
              this.loading = false
              this.snackBar.open(`Imagen cargada con exito`, 'Cerrar', {
                duration: 2000,
                verticalPosition: 'top'
              })
            })
        }
      } catch (e) {
        this.loading = false
        this.snackBar.open(`Error al cargar imagen`, 'Cerrar', {
          duration: 2000,
          verticalPosition: 'top'
        })
        console.log('ERROR AL SUBIR IMG', e)
      }
    }
  }

  // //////////////////////////////////////MODIFICACION ISAI ///////////////////////////////////////
  registrarEgresoEnEmpresa(empresa, egreso) {
    console.log('Empresa en funcion registrar', empresa)
    empresa.modeloEgreso.idSucursal = this.idSucursal
    empresa.modeloEgreso.tipoEgreso = egreso.inversion
    empresa.modeloEgreso.descripcion = egreso.motivoPago
    empresa.modeloEgreso.fecha = egreso.fechaSolicitud
    empresa.modeloEgreso.tipoPago = egreso.tipoPago
    empresa.modeloEgreso.monto = (egreso.montoPago * empresa.porcentaje) / 100
    empresa.modeloEgreso.montoCuota = egreso.montoCuota
    empresa.modeloEgreso.responsable = egreso.nombreResponsable
    empresa.modeloEgreso.numeroCuota = egreso.numeroCuota
    empresa.modeloEgreso.grupoGastos = 'TRANSFERENCIAS CASA MATRIZ'
    empresa.modeloEgreso.archivos = egreso.archivos
    empresa.modeloEgreso.idUsuario = egreso.idUsuario
    console.log('modelo empresa en funcion registrar', empresa.modeloEgreso)
    //this.modeloEgresoGastoPersonal.monto =
    //                 this.addressForm.value.montoPago

    // Llamar al método correcto utilizando el nombre almacenado en 'metodo'
    if (
      empresa.servicio &&
      typeof empresa.servicio[empresa.metodo] === 'function'
    ) {
      empresa.servicio[empresa.metodo](empresa.modeloEgreso)
        .pipe(first())
        .subscribe(
          (data) => {
            this.snackBar.open('Agrego cuenta con éxito', 'cerrar', {
              duration: 2000,
              verticalPosition: 'top'
            })
          },
          (error) => {
            this.snackBar.open(
              'Tenemos problemas para realizar el registro, por favor contactar al equipo de desarrollo',
              'cerrar',
              { duration: 2000, verticalPosition: 'top' }
            )
            this.snackBar.open(error, 'cerrar', {
              duration: 3000,
              verticalPosition: 'top'
            })
          }
        )
    } else {
      console.error(
        `El método ${empresa.metodo} no está definido en el servicio para ${empresa.nombre}`
      )
    }
  }
  // //////////////////////////////////////MODIFICACION ISAI ///////////////////////////////////////

  subirFactura() {
    try {
      this.loading2 = true
      const formularioDeDatos = new FormData()
      this.fotoFactura.forEach((fotoFactura) => {
        formularioDeDatos.append('File', fotoFactura)
      })
      this.http
        .post(
          //`http://localhost:3000/api/solicitudBancaria/uploadFactura`,
          `${environment.apiUrl}/solicitudBancaria/uploadFactura`,
          formularioDeDatos
        )
        .subscribe((res) => {
          this.loading2 = false
          this.snackBar.open(`Imagen cargada con exito`, 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
        })
    } catch (e) {
      this.loading2 = false
      this.snackBar.open(`Error al cargar imagen`, 'Cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
      console.log('ERROR AL SUBIR IMG', e)
    }
  }

  CuentaBancaria() {
    if (
      this.f.estado.value === 'Pagado' ||
      this.f.estado.value === 'Rechazada' ||
      this.f.estado.value === 'Devuelto'
    ) {
      switch (this.addressForm.status) {
        case 'VALID':
          this.consolidadoBancarioService
            .updateCuentaBancaria(
              this.UpdateCuentaBancaria[0].id,
              this.UpdateCuentaBancaria[0]
            )
            .pipe(first())
            .subscribe(
              (data) => {
                this.snackBar.open('Cuenta editada con éxito.', 'Cerrar', {
                  duration: 2000,
                  verticalPosition: 'top'
                })
                this.addressForm.reset()
                this.consolidadoBancarioService.closeDialogModal()
                // window.location.reload()
              },
              (error) => {
                this.snackBar.open(
                  'No se pudo editar la cuenta, favor contactar a informática.',
                  'Cerrar',
                  {
                    duration: 2000,
                    verticalPosition: 'top'
                  }
                )
              }
            )

          break
        case 'INVALID':
          this.snackBar.open('El formulario debe ser completado.', 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
          break

        default:
          break
      }
    } else {
      this.snackBar.open('Falta cambiar estado.', 'Cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
    }
  }

  //Desshabilutar mat-expansion-panel
  moveUp() {
    this.clickButton = true
  }
  //Cerrar Modal
  closeDialog() {
    this.administracionService.closeDialogModal()
    this.consolidadoBancarioService.closeDialogModal()
    // window.location.reload()
  }

  onSubmit() {
    this.consolidadoBancarioService
      .getByIdPagoBancario(this.idPagoBancario)
      .pipe(first())
      .subscribe((x: any) => {
        this.factura = x[0].estadoFactura

        this.imagen = x[0].fotoFactura
      })

    //===> Cambia el estado
    if (
      this.addressForm.value.estado === 'Saldado' ||
      this.addressForm.value.estado === 'Pagado' ||
      this.addressForm.value.estado === 'Rechazada' ||
      this.addressForm.value.estado === 'Devuelto'
    ) {
      if (this.dataArrayFactura.length > 0) {
        this.addressForm.value.fotoFactura = this.dataArrayFactura
        this.addressForm.value.estadoFactura = 'Por Confirmar'
      } else if (this.fotoFactura) {
        // Si no hay factura nueva pero hay una factura actual, mantenerla
        this.addressForm.value.fotoFactura = this.factura
      }

      if (this.dataArrayImg.length > 0) {
        this.addressForm.value.archivos = this.dataArrayImg
      } else if (this.fotoFactura) {
        // Si no hay factura nueva pero hay una imagen actual, mantenerla
        this.addressForm.value.archivos = this.imagen
      }
      if (this.dataArrayDevuelto.length > 0) {
        this.addressForm.value.archivoDevuelto = this.dataArrayDevuelto
      }

      if (this.addressForm.value.estadoFactura === null) {
        this.addressForm.value.estadoFactura = 'Por Emitir'
      }
      if (this.addressForm.value.estadoFactura === 'Por Confirmar') {
        this.addressForm.value.confirmado = this.addressForm.get(
          'confirmadoPorFinanzas'
        ).value
      }
      if (this.addressForm.value.confirmadoPorFinanzas === true) {
        this.addressForm.value.confirmadoPorFinanzas = 'Confirmado'
      }
      if (this.addressForm.value.confirmadoPorFinanzas === false) {
        this.addressForm.value.confirmadoPorFinanzas = 'No Confirmado'
      }
      this.addressForm.value.nombreCompleto = this.nombreCompleto
      switch (this.addressForm.status) {
        case 'VALID':
          this.consolidadoBancarioService
            .updatePagoBancario(this.idPagoBancario, this.addressForm.value)
            .pipe(first())
            .subscribe(
              (data) => {
                this.snackBar.open('Operacion realizada con exito', 'Cerrar', {
                  duration: 2000,
                  verticalPosition: 'top'
                })
                this.addressForm.reset()
                this.consolidadoBancarioService.closeDialogModal()
                 window.location.reload()
                this.updateServicioCompartidoConsolidado()
              },
              (error) => {
                this.snackBar.open(
                  'No se pudo editar la cuenta, favor contactar a informática.',
                  'Cerrar',
                  {
                    duration: 2000,
                    verticalPosition: 'top'
                  }
                )
              }
            )
          break
        case 'INVALID':
          this.snackBar.open('El formulario debe ser completado.', 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
          break

        default:
          break
      }
    }
    //===> Division en %
    if (this.addressForm.value.estado === 'Rechazada') {
      this.estado = 'Rechazada'
      switch (this.addressForm.status) {
        case 'VALID':
          this.consolidadoBancarioService
            .updatePagoBancario(this.idPagoBancario, this.addressForm.value)
            .pipe(first())
            .subscribe(
              (data) => {
                this.snackBar.open('Operacion realizada con exito', 'Cerrar', {
                  duration: 2000,
                  verticalPosition: 'top'
                })
                this.addressForm.reset()
                this.consolidadoBancarioService.closeDialogModal()
                 window.location.reload()
              },
              (error) => {
                this.snackBar.open(
                  'No se pudo editar la cuenta, favor contactar a informática.',
                  'Cerrar',
                  {
                    duration: 2000,
                    verticalPosition: 'top'
                  }
                )
              }
            )
          break
        case 'INVALID':
          this.snackBar.open('El formulario debe ser completado.', 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
          break

        default:
          break
      }
      //====> Mejorar interfaz de usuario cuando este rechazada!
      console.log('Rechazada')
    }
    // //////////////////////////////////////MODIFICACION ISAI ///////////////////////////////////////
    else {
      const empresas = [                                          //El array de empresas contiene las empresas en las cuales se divide el gasto de Casa Matriz con sus porsentajes(llama a los servicios y modelos correspondientes)
        {
          nombre: 'Rent a Car',
          porcentaje: 45,
          servicio: this.rentacarService,
          modeloEgreso: this.modeloEgresoRentcar,
          metodo: 'EgresoRentACar'
        },
        {
          nombre: 'Firma',
          porcentaje: 30,
          servicio: this.firmaAbogadoService,
          modeloEgreso: this.modeloEgresoFirmaAbogado,
          metodo: 'egresoRegistrar'
        },
        {
          nombre: 'Lubricentro',
          porcentaje: 10,
          servicio: this.lubricentroService,
          modeloEgreso: this.modeloEgresoLubricentro,
          metodo: 'egresoRegistrar'
        },
        {
          nombre: 'Importadora',
          porcentaje: 5,
          servicio: this.importadoraService,
          modeloEgreso: this.modeloEgresoImportadora,
          metodo: 'createEgresosFijo'
        },
        {
          nombre: 'Hostal',
          porcentaje: 10,
          servicio: this.hostalService,
          modeloEgreso: this.modeloEgresoHostal,
          metodo: 'egresoRegistrar'
        }
      ]

      const empresas2 = [                               // el array empresas2 contiene las empresas que o bien son un DIV o para sucursales que solo tienen asociado una empresa en particuar
        {
          id: 4, // idEmpresa
          nombre: 'Rent a Car',
          servicio: this.rentacarService,
          modeloEgreso: this.modeloEgresoRentcar,
          metodo: 'EgresoRentACar'
        },
        {
          id: 2,
          nombre: 'Firma',/* Abogados */
          servicio: this.firmaAbogadoService,
          modeloEgreso: this.modeloEgresoFirmaAbogado,
          metodo: 'egresoRegistrar'
        },
        {
          id: 3,
          nombre: 'Lubricentro',
          servicio: this.lubricentroService,
          modeloEgreso: this.modeloEgresoLubricentro,
          metodo: 'egresoRegistrar'
        },
        {
          id: 9,
          nombre: 'Importadora',
          servicio: this.importadoraService,
          modeloEgreso: this.modeloEgresoImportadora,
          metodo: 'createEgresosFijo'
        },
        {
          id: 1,
          nombre: 'Hostal',
          servicio: this.hostalService,
          modeloEgreso: this.modeloEgresoHostal,
          metodo: 'egresoRegistrar'
        },
        {
          nombre: 'Gasto Personal',                                 //A excepcion de Gasto Personal este no se puede mover a menos que se cambie desde el codigo ya que es el unico que lo busca por posicion en el array 
          servicio: this.gastoService,
          modeloEgreso: this.modeloEgresoGastoPersonal,
          metodo: 'createEgresos'
        },
        {
          id: 5,
          nombre: 'Inmobiliaria',
          servicio: this.inmobiliariaService,
          modeloEgreso: this.modeloEgresoInmobiliaria,
          metodo: 'egresoInmobiliaria'
        }
      ]

      // Definir los ids que requieren la empresa [5] de empresas2
      const idsEspeciales = [122, 121, 120, 119, 118, 127]                            //Los ID especiales se ocupan para las Sucursales o cuenta que van a gasto personal en caso de queiran agregar a otra persona o sucurlas apuntado a gasto personal
      console.log('antes del if de id sucrusales')
      if (this.idSucursal === 1) {
        if (this.dataArrayImg.length > 0 || this.dataArrayDevuelto.length > 0) {
          this.addressForm.value.archivos = this.dataArrayImg
          this.addressForm.value.archivoDevuelto = this.dataArrayDevuelto

          if (this.addressForm.status === 'VALID') {
            empresas.forEach((empresa) => {
              this.registrarEgresoEnEmpresa.call(
                this,
                empresa,
                this.addressForm.value
              )
            })
          }
        }
      }

      // Comprobar si el idSucursal está en el array idsEspeciales
      if (idsEspeciales.includes(this.idSucursal)) {
        console.log('encontro una id especial')
        // Seleccionar la empresa en la posición [5] del array empresas2
        let nuevasEmpresas: any[] = []
        const empresaSeleccionada = empresas2[5]
        nuevasEmpresas.push(empresas2[5])
        const numeroDeEmpresas = nuevasEmpresas.length
        const porcentajeDividido = 100 / numeroDeEmpresas
        nuevasEmpresas.forEach((empresa) => {
          empresa.porcentaje = porcentajeDividido
          this.registrarEgresoEnEmpresa(empresa, this.addressForm.value)
        })

        // Lógica para registrar el egreso con la empresa seleccionada
      } else {
        console.log('paso al else')
        const sucursalEmpresasMap = {                                     // Estos ID corresponden a los DIV de sucrusales Para agregar una sucursal con gasto dividio se buscan las posiciones dende esta el array de empresas
          86: [empresas[0], empresas[2], empresas[3]],
          87: [empresas[0], empresas[1]],
          88: [empresas[0], empresas[1]],
          89: [empresas[0], empresas[2], empresas[3]],
          90: [empresas[0], empresas[2], empresas[3]]
          // Agrega aquí más sucursales si es necesario dividir el gasto
        }

        // Primero verifica si la sucursal está en la lista de división de gastos
        if (sucursalEmpresasMap[this.idSucursal]) {
          // Si la sucursal requiere división de gastos, usa el mapeo
          let nuevasEmpresas = sucursalEmpresasMap[this.idSucursal]
          console.log('empresa con gasto compartido', nuevasEmpresas)

          const numeroDeEmpresas = nuevasEmpresas.length
          const porcentajeDividido = 100 / numeroDeEmpresas

          nuevasEmpresas.forEach((empresa) => {
            empresa.porcentaje = porcentajeDividido
            this.registrarEgresoEnEmpresa(empresa, this.addressForm.value)
          })
        } 
        
        
        else {
          console.log('segundo else')

          if (
            this.dataArrayImg.length > 0 ||
            this.dataArrayDevuelto.length > 0
          ) {
            this.addressForm.value.archivos = this.dataArrayImg
            this.addressForm.value.archivoDevuelto = this.dataArrayDevuelto
            console.log('omaiga')
            if (this.addressForm.status === 'VALID') {
              console.log('skere')
              this.sucursalService
                .getById(this.idSucursal)
                .pipe(first())
                .subscribe((x: any) => {
                  console.log('entro a la funcion')
                  const idEmpresa = x.idEmpresa
                  console.log(idEmpresa)
                  let nuevasEmpresas = []

                  if (sucursalEmpresasMap[this.idSucursal]) {
                    // Si la sucursal requiere división de gastos, usar el mapeo
                    nuevasEmpresas = sucursalEmpresasMap[this.idSucursal]
                  } else {
                    // Buscar la empresa seleccionada directamente en empresas2 si es un array de objetos
                    // El número que estás buscando
                    let nuevasEmpresas: any[] = [] // Declarar nuevasEmpresas como un array vacío

                    for (let i = 0; i < empresas2.length; i++) {
                      if (empresas2[i].id === idEmpresa) {
                        nuevasEmpresas.push(empresas2[i]) // Agregar el objeto al array
                        break // Termina el bucle al encontrar la empresa
                      }
                    }

                    if (nuevasEmpresas.length > 0) {
                      console.log('Empresa encontrada:', nuevasEmpresas)
                      const numeroDeEmpresas = nuevasEmpresas.length
                      const porcentajeDividido = 100 / numeroDeEmpresas
                      console.log(nuevasEmpresas)
                      nuevasEmpresas.forEach((empresa) => {
                        empresa.porcentaje = porcentajeDividido
                        this.registrarEgresoEnEmpresa(
                          empresa,
                          this.addressForm.value
                        )
                      })
                    } else {
                      console.log(
                        'No se encontró una empresa con el id especificado.'
                      )
                    }
                  }

                  const numeroDeEmpresas = nuevasEmpresas.length
                  const porcentajeDividido = 100 / numeroDeEmpresas
                  console.log(nuevasEmpresas)
                  nuevasEmpresas.forEach((empresa) => {
                    empresa.porcentaje = porcentajeDividido
                    this.registrarEgresoEnEmpresa(
                      empresa,
                      this.addressForm.value
                    )
                  })
                })
            }
          }
        }
      }
      // //////////////////////////////////////FIN DE MODIFICACION ISAI ///////////////////////////////////////
    }
  }

  verificarURL() {
    // Verificar si la URL no comienza con "http://" o "https://"
    if (
      !this.linkValue.startsWith('http://') &&
      !this.linkValue.startsWith('https://')
    ) {
      // Si no comienza con ninguno de los dos, agregar "https://"
      this.linkValue = 'https://' + this.linkValue
    }
  }
}
