<h1>Registro de Solicitud Orden de Compra</h1>
<div class="mat-elevation-z8">
  <mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
      <mat-panel-title> Solicitudes O/C!</mat-panel-title>
      <mat-panel-description>
        Para Crear una Solicitud de Orden de Compra Presiona Aqui
        <mat-icon>touch_app_white_18dp</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-horizontal-stepper class="ancho-contenedor" linear>

      <!-- Informacion Basica -->
      <mat-step [stepControl]="infBasica" completed="false" style="background-color: brown;">
        <form [formGroup]="infBasica" novalidate>
          <ng-template matStepLabel>Informacion Basica</ng-template>
          <!-- Fila 1 -->
          <div class="row">
            <!-- Estado -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <input matInput placeholder="Estado" formControlName="estado" readonly="»readonly»" />
                <mat-icon matSuffix>assignment_turned_in</mat-icon>
              </mat-form-field>
            </div>

            <!-- Fecha Solicitud-->
            <div class="col">
              <mat-form-field appearance="legacy">
                <input
                  matInput
                  [matDatepicker]="picker"
                  placeholder="Ingresa La Fecha"
                  formControlName="fechaSolicitud"
                  [max]="maxDate"
                  [matDatepickerFilter]="myFilter"

                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>

          <!-- nombre de Responsable -->
          <div class="col">
            <mat-form-field appearance="legacy">
              <input matInput formControlName="nombreResponsable" readonly="»readonly»" />
              <mat-icon matSuffix>account_circle</mat-icon>
            </mat-form-field>
          </div>

          </div>
          -
          <!-- Fila 2 -->
          <div class="row">
            <!--inversion -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <mat-label>Tipo Egreso</mat-label>
                <mat-select formControlName="inversion">
                  <mat-option value="Gasto"> Gasto </mat-option>
                  <mat-option value="Inversion">Inversion</mat-option>
                  <mat-option value="Remuneraciones">Remuneraciones</mat-option>
                  <mat-option value="Mantencion Automotriz">Mantencion Automotriz</mat-option>
                  <mat-option value="Pago de Cuota Automotriz">Pago de Cuota Automotriz</mat-option>
                  <mat-option value="Pago de Cuota Bancaria">Pago de Cuota Bancaria</mat-option>
                  <mat-option value="Reparacion">Reparacion</mat-option>
                  <mat-option value="Repuestos">Repuestos</mat-option>
                  <mat-option value="Pago Reemplazo">Pago Reemplazo</mat-option>
                  <mat-option value="Prestamos">Prestamos</mat-option>
                  <mat-option value="Traslado en Camion">Traslado en Camion</mat-option>
                  <mat-option value="Educacion">Educación</mat-option>
                  <mat-option value="Caja Chica">Caja chica</mat-option>
                  <mat-option value="Remate">Remate</mat-option>
                  <mat-option value="Devolucion">Devolucion</mat-option>

                </mat-select>
                <mat-icon matSuffix>account_balance_wallet</mat-icon>
              </mat-form-field>
            </div>
            <!-- Motivo de pago  -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <input matInput type="text" placeholder="Ingresa Motivo de Pago " formControlName="motivoPago" (input)="onInputChange($event)"/>
                <mat-icon matSuffix>question_answer</mat-icon>

              </mat-form-field>
            </div>
           
            <!-- jefe Autoriza  -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <mat-label>Selecciona Jefe Autoriza </mat-label>
                <mat-select formControlName="jefeAutoriza">
                  <mat-option value="Diego Vargas">Diego Vargas</mat-option>
                  <mat-option value="Solanch Tejos">Solanch Tejos</mat-option>
                  <mat-option value="Miguel Vargas">Miguel Vargas</mat-option>
                  <mat-option value="Braulio Vargas">Braulio Vargas</mat-option>
                  <mat-option value="Evelyn Gallegos">Evelyn Gallegos</mat-option>

                </mat-select>
                <mat-icon matSuffix>supervisor_account</mat-icon>
              </mat-form-field>
            </div>
          </div>
          <!-- Fila 3 -->
          <div class="row">
            
            <!-- Monto de pago  -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <input matInput type="text" placeholder="Ingresa Monto De Pago" formControlName="montoPago"
                  [ngModel]="result2" (ngModelChange)="transform($event)" (keypress)="restrictNumeric($event)" />
                <span matPrefix>$&nbsp;</span>
                <mat-icon matSuffix>attach_money_18dp</mat-icon>

              </mat-form-field>
            </div>

            <!-- asume Factura -->
           <!--  <div class="col">
              <mat-form-field appearance="legacy">
                <input matInput placeholder="Cuenta que Asume Gasto" formControlName="asumeFactura"
                  (ngModel)="termino" [matAutocomplete]="auto" (input)="getCuentaBancaria()" />
                <mat-icon matSuffix>business</mat-icon>
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                  (optionSelected)="opcionSeleccionada($event)" [displayWith]="displayFn">
                  <mat-option *ngFor="let cuenta of cuentas" [value]="cuenta" class="uppercase">
                    {{ cuenta.nombreCuenta }} 
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
 -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <input matInput placeholder="Cuenta que Asume Gasto" formControlName="asumeFactura"
                  [(ngModel)]="termino" [matAutocomplete]="auto" (input)="getCuentaBancaria()" />
                <mat-icon matSuffix>business</mat-icon>
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [panelWidth]="panelWidth"
                  (optionSelected)="opcionSeleccionada($event)">
                  <mat-option *ngFor="let Cuenta of cuentas" [value]="Cuenta.nombreCuenta  + ' ' + Cuenta.banco  +  ' '  + Cuenta.rut">
                    {{ Cuenta.nombreCuenta }} {{ Cuenta.banco }} {{Cuenta.rut}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <!-- empresa Asume Gasto -->
            <!-- <div class="col">
              <mat-form-field appearance="legacy">
                <input matInput placeholder="Empresa Asume Gasto" formControlName="empresaAsumeGasto"
                  [value]="this.empresaAsumeGasto" readonly="»readonly»" />
                <mat-icon matSuffix>store_18dp</mat-icon>
              </mat-form-field>
            </div> -->

            <div class="col">
              <mat-form-field class="full-width">
                <mat-select placeholder="Empresa que asume el gasto" formControlName="empresaAsumeGasto">
                  <mat-option *ngFor="let tipo of empresaAsumeGastos" [value]="tipo">
                    
                    {{ tipo }}
                  </mat-option>
                 
                </mat-select>
                <mat-error *ngIf="
                       infBasica.controls['empresaAsumeGasto'].hasError('required')
                      ">
                    este campo es <strong>requrido</strong>
                  </mat-error>
                <mat-icon matSuffix>store_18dp</mat-icon>
              </mat-form-field>
            </div>
            <!-- sucursales -->
            <div class="col">
              <mat-form-field class="full-width">
                <mat-select placeholder="Sucursales" formControlName="idSucursal">
                  <mat-option *ngFor="let s of sucursales" value="{{ s.id }}">
                    {{ s.razonSocial }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="infBasica.controls['idSucursal'].hasError('required')">
                  sucursal es <strong>requrido</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <!-- Fila 4 -->
          <div class="row">
            <!-- Link -->
            <div class="col">
              <mat-form-field appearance="legacy">
                <input matInput placeholder="Link" formControlName="link" (input)="cambioBoton($event)" />
                <mat-icon matSuffix>link</mat-icon>
              </mat-form-field>
            </div>
          </div>

          <!-- boton  de acciones-->
          <div>
            <button mat-button matStepperNext *ngIf="!mostrarBotonGuardar">
              Siguiente <mat-icon>arrow_forward_ios_white_18dp</mat-icon>
            </button>

            <button *ngIf="mostrarBotonGuardar" mat-raised-button color="primary" type="submit" (click)="onSubmit()">
              <mat-icon>send</mat-icon> Enviar
            </button>

          </div>
        </form>
      </mat-step>

      <!-- Datos Transferencia -->
      <mat-step [stepControl]="infDtTransferencia">
        <form [formGroup]="infDtTransferencia" novalidate (ngSubmit)="onSubmit()">
          <ng-template matStepLabel>Informacion Datos de Transferencia</ng-template>
          <div class="row" >
                <button type="button" mat-fab extended style="width: 10%"
                style="margin-right: 4%; margin-left: 2%; margin-top: -6px;  background-color: green;"
                matTooltip="Añade un nuevo destinatario" (click)="crearDestinatario()">
                <mat-icon>group_add</mat-icon>
              </button>
                  <mat-form-field class="legacy" style="width: 45%; margin-right: 4%;">
                    <input type="text" 
                           placeholder="Seleccione Destinatario"
                           aria-label="Number"
                           matInput 
                           [formControl]="destinatariosControl" 
                           [matAutocomplete]="autoDestinatario">
                    <mat-autocomplete autoActiveFirstOption #autoDestinatario="matAutocomplete" (optionSelected)="selectEvent($event); destinatariosControl.setValue('')">
                      <mat-option *ngFor="let destinatario of filteredOptions2 | async " [value]="destinatario">
                        {{ destinatario.nombreTransferencia }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
            <div *ngIf="destinatarioExiste"
            style="border-radius: 10px;width: 35%;padding: 1%;"
            >
                  <h3 style="margin-bottom: -1%;">Datos del Destinatario</h3>
                  <hr style="margin-top: -1px;">
                  <div><strong class="mr-1">Nombre:</strong> {{ datosDestinatario[0] }}</div>
                  <div><strong>Rut:</strong> {{ datosDestinatario[1] }}</div>
                  <div><strong>N° Cuenta:</strong> {{ datosDestinatario[2] }}</div>
                  <div><strong>Banco:</strong> {{ datosDestinatario[3] }}</div>
                  <div><strong>Tipo de cuenta:</strong> {{ datosDestinatario[4] }}</div>
                  <div><strong>Correo:</strong> {{ datosDestinatario[5] }}</div>
              </div>
            </div>
   
          <div class="row anchoFila-100">
            <!-- Nombre Destinatario -->
            <div *ngIf="!destinatarioExiste" class="column-xl m-r-2">
              <mat-form-field class="element-hijo-tamaño" appearance="legacy">
                <input  matInput placeholder="Ingresa nombre del destinatario"
                  formControlName="nombreTransferencia" />
              </mat-form-field>
            </div>

            <!-- rut -->
            <div *ngIf="!destinatarioExiste" class="column-s m-r-2">
              <mat-form-field appearance="legacy" class="element-hijo-tamaño">
                <mat-label>Rut</mat-label>
                <input
                 (input)="mostrarButonEnviar($event)"
                  matInput
                  minlength="8"
                  maxlength="10"
                  placeholder="Ingresa Rut"
                  formControlName="rut"
                  required
                  [(ngModel)]="tamano_rut"
                  (input)="formatoRut()"
                  type="string"
                />
              </mat-form-field>
            </div>

             

            <!-- Numero de cuenta -->
            <div *ngIf="!destinatarioExiste" class="column-s m-r-2">
              <mat-form-field appearance="legacy" class="element-hijo-tamaño">
                <input matInput placeholder="Ingresa Numero De Cuenta" formControlName="NumeroCuenta" />
              </mat-form-field>
            </div>

            <!-- Tipo banco   -->
            <div *ngIf="!destinatarioExiste" class="column-xl m-r-2">
              <mat-form-field appearance="legacy" class="element-hijo-tamaño">
                <mat-label>Selecciona Banco Cliente </mat-label>
                <mat-select formControlName="bancoCliente">
                  <mat-option value="BancoItau"> Banco Itau </mat-option>
                  <mat-option value="BancoEstado"> Banco Estado </mat-option>
                  <mat-option value="BancoSantander">
                    Banco Santander - Santiago
                  </mat-option>
                  <mat-option value="BancoChile">
                    Banco de Chile / Edwards-Citi</mat-option>
                  <mat-option value="BCI - MACH">
                    BCI - MACH</mat-option>
                  <mat-option value="BancoFalabella">
                    Banco Falabella</mat-option>
                  <mat-option value="BancoScotiabank">
                    Banco Scotiabank Sud Americano</mat-option>
                  <mat-option value="BancoSecurity"> Banco Security</mat-option>
                  <mat-option value="BancoScotiabankAzul">
                    Banco Scotiabank Azul-Ex BBVA</mat-option>
                  <mat-option value="BancoInternacional">
                    Banco Internacional</mat-option>
                  <mat-option value="BancoCorpbanca">
                    Banco Corpbanca</mat-option>
                  <mat-option value="BancoBice"> Banco Bice</mat-option>
                  <mat-option value="BancoHsbc">
                    Banco HSBC Bank Chile</mat-option>
                  <mat-option value="BancoMufg">
                    Banco MUFG Bank Chile</mat-option>
                  <mat-option value="BancoSudAmericano">
                    Banco Sud Americano</mat-option>
                  <mat-option value="BancoRipley">
                    Banco Ripley/Chek</mat-option>
                  <mat-option value="BancoRabobank"> Banco Rabobank</mat-option>
                  <mat-option value="BancoConsorcio">
                    Banco Consorcio</mat-option>
                  <mat-option value="BancoParis"> Banco Paris</mat-option>
                  <mat-option value="BancoDesarrollo">
                    Banco de Desarrollo</mat-option>
                  <mat-option value="BancoCoopeuch"> Banco Coopeuch</mat-option>
                  <mat-option value="BancoHeroes">
                    Banco Prepago los Heroes</mat-option>
                  <mat-option value="BancoTenpo"> Banco Tenpo SA</mat-option>
                  <mat-option value="BancoDoBrasil">
                    Banco Do Brasil</mat-option>
                  <mat-option value="BancoMorgan">
                    Banco JP Morgan Chass Bank</mat-option>
                  <mat-option value="BancoPactual">
                    Banco BTG Pactual Chile</mat-option>
                  <mat-option value="BancoChinaConstruccion">
                    Banco China Construccion Bank AG Chile</mat-option>
                  <mat-option value="BancoChina">
                    Banco Bank of China</mat-option>
                  <mat-option value="BancoTransbank">
                    Banco Transbank</mat-option>
                  <mat-option value="BancoMercadoPago">
                    Banco Mercado Pago Emisora SA</mat-option>
                  <mat-option value="BancoCajaLosAndes">
                    Banco TAPP Caja los Andes</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <!-- Tipo Cuenta   -->
            <div *ngIf="!destinatarioExiste" class="column-xl m-r-2">
              <mat-form-field appearance="legacy" class="element-hijo-tamaño">
                <mat-label>Selecciona Tipo Cuenta </mat-label>
                <mat-select formControlName="tipoCuenta">
                  <mat-option value="Chequera Electronica">Chequera Electronica</mat-option>
                  <mat-option value="Cuenta Corriente">Cuenta Corriente</mat-option>
                  <mat-option value="Cuenta Vista">Cuenta Vista</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <!-- Nombre Correo -->
            <div *ngIf="!destinatarioExiste" class="column-xl ">
              <mat-form-field appearance="legacy" class="element-hijo-tamaño">
                <input matInput placeholder="Ingresa Correo a Transferir" formControlName="correo" />
                <mat-icon matSuffix>email</mat-icon>
              </mat-form-field>
            </div>
          </div>

         
          
          <!-- boton  de acciones-->
          <div>
            <button mat-button matStepperPrevious >
              <mat-icon>arrow_back_ios_new_white_18dp</mat-icon> Anterior
            </button>
            <!-- <button mat-raised-button color="primary" type="submit" [disabled]="infDtTransferencia.invalid"> -->
              <button *ngIf="mostrarBotonGuardar" mat-raised-button color="primary" type="submit" [disabled]="isSubmitting">
              <mat-icon>send</mat-icon> Enviar
            </button>
          </div>

         
      

        </form>
      </mat-step>
    </mat-horizontal-stepper>
  </mat-expansion-panel>
</div>
