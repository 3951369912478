<div class="mat-elevation-z8">
  <mat-card>
    <mat-toolbar color="primary">
      <span>Pago Orden de Compra</span>
      <button
        style="margin-left: 1rem; margin-bottom: 3px"
        mat-raised-button
        color="accent"
        (click)="actualizar()"
      >
        Actualizar tabla <mat-icon>autorenew_white_18dp</mat-icon>
      </button>
      <br />

      <button
        mat-raised-button
        color="accent"
        style="margin-left: 1rem; margin-bottom: 3px"
        (click)="exportAsXLSX()"
      >
        Exportar a Excel <mat-icon>description_white_18dp</mat-icon>
      </button>
    </mat-toolbar>
  </mat-card>

  <br />
  <div class="button-container">
    <button
      mat-raised-button
      color="accent"
      style="margin-left: 1rem; margin-bottom: 3px"
      type="reset"
      (click)="aceptada()"
    >
      Autorizado
      <mat-icon>checklist_white_18dp</mat-icon>
    </button>
    <button
      mat-raised-button
      color="accent"
      style="margin-left: 1rem; margin-bottom: 3px"
      (click)="actualizar()"
    >
      Todos
      <mat-icon>list_white_18dp</mat-icon>
    </button>
    <button
      mat-raised-button
      color="accent"
      style="margin-left: 1rem; margin-bottom: 3px"
      (click)="saldado()"
    >
      Pagado
      <mat-icon>paid_18dp</mat-icon>
    </button>
    <button
      mat-raised-button
      color="accent"
      style="margin-left: 1rem; margin-bottom: 3px"
      (click)="rechazada()"
    >
      Rechazada
      <mat-icon>cancel_white_18dp</mat-icon>
    </button>
    <button
      mat-raised-button
      color="accent"
      style="margin-left: 1rem; margin-bottom: 3px"
      (click)="Devuelto()"
    >
      Devuelta
      <mat-icon>cancel_white_18dp</mat-icon>
    </button>
  </div>

  <mat-form-field appearance="standard">
    <mat-label>Buscar</mat-label>
    <input
      matInput
      (keyup)="applyFilter($event)"
      placeholder="Ingresa Nombre"
      #input
    />
  </mat-form-field>

  <table
    mat-table
    [dataSource]="dataSource"
    matTableExporter
    matSort
    #exporter="matTableExporter"
    matSortActive="id"
    matSortDirection="desc"
    class="mat-elevation-z10"
  >
    <!-- SELECCCION -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- id Columna -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>id</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <!-- Nombre  Columna -->
    <ng-container matColumnDef="nombreTransferencia">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
      <td mat-cell *matCellDef="let element">
        {{ element.nombreTransferencia }}
      </td>
    </ng-container>

    <!-- rut Columna -->
    <ng-container matColumnDef="rut">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Rut</th>
      <td mat-cell *matCellDef="let element">
        {{ element.rut }}
      </td>
    </ng-container>

    <!-- Numero Cuenta Columna -->
    <ng-container matColumnDef="NumeroCuenta">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Numero Cuenta</th>
      <td mat-cell *matCellDef="let element">
        {{ element.NumeroCuenta }}
      </td>
    </ng-container>

    <!-- jefeAutoriza Columna -->
    <ng-container matColumnDef="jefeAutoriza">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Jefe Autoriza</th>
      <td mat-cell *matCellDef="let element">
        {{ element.jefeAutoriza }}
      </td>
    </ng-container>

    <!-- Campo Fecha -->
    <ng-container matColumnDef="fechaSolicitud" class="mat-column-date">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="limit-width-180"
      >
        <mat-form-field appearance="fill" class="limit-width-180">
          <mat-label>Fecha</mat-label>
          <mat-date-range-input [formGroup]="formFilter" [rangePicker]="picker">
            <input
              matStartDate
              formControlName="start"
              placeholder="Start date"
            />
            <input matEndDate formControlName="end" placeholder="End date" />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error
            *ngIf="formFilter.controls.start.hasError('matStartDateInvalid')"
            >Invalid start date
          </mat-error>
          <mat-error
            *ngIf="formFilter.controls.end.hasError('matEndDateInvalid')"
            >Invalid end date
          </mat-error>
        </mat-form-field>
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.fechaSolicitud | date : 'dd/MM/yyyy' }}
      </td>
    </ng-container>

    <!-- estado Columna -->
    <ng-container matColumnDef="estado">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
      <td mat-cell *matCellDef="let element">
        <div style="display: flex; align-items: center">
          <ng-container
            *ngIf="
              element.estado == 'Aceptada' || element.estado == 'Autorizada'
            "
          >
            Autorizado
            <mat-icon style="-webkit-text-fill-color: green"
              >check_box_white_18dp</mat-icon
            >
          </ng-container>
          <ng-container *ngIf="element.estado == 'Pendiente'">
            {{ element.estado }}
            <mat-icon style="-webkit-text-fill-color: rgb(239, 108, 8)"
              >pending_actions_18dp</mat-icon
            >
          </ng-container>
          <ng-container *ngIf="element.estado == 'Rechazada'">
            {{ element.estado }}
            <mat-icon style="-webkit-text-fill-color: rgb(216, 58, 37)"
              >cancel_white_18dp</mat-icon
            >
          </ng-container>
          <ng-container
            *ngIf="element.estado == 'Saldado' || element.estado == 'Pagado'"
          >
            Pagado
            <mat-icon style="-webkit-text-fill-color: rgb(37, 115, 216)"
              >check_box_white_18dp</mat-icon
            >
          </ng-container>
          <ng-container *ngIf="element.estadoFactura == 'Por Confirmar'">
            Emitida
            <mat-icon style="-webkit-text-fill-color: rgb(0, 242, 255)"
              >check_circle-24px</mat-icon
            >
          </ng-container>
          <ng-container *ngIf="element.estadoFactura == 'Por Emitir'">
            Por Emitir
            <mat-icon style="-webkit-text-fill-color: rgb(255, 166, 0)"
              >history-24px</mat-icon
            >
          </ng-container>
          <ng-container *ngIf="element.estado == 'Devuelto'">
            {{ element.estado }}
            <mat-icon style="-webkit-text-fill-color: rgb(255, 213, 0)"
              >cancel_white_18dp</mat-icon
            >
          </ng-container>
        </div>
      </td>
    </ng-container>

    <!-- montoPago Columna -->
    <ng-container matColumnDef="montoPago">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Monto</th>
      <td mat-cell *matCellDef="let element">
        <span matPrefix>$&nbsp;</span
        >{{ element.montoPago?.toLocaleString('es-CL') }}
        <!-- agregar .toLocaleString('es-CL') -->
      </td>
    </ng-container>
    <!-- Montivo Pago -->
    <ng-container matColumnDef="motivoPago">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Motivo Pago</th>
      <td mat-cell *matCellDef="let element">
        {{ element.motivoPago }}
      </td>
    </ng-container>

    <!-- confirmado por finazas -->
    <ng-container matColumnDef="confirmadoPorFinanzas">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Confirmado Finanzas</th>
      <td mat-cell *matCellDef="let element">
        <!-- {{ element.confirmadoPorFinanzas }} -->
        <div style="display: flex; align-items: center">
          <ng-container *ngIf="element.confirmadoPorFinanzas == 'Confirmado'">
          Confirmado
          <mat-icon style="-webkit-text-fill-color: rgb(0, 255, 64)"
            >check_circle-20px</mat-icon
          >
          </ng-container>
          <ng-container 
          *ngIf="element.confirmadoPorFinanzas == 'No Confirmado'">
            No confirmado
            <mat-icon style="-webkit-text-fill-color: rgb(255, 128, 0)"
              >history-20px</mat-icon
            >
          </ng-container>
         </div>
      </ng-container>
      
      
    <!-- <ng-container matColumnDef="confirmado">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Confirmado</th>
      <td mat-cell *matCellDef="let element">
        {{ element.confirmado? 'Confirmado' : 'No confirmado' }}
      </td>
    </ng-container> -->

    <!-- Perfil del Usario -->
    <ng-container matColumnDef="perfilUsuario">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Solicitado Por</th>
      <td mat-cell *matCellDef="let element">
        {{ element.perfilUsuario }}
      </td>
    </ng-container>

    <!-- Jefe Autoriza/Rechza -->
    <ng-container matColumnDef="nombreAutoriza">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Motivo Autoriza</th>
      <td mat-cell *matCellDef="let element">
        {{ element.razonApruebo }}
      </td>
    </ng-container>

    <!-- Motivo Rechazo -->
    <ng-container matColumnDef="razonRechazo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Motivo Rechazo</th>
      <td mat-cell *matCellDef="let element">
        {{ element.razonRechazo }}
      </td>
    </ng-container>

    <!-- Realizado Por -->
    <!-- <ng-container matColumnDef="apruebo_pagado">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Realizado Por</th>
      <td mat-cell *matCellDef="let element">
        {{ element.apruebo_pagado }}
      </td>
    </ng-container> -->

    <!-- correo Columna -->
    <ng-container matColumnDef="correo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Correo</th>
      <td mat-cell *matCellDef="let element">
        {{ element.correo }}
      </td>
    </ng-container>
    <!-- Acciones Columna -->
    <ng-container matColumnDef="acciones">
      <th mat-header-cell *matHeaderCellDef style="text-align: center">
        Acciones
      </th>
      <td mat-cell *matCellDef="let element">
        <div style="display: flex; align-items: center">
          <button
            mat-mini-fab
            color="primary"
            style="margin-left: 1rem; margin-bottom: 2px"
            (click)="accionesPagoCuentaBancaria(element.id)"
            [disabled]="
              rolUsuario !== 'Administrador' &&
              rolUsuario !== 'Finanza' &&
              rolUsuario !== 'Operaciones'
            "
          >
            <mat-icon>save_as_white_18dp</mat-icon>
          </button>

          <button
            mat-mini-fab
            style="margin-left: 1rem; margin-bottom: 2px rgb(10, 85, 32)"
            (click)="PagoBanco(element.id)"
            (click)="accionesPagoCuentaBancaria(element.id)"
            [disabled]="
              rolUsuario !== 'Administrador' && rolUsuario !== 'Finanza'
            "
          >
            <mat-icon>account_balance_18dp</mat-icon>
          </button>

          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              (click)="downloadImage(element.id)"
              [disabled]="
                rolUsuario !== 'Administrador' && rolUsuario !== 'Finanza'
              "
            >
              <mat-icon>file_download</mat-icon>
              <span>Descargar Imagen</span>
            </button>
          </mat-menu>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" class="row-with-bottom-line"></tr>
  </table>

  <mat-paginator
    [pageSizeOptions]="[10, 20, 30]"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
  <!-- Alerta "Por Emitir" -->
  <div class="alert" *ngIf="mostrarAlertaPorEmitir">
    Aún existen solicitudes Por Emitir. Por favor, subir factura.
  </div>
</div>
