export class nuevoContrato {
    id!: string;
    nContrato!: number;
    estadoPago!: string;
    montoContrato!: number;
    saldoPendiente!: number;
    idCliente!: number;
    idSucursal!: number;
    idUsuario!: number;
    fechaContrato!: string;
    file!:any
  }