import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Usuario } from '@models/shared/usuario';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertHelper } from '@app/_helpers/alert.helper';
import { first } from 'rxjs/operators';
import { BodegaService } from '../bodega.service';
import { EmpresaSharedService } from '@app/_pages/shared/shared-services/empresa-shared.service';
import { SucursalSharedService } from '@app/_pages/shared/shared-services/sucursal-shared.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-bodega-form',
  templateUrl: './bodega-form.component.html',
  styleUrls: ['./bodega-form.component.scss']
})
export class BodegaFormComponent implements OnInit {
  addressForm: FormGroup
  usuario: Usuario = JSON.parse(localStorage.getItem('usuario') + '')
  sucursales: any
  empresaSucursal: any
  empresas: any
  bodegaNueva: any
  selectedEmpresaId: any;

  // ? Configuración de formulario
  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private BodegaService: BodegaService,
    private empresaService: EmpresaSharedService,
    private alert: AlertHelper,
    private sucursalService: SucursalSharedService
  ) {
    this.addressForm = this.fb.group({
      nombre: [
        '',
        [
          Validators.required,
          Validators.minLength(5),
          Validators.pattern(/^[a-zA-Z 0-9]+$/)
        ]
      ],
      direccion: ['', [Validators.required, Validators.minLength(5)]],
      idBodegaSucursal: [''],
      estado: true,
      idEmpresa: ['', [Validators.required]]
    })
  }

  ngOnInit(): void {
    // this.getSucursales()
    this.getEmpresas()
  }

  getEmpresas() {
    this.empresaService
      .getAll()
      .pipe(first())
      .subscribe((empresas) => {
        this.empresas = empresas
      })
  }

  onSelectionChange(event: MatSelectChange): void {
    this.selectedEmpresaId = event.value
    this.sucursalService.getByEmpresa(this.selectedEmpresaId).subscribe((res)=>{
      this.sucursales = res
    })
  }

  // getSucursales() {
  //   this.sucursalService.getByEmpresa(1).subscribe((res) => {
  //     this.sucursales = res
  //   })
  // }

  crearBodegaEmpresa(idEmpresa: any, idBodega: any) {
    this.BodegaService.crearBodegaEmpresa(idEmpresa, idBodega)
      .pipe(first())
      .subscribe((empresaBodega) => {})
  }
  onSubmit() {
    switch (this.addressForm.status) {
      //Si el formulario esta correcto
      case 'VALID':
        console.log('value del form', this.addressForm.value.idEmpresa),
          this.BodegaService.create(this.addressForm.value)
            .pipe()
            .subscribe(
              (data) => {
                this.snackBar.open('Bodega ingresada con exito', 'cerrar', {
                  duration: 2000,
                  verticalPosition: 'top'
                })
                this.bodegaNueva = data
                this.crearBodegaEmpresa(
                  this.addressForm.value.idEmpresa,
                  this.bodegaNueva.payload.id
                )
                // window.location.reload()
                this.BodegaService.addResultBodega(null)
              },
              (error) => {
                this.snackBar.open(
                  'No se pudo ingresar la bodega, contacte con informática',
                  'cerrar',
                  {
                    duration: 2000,
                    verticalPosition: 'top'
                  }
                )
              }
            )
        break
      //Si el formulario es erroneo
      case 'INVALID':
        this.addressForm.markAllAsTouched()
        break
      default:
        break
    }
  }

  limpiarFormulario() {
    this.addressForm.reset({
      nombre: '',
      direccion: '',
      idEmpresa: '',
      idBodegaSucursal: ''
    })
  }
}
