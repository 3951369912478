<mat-tab-group >
  <!-- <mat-tab label="Actualizar IMG">
    <form [formGroup]="addressForm" novalidate (ngSubmit)="agregarIMG()">
      <mat-card class="shipping-card">
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>Informacion IMG </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col">
              <input
                mat-stroked-button
                (change)="capturarFile($event)"
                type="file"
              />
              <br />
              <br />
              <button
                mat-stroked-button
                color="primary"
                [disabled]="loading"
                (click)="subirIMG()"
                (click)="clickButton = false"
                class="separador"
              >
                {{ loading ? 'Cargando....' : 'Subir IMG' }}
              </button>
              <hr />
              <h3>Previsualizacion</h3>
              <div class="row">
                <div class="col">
                  <img width="400px" [src]="previsualizacion" alt="img" />
                </div>
              </div>
            </div>
            <hr class="separador" />
          </div>
        </mat-expansion-panel>
        <mat-card-actions>
          <button mat-raised-button color="primary" type="submit">
            <mat-icon>send</mat-icon>
            Guardar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab> -->
  <mat-tab label="IMAGEN 📃 ">
    <form [formGroup]="addressForm" novalidate>
      <mat-card class="shipping-card">
        <mat-card-content>
          <mat-card>
            <h3>Imagen Previsualizacion</h3>
            <hr />
            <div class="row">
              <div class="col" *ngIf="previsualizacion">
                <img width="400px" [src]="previsualizacion" alt="img" />
              </div>
            </div>
          </mat-card>
        </mat-card-content>
        <mat-card-actions>
          <!-- <button
            mat-stroked-button
            color="accent"
            style="margin-left: 1rem; margin-bottom: 3px"
            (click)="closeDialog()"
          >
            <mat-icon>cancel_white_18dp</mat-icon>Cancelar
          </button> -->
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>

  <mat-tab label="EDITAR 📝" >
    <button
      color="accent"
      class="cerrar"
      (click)="closeDialog()"
      title="Cerrar"
    >
      <mat-icon>highlight_off</mat-icon>
    </button>
    <form [formGroup]="addressForm" novalidate class="mat-elevation-z10">
      <mat-card class="shipping-card" >
        <mat-card-header>
          <h1>Ingresos Hostal</h1>
        </mat-card-header>
        <mat-card-content>
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>Información Cliente </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- Formulario de edicion -->
            <!-- Fila 1 -->
            <div class="row">
              <!-- Fecha -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-label>Seleccionar fecha</mat-label>
                  <input
                    matInput
                    formControlName="fecha"
                    [matDatepicker]="picker"
                    (click)="picker.open()"
                    [readonly]="true"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error
                    *ngIf="addressForm.controls['fecha'].hasError('required')"
                  >
                    fecha es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Cliente -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="ingrese nombre cliente"
                    formControlName="cliente"
                  />
                  <mat-icon matSuffix>account_circle</mat-icon>
                  <mat-error
                    *ngIf="addressForm.controls['cliente'].hasError('required')"
                  >
                    cliente es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Sucursal -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Sucursal"
                    formControlName="idSucursal"
                  >
                    <mat-option
                      *ngFor="let s of empresa.Sucursals"
                      [value]="s.id"
                    >
                      {{ s.razonSocial }}
                    </mat-option>
                  </mat-select>
                  <mat-icon matSuffix>home</mat-icon>
                  <!-- <mat-error
                    *ngIf="
                      addressForm.controls['nombreSucursal'].hasError('required')
                    "
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error> -->
                </mat-form-field>
              </div>
            </div>
            <!-- Fila 2 -->
            <div class="row">
              <!-- tipoIngreso -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Tipo Ingreso"
                    formControlName="tipoIngreso"
                  />
                  <mat-icon matSuffix>account_balance_18dp</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['tipoIngreso'].hasError('required')
                    "
                  >
                    cliente es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <!-- Fila 3 -->
            <div class="row">
              <!-- tipo cliente -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Tipo Cliente"
                    formControlName="tipoCliente"
                  >
                    <mat-option
                      *ngFor="let tipo of tiposCliente"
                      [value]="tipo"
                    >
                      {{ tipo }}
                    </mat-option>
                  </mat-select>
                  <mat-icon matSuffix>assignment_ind</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['tipoCliente'].hasError('required')
                    "
                  >
                    Tipo_Cliente es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Referencia -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Referencia"
                    formControlName="referenciaCliente"
                  >
                    <mat-option
                      *ngFor="let referencias of referenciaCliente"
                      [value]="referencias"
                    >
                      {{ referencias }}
                    </mat-option>
                  </mat-select>
                  <mat-icon matSuffix>contact_mail</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['referenciaCliente'].hasError(
                        'required'
                      )
                    "
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- estado pago -->
              <!-- <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Estado Pago"
                    formControlName="estadoPago"
                  >
                    <mat-option
                      *ngFor="let estadoPago of estadoPagos"
                      [value]="estadoPago"
                    >
                      {{ estadoPago }}
                    </mat-option>
                  </mat-select>
                  <mat-icon matSuffix>monetization_on</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['estadoPago'].hasError('required')
                    "
                  >
                    estadoPago es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
              </div> -->
            </div>
            <!-- Fila 4 -->
            <div class="row">
              <!-- tipo pago -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Tipo Pago"
                    formControlName="tipoPago"
                  >
                    <mat-option
                      *ngFor="let tipoPago of tiposPagos"
                      [value]="tipoPago"
                    >
                      {{ tipoPago }}
                    </mat-option>
                  </mat-select>
                  <mat-icon matSuffix>payment</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['tipoPago'].hasError('required')
                    "
                  >
                    este campo es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
               <!-- Tipo banco   -->
              <div class="col">
                <div>
                  <mat-form-field appearance="legacy" class="full-width">
                    <mat-label>Seleccionar Banco</mat-label>
                    <mat-select formControlName="banco">
                      <!-- <mat-option *ngFor="let banco of bancos" [value]="banco.NombreInstitucion"> {{banco.NombreInstitucion}} </mat-option> -->
                    </mat-select>
                    <mat-icon matSuffix>account_balance_18dp</mat-icon>
                  </mat-form-field>
                </div>
                
              </div>
               
              <!-- monto -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Monto"
                    formControlName="monto"
                    type="text"
                  />
                  <span matPrefix>$&nbsp;</span>
                  <mat-error
                    *ngIf="addressForm.controls['monto'].hasError('required')"
                  >
                    Monto es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              
              <!-- Codigo Autorizacion -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="Codigo Autorizacion"
                    formControlName="nAutorizacion"
                  />
                  <mat-icon matSuffix>payment</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['nAutorizacion'].hasError('required')
                    "
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <!-- Fila 5 -->
            <div class="row">
             
              <!-- Tipo Documento -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Tipo Documento"
                    formControlName="tipoDocumento"
                  >
                    <mat-option
                      *ngFor="let tipoDocumento of tipoDocumento"
                      [value]="tipoDocumento"
                    >
                      {{ tipoDocumento }}
                    </mat-option>
                  </mat-select>
                  <mat-icon matSuffix>import_contacts</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['tipoDocumento'].hasError('required')
                    "
                  >
                    Este campo es <strong>requerido.</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- numero documento -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="N° documento"
                    formControlName="nDocumento"
                  />
                  <mat-error
                    *ngIf="
                      addressForm.controls['nDocumento'].hasError('required')
                    "
                  >
                    este campo es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- descripcion -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="ingrese una descripcion del registro"
                    formControlName="descripcion"
                  />
                  <mat-error
                    *ngIf="
                      addressForm.controls['descripcion'].hasError('required')
                    "
                  >
                    descripcion es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </mat-expansion-panel>
          <br class="separador" />
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>Información Imagen</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <div class="col">
                <!-- Capturar img -->
                <div>
                  <input
                    mat-stroked-button
                    (change)="capturarFile($event)"
                    type="file"
                  />
                </div>
                <br />
                <div>
                  <!-- <button
                    mat-stroked-button
                    color="primary"
                    [disabled]="loading"
                    (click)="subirIMG()"
                    (click)="clickButton = false"
                  >
                    {{ loading ? 'Cargando....' : 'Subir IMG' }}
                  </button> -->
                </div>
              </div>
            </div>
            <br />
            <h3>Imagen Previsualizacion</h3>
            <hr />
            <div class="row">
              <!-- Preview img -->
              <div class="col" *ngIf="previsualizacion">
                <img width="400px" [src]="previsualizacion" alt="img" />
              </div>
            </div>
            <hr />
          </mat-expansion-panel>
        </mat-card-content>
        <mat-card-actions>
          <button
            mat-raised-button
            color="primary"
            type="submit"
            (click)="onSubmit()"
          >
            <mat-icon>send</mat-icon>
            Guardar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>
</mat-tab-group>
