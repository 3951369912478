import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Stock } from '@app/_models/stock/stock';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { StockActualService } from '../../stockActual.service'
import { CategoriasService } from '@app/_pages/categorias/categorias.service';
import { BodegaService } from '@app/_pages/inventario/bodega/bodega.service';
import { ProductosService } from '@app/_pages/productos/productos.service';
import { info, timeStamp } from 'console';
import * as XLSX from 'xlsx';
import { element } from 'protractor';
import { Producto } from '@app/_models/productos/producto';
import { delay } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-stockActual-list',
  templateUrl: './stockActual-list.component.html',
  styleUrls: ['./stockActual-list.component.scss']
})
export class StockActualListComponent implements OnInit {

  //@ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();  
  //@ViewChild(MatPaginator) paginator: MatPaginator;

  //@ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>()

  dataSource: MatTableDataSource<any>   = new MatTableDataSource();
  dataSource2: MatTableDataSource<any>  = new MatTableDataSource();
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatPaginator) paginatorCategorias = new QueryList<MatPaginator>();
  @ViewChild(MatSort) sort  = new MatSort();
  @ViewChild(MatSort) sort2 = new MatSort();
  
  pageNumber = 1;
  pageSize   = 10;
  totalRecords = 0;

  pageNumberCat = 1;
  pageSizeCat   = 10;
  totalRecordsCat = 0;

  dataStock: any[] = [];
  displayedColumns: string[] = [
    // 'select',
    // 'ip',
    'categoria',
    'producto',
    'estado',
    'bodega',
    'stock',
    'costo',
    'total',
    'codigo',
    'botones',
  ];

  displayedColumns2: string[] = [
    // 'select',
    'categoria',
    'stock',
    'costopromedio',
    'total',
  ];

  meses = [

    { id: 1, valor: 'Vigente' },
    { id: 2, valor: 'No Vigente' },

  ];

  selection = new SelectionModel<Stock>(true, []);
  totalSeleccion = 0;
  selectedRows!: any[];
  informacion: any;
  detallecategoria = 0;
  stockTotal = 0;
  valorInvantario = 0
  bodegas: any;
  categorias: any;
  productos: any;
  lista_bodegas: any = [];
  lista_categorias: any = [];
  lista_productos: any = [];
  listado_productos: Producto[] = [];



  listadoCategoriasDetalle: any[] = [];

  mostrarDetalleProductos = true;
  mostrarDetalleCategoria = false;
  totalInventary: any;
  totalStock: any;


  mostrarTablaDetalleProductos(): void {
    this.mostrarDetalleProductos = true;

    if (this.mostrarDetalleProductos) {
      this.getStockProductsTable(this.pageNumber, this.pageSize);
      console.log("metodo mostrar tabla detalle productos",this.pageNumber, this.pageSize);  
    }
    this.mostrarDetalleCategoria = false;
  }

  mostrarTablaDetalleCategoria(): void {
    this.mostrarDetalleProductos = false;
    this.mostrarDetalleCategoria = true;
    if (this.mostrarDetalleCategoria) {
      this.getDetailCategory(this.pageNumberCat, this.pageSizeCat);
      console.log("metodo mostrar tabla detalle productos",this.pageNumberCat, this.pageSizeCat); 
    }
  }


  constructor(
    private stockService: StockActualService,
    private fb: FormBuilder,
    private bodegasService: BodegaService,
    private categoriasService: CategoriasService,
    private productosService: ProductosService

  ) { }

  ngOnInit() {

    this.getStockProductsTable(this.pageNumber, this.pageSize);
    this.getDetailCategory(this.pageNumberCat, this.pageSizeCat);



    

    this.getProducts();
    this.getTotalRecords();
    this.getTotalInventary();
    this.getStockInventary();
    this.mostrarDetalleProductos = true;
    //this.getDetailCategory();
    //this.getProductosStockDetails();
    //this.informacionTabla();
    //this.infor();
    this.getBodegas();
    this.getCategorias();
    //this.getAllProductos();
    this.detallecategoria = 0
    //this.filtrar();

  }

  Filtro = this.fb.group({
    categoria: [null],
    producto: [null],
    estado: [null],
    costo: [null],
    tipoajuste: [null],
    bodega: [null],
    botones: [null],

  })

  formFilter = new FormGroup({
    idStock: new FormControl(),
    CodigoBarras: new FormControl(),
    idBodegas: new FormControl(),
    idCategorias: new FormControl(),
    idProductos: new FormControl(),
    Estado: new FormControl(),

  })

  verDisponibilidad(id: number, nombre: string, categoria: string) {

    //localStorage.setItem('idStock', ip);
    this.stockService.openDialogEditEgreso(id, nombre, categoria);
  
  }

  limpiarFiltros() {
    console.log("limpiarFiltros",this.pageNumber);
    console.log("limpiarFiltros",this.pageSize);
    console.log("limpiarFiltros",this.pageNumberCat);
    console.log("limpiarFiltros",this.pageSizeCat);
    this.formFilter.patchValue({ idStock: null })
    this.formFilter.patchValue({ idBodegas: null })
    this.formFilter.patchValue({ CodigoBarras: null })
    this.formFilter.patchValue({ idCategorias: null })
    this.formFilter.patchValue({ idProductos: null })
    this.formFilter.patchValue({ Estado: null })
    //this.dataSource = new MatTableDataSource(this.dataStock);
    //this.dataSource.paginator = this.paginator.toArray()[0];
    this.selection.clear()
    this.totalSeleccion = 0;
    this.getStockProductsTable(this.pageNumber, this.pageSize);
    this.getDetailCategory(this.pageNumberCat, this.pageSizeCat);  
    this.getTotalRecords();
    this.getTotalInventary();
    this.getStockInventary();

    console.log("limpiarFiltros",this.pageNumber);
    console.log("limpiarFiltros",this.pageSize);
    console.log("limpiarFiltros",this.pageNumberCat);
    console.log("limpiarFiltros",this.pageSizeCat);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }


  getBodegas() {
    this.bodegasService.bodegasGetAll().subscribe((result: any) => {
      this.bodegas = result
      for (var i = 0; i < this.bodegas.length; i++) {
        this.lista_bodegas.push(this.bodegas[i]);
      }
    }
    )
  }

  getCategorias() {
    this.categoriasService.categoriaGet().subscribe((result: any) => {
      this.categorias = result;
      for (var i = 0; i < this.categorias.length; i++) {
        this.lista_categorias.push(this.categorias[i]);
      }
    })
  }

  onSelectChangue(){
    setTimeout(() => {
      this.getStockProductsTable(this.pageNumber, this.pageSize);
      this.getDetailCategory(this.pageNumberCat, this.pageSizeCat);

      this.getTotalInventary();
      this.getStockInventary();
    }, 100);
  }
  

  getProducts(){    
    this.productosService.productoGet().subscribe(
      (productos) => {    
        this.lista_productos = productos.map((productoData: any) => {
          const producto = new Producto();
          Object.assign(producto, productoData);
          return producto;
        });    
          this.dataSource = new MatTableDataSource(this.lista_productos)
          this.dataSource.paginator = this.paginator.toArray()[0]
          console.log(this.dataSource.paginator);
          this.dataSource.sort = this.sort;
        console.log("productos listado exitoso", this.lista_productos);
      });
  }   

  onPaginateChangeCategories(event: any) {

    this.pageNumberCat = event.pageIndex + 1;
    this.pageSizeCat = event.pageSize;
  
    this.getDetailCategory(this.pageNumberCat, this.pageSizeCat);

  }

  filtrar2(event: Event) {
    const filtro = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filtro.trim().toLowerCase();
  }

  onPaginateChange(event: any) {

    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
  
    this.getStockProductsTable(this.pageNumber, this.pageSize);

  }

  getStockProductsTable(pageNumber: Number, pageSize: Number) {

    const params = {

      bodega: this.formFilter.value.idBodegas || '',
      categoria: this.formFilter.value.idCategorias || '',
      producto: this.formFilter.value.idProductos || '',
      stock: this.formFilter.value.idStock || '',
      codigoSku: this.formFilter.value.CodigoBarras || '',
      estado: this.formFilter.value.Estado || '',
      pageNumber: pageNumber,
      pageSize: pageSize

    };
    this.productosService.getProducts(params).pipe(
      delay(0)
    ).subscribe(
      (productos) => {
        this.listado_productos = productos.map((productoData: any) => {
          const producto = new Producto();
          Object.assign(producto, productoData);
          // this.dataSource = new MatTableDataSource(this.lista_productos)
          // this.dataSource.paginator = this.paginator.toArray()[0]
          // this.dataSource.sort = this.sort;
          return producto;
        });
        console.log("productos exitosos", this.listado_productos);
      },
      (error) => {
        console.log( { response: false, msg: error.mesagge} );
      }
    );
  }

  getDetailCategory(pageNumberCat: Number,pageSizeCat: Number){
    const params = {

      bodega: this.formFilter.value.idBodegas || '',
      categoria: this.formFilter.value.idCategorias || '',
      producto: this.formFilter.value.idProductos || '',
      stock: this.formFilter.value.idStock || '',
      codigoSku: this.formFilter.value.CodigoBarras || '',
      estado: this.formFilter.value.Estado || '',
      pageNumber: pageNumberCat,
      pageSize: pageSizeCat
    };

    this.productosService.getDetailCategory(params).pipe(
      delay(0)
    ).subscribe(
      (categoriasDetalles) => {
        this.listadoCategoriasDetalle = categoriasDetalles;
        this.dataSource2 = new MatTableDataSource(this.listadoCategoriasDetalle)
        this.dataSource2.paginator = this.paginatorCategorias.toArray()[0]
        this.dataSource.sort = this.sort2;
      },
      (error) => {
        console.log( { response: false, msg: error.mesagge} );
      }  
    )
  }
/*
|||
|
|
|
|
|
|
|
|
|
|
|
|
|
|
|
|
|
|
|
|
*/

  getTotalRecords() {
    const params = {
      bodega: this.formFilter.value.idBodegas || '',
      categoria: this.formFilter.value.idCategorias || '',
      producto: this.formFilter.value.idProductos || '',
      stock: this.formFilter.value.idStock || '',
      codigoSku: this.formFilter.value.CodigoBarras || '',
      estado: this.formFilter.value.Estado || '',
    };

    this.productosService.getTotalProductosStock(params).subscribe(
      (response: any) => {
        this.totalRecords = parseInt(response);
        //this.paginator.length = this.totalRecords; // Asociar el total de registros al paginador
      },
      (error) => {
        console.log('Error al obtener el total de registros:', error);
      }
    );
  }


getTotalInventary(){
  const params = {

    bodega: this.formFilter.value.idBodegas || '',
    categoria: this.formFilter.value.idCategorias || '',
    producto: this.formFilter.value.idProductos || '',
    stock: this.formFilter.value.idStock || '',
    codigoSku: this.formFilter.value.CodigoBarras || '',
    estado: this.formFilter.value.Estado || '',
  };

  this.stockService.getTotalInventary(params).pipe(
    delay(8)
    ).subscribe(
      (response: any) => {
        this.totalInventary = parseFloat(response[0].total_inventario);
      },
      (error) => {
        console.log( { response: false, msg: error.mesagge} );
      }   
    );
}

getStockInventary(){
  const params = {

    bodega: this.formFilter.value.idBodegas || '',
    categoria: this.formFilter.value.idCategorias || '',
    producto: this.formFilter.value.idProductos || '',
    stock: this.formFilter.value.idStock || '',
    codigoSku: this.formFilter.value.CodigoBarras || '',
    estado: this.formFilter.value.Estado || '',
  };

  this.stockService.getStockInventary(params).pipe(
    delay(8)
    ).subscribe(
      (response: any) => {
        this.totalStock = parseFloat(response[0].total_stock);
      },
      (error) => {
        console.log( { response: false, msg: error.mesagge} );
      }
    );
}


masterToggle() {
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  this.isAllSelected() ?
    this.selection.clear() :
    this.dataSource.filteredData.forEach(row => {
      this.selection.select(row);
    });
}
//excel primera tabla de detalle
exportAsXLSX(): void {

  let element = document.getElementById('tabla')
  
  // this.selectedRows = [] ;
  // this.selection.selected.forEach((x) => this.selectedRows.push(x));
  // console.log(this.selectedRows)
  // this.stockService.exportAsExcelFile(this.selectedRows, 'Productos y stock');

  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

  const wb: XLSX.WorkBook = XLSX.utils.book_new();
 
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1', );
  
  XLSX.writeFile(wb, "Detalle Stock ");


}
//segunda tabla de detalle categoria
exportAsXLSX1(): void {

  let element = document.getElementById('tabla1')
  
  // this.selectedRows = [] ;
  // this.selection.selected.forEach((x) => this.selectedRows.push(x));
  // console.log(this.selectedRows)
  // this.stockService.exportAsExcelFile(this.selectedRows, 'Productos y stock');

  const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

  const wb: XLSX.WorkBook = XLSX.utils.book_new();
 
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1', );
  
  XLSX.writeFile(wb, "Detalle Categoria ");


}


filtrar() {

  this.formFilter.valueChanges.subscribe(res => {

    this.stockService.infoTabla().subscribe((result: any) => {
      let informacion = result
      let stocktotal = 0
      let valorinvantario = 0
      informacion.map(function (item: any) {
        let datos = [];

        let total = item.stock * item.unitario

        datos.push([
          item.nombreCategoria,
          item.nombreProducto,
          item.estado,
          item.stock,
          item.unitario,
          item.total = total,


        ]);

        stocktotal += total

        valorinvantario += item.stock
      }

      )
      this.stockTotal = stocktotal
      this.valorInvantario = valorinvantario

      console.log(res)
      console.log(informacion)


      if (res.idStock) {
        informacion = informacion.filter((data: any) => data.stock == res.idStock);
      
      }

      if (res.CodigoBarras) {

        informacion = informacion.filter((data: any) => data.codigoBarra == res.CodigoBarras);

      }
      if (res.idCategorias) {

        informacion = informacion.filter((data: any) => data.idCategoria == res.idCategorias);

      }
      if (res.idProductos) {

        informacion = informacion.filter((data: any) => data.nombreProducto == res.idProductos);

      }
      if (res.Estado) {

        informacion = informacion.filter((data: any) => data.estado == res.Estado);

      }
      if (res.idBodegas) {

        informacion = informacion.filter((data: any) => data.nombreBodega == res.idBodegas);

      }



      this.dataSource = informacion;

    

    })
  })
}


}

// infor(){

//   this.stockService.infoTablaDetalle().subscribe((result: any) => {

//     let informacion = result

   
    // let duplicados = [];
     
    // const tempArray = [informacion[0].nombreCategoria].sort();

     
    // for (let i = 0; i < tempArray.length; i++) {
    //   if (tempArray[i].nombreCategoria === tempArray[i].nombreCategoria) {
    //     duplicados.push(tempArray[i]);
    //   }
    // }

    // console.log(duplicados)
    // return
     
  
    // informacion.map(function (item: any) {
    //   let datos = [];
   

    //   let total = item.stock * item.unitario

    //   datos.push([
       
    //     item.nombreCategoria,
    //     item.nombreProducto,
    //     item.stock,
    //     item.unitario,
    //     item.total = total,
      

    //   ]);

    //   console.log(datos)

     
    // }

    // )
  
    
    // this.dataSource2 = informacion;

    //this.dataSource.paginator = this.paginator.toArray()[0];

    // return



  // })

  
// }
  

  // informacionTabla() {


  //   this.stockService.infoTabla().subscribe((result: any) => {
  //     let informacion = result
  //     let stocktotal = 0
  //     let valorinvantario = 0
     
  //     informacion.map(function (item: any) {
  //       let datos = [];

  //       let total = item.stock * item.unitario

  //       datos.push([
  //         item.ip,
  //         item.nombreCategoria,
  //         item.nombreProducto,
  //         item.nombreBodega,
  //         item.estado,
  //         item.stock,
  //         item.unitario,
  //         item.total = total,



  //       ]);

  //       stocktotal += total

  //       valorinvantario += item.stock
  //     }

  //     )

  //     this.stockTotal = stocktotal

  //     this.valorInvantario = valorinvantario


  //     this.dataSource = informacion
    

   

  //    // this.dataSource.paginator = this.paginator.toArray()[0];

  //     return



  //   })


  // }



  // getAllProductos() {
  //   this.stockService.productoGet().subscribe({
  //     next: (result: any) => {
  //       this.lista_productos.push(...result);
  //       console.log(this.lista_productos);
  //     },
  //     error: (error: any) => {
  //       console.error('Error al obtener los productos:', error);
  //     }
  //   });
  // }


  // getProductosStockDetails() {
  //   this.productosService.productsStockDetails().subscribe(
  //     (data: any[]) => {
  //       this.productos = data;
  //       console.log('Datos de productos:', this.productos);
  //     },
  //     (error) => {
  //       console.error('Error al obtener los detalles del stock de productos:', error);
  //     }
  //   );
  // }
 






