<div class="container">
<div class="box-filtros">

<div class="box-filtros">

<!-- <mat-expansion-panel hideToggle> -->
<!-- <mat-expansion-panel-header> -->
<!-- <mat-panel-title> Filtros </mat-panel-title>
    <mat-panel-description>Para visualizar, Presiona Aqui
      <mat-icon>touch_app_white_18dp</mat-icon>
    </mat-panel-description>
  </mat-expansion-panel-header> -->
<form [formGroup]="Filtro">
  <mat-grid-list cols="3" rowHeight="70px">
    <mat-grid-tile>
      <div class="col">
        <mat-form-field class="full-width" style="width:140%" [formGroup]="formFilter">
          <mat-select placeholder="Bodegas" formControlName="idBodegas" (valueChange)="onSelectChangue()">
            <mat-option *ngFor="let bodegas of lista_bodegas" [value]="bodegas.id">
              {{ bodegas.nombre }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <div class="col">
        <mat-form-field class="full-width" style="width:140%" [formGroup]="formFilter">
          <mat-select placeholder="Categorias" formControlName="idCategorias" (valueChange)="onSelectChangue()">

            <mat-option *ngFor="let categorias of lista_categorias" [value]="categorias.id">
              {{ categorias.nombre }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <div class="col">
        <mat-form-field class="full-width" style="width:140%" [formGroup]="formFilter">
          <mat-select placeholder="Productos" formControlName="idProductos" (valueChange)="onSelectChangue()">
            <mat-option *ngFor="let productos of lista_productos" [value]="productos.id">
              {{ productos.nombre }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <div class="col">
        <mat-form-field class="full-width" style="width:120%" [formGroup]="formFilter">
          <input matInput placeholder="Stock" formControlName="idStock" type="number" (input)="onSelectChangue()" (keyup)="filtrar2($event)"/>
        </mat-form-field>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <div class="col">
        <mat-form-field class="full-width" style="width:120%" [formGroup]="formFilter">
          <input matInput placeholder="Codigo de barras" formControlName="CodigoBarras" type="number" />
        </mat-form-field>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
      <div class="col">
        <mat-form-field class="full-width" style="width:140%" [formGroup]="formFilter">
          <mat-select placeholder="Estado" formControlName="Estado" (valueChange)="onSelectChangue()">
            <mat-option *ngFor="let m of meses" [value]="m.valor">
              {{ m.valor }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
  <div class="row-clear">

    <div *ngIf="detallecategoria == 0">
      <button mat-raised-button color="accent" style="margin-right: 10px;" (click)="exportAsXLSX()">
        Exportar
      </button>
    </div>
    <div *ngIf="detallecategoria == 1">
      <button mat-raised-button color="accent" style="" (click)="exportAsXLSX1()">
        Exportar
      </button>
    </div>

    <button mat-raised-button color="accent" style="margin-right: 10px;" onclick="location.reload()">
      <mat-icon>autorenew</mat-icon>
    </button>

  <button style="margin-right: 20px;margin-bottom: 20px;" mat-raised-button color="accent" (click)="limpiarFiltros()">
    Limpiar filtros
  </button>
</div>
</form>

<!-- </mat-expansion-panel> -->

</div>


<!-- <mat-toolbar color="primary">

  <button style="margin-right: 9rem" mat-raised-button color="accent" (click)="limpiarFiltros()">
    Limpiar filtros
  </button>
  <span style="margin-right: 9rem; margin-left: 9rem"> Stock de productos </span>
  <div>
    <div *ngIf="detallecategoria == 0">
      <button mat-raised-button color="accent" style="margin-left: 8rem" (click)="exportAsXLSX()">
        Exportar
      </button>
    </div>
    <div *ngIf="detallecategoria == 1">
      <button mat-raised-button color="accent" style="margin-left: 8rem" (click)="exportAsXLSX1()">
        Exportar
      </button>
    </div>


  </div>

  <button mat-raised-button color="accent" style="margin-left: 1rem" onclick="location.reload()">
    <mat-icon>autorenew</mat-icon>
  </button>
</mat-toolbar> -->



<div class="box-productos-categorias">

<div class="row-boxes">

  <div class="box-btn">
  <button style="margin: 20px;" mat-raised-button
  [ngClass]="{'active-button': mostrarDetalleProductos}"
  (click)="mostrarTablaDetalleProductos()" color="accent">
  Detalle
</button>
<button mat-raised-button 
[ngClass]="{'active-button': mostrarDetalleCategoria}"
(click)="mostrarTablaDetalleCategoria()" color="accent">
  Detalle Categoria
</button>

</div>

  <div class="box">
    <div class="info">
      <p class="title">
        Stock total disponible
      </p>
      <h5 class="totalInventary">
        {{  totalStock| number}}
      </h5>
    </div>
  </div>
  <div class="box" style="margin-left: 20px;margin-right: 10px;">
    <div class="info">
      <p class="title">
        Valor del inventario
      </p>
      <h5 class="totalStock">
        <span matPrefix>$&nbsp;</span>
        {{ totalInventary | number}}
      </h5>
    </div>
  </div>
</div>





 <div class="box-btn">
    <button style="margin-right: 10px;" mat-raised-button
    [ngClass]="{'active-button': mostrarDetalleProductos}"
    (click)="mostrarTablaDetalleProductos()" color="accent">
    Detalle
  </button>
  <button mat-raised-button 
  [ngClass]="{'active-button': mostrarDetalleCategoria}"
  (click)="mostrarTablaDetalleCategoria()" color="accent">
    Detalle Categoria
  </button>
  
  </div>



  <div *ngIf="detallecategoria == 0">
    <button mat-raised-button color="accent" style="margin-right: 10px;" (click)="exportAsXLSX()">
      Exportar
    </button>
  </div>
  <div *ngIf="detallecategoria == 1">
    <button mat-raised-button color="accent" (click)="exportAsXLSX1()">
      Exportar
    </button>
  </div>
</div>
<!-- <button style="margin-top: 10px; margin-right: 10px;" mat-raised-button color="accent">
  <h4>Total Stock Disponible</h4>

  {{valorInvantario}}
</button>

<button style="margin-top: 10px; " mat-raised-button color="accent">
  <h4>Valor del Inventario</h4>
  {{stockTotal}}
</button> -->

<!-- TABLA PRODUCTOS -->
<div class="" *ngIf="mostrarDetalleProductos">
  <table mat-table [dataSource]="listado_productos" class="" id="tabla">

    <ng-container matColumnDef="categoria">
      <th mat-header-cell *matHeaderCellDef>Categoria</th>
      <td mat-cell *matCellDef="let element">{{ element.nombreCategoria }}</td>
    </ng-container>

    <ng-container matColumnDef="producto">
      <th mat-header-cell *matHeaderCellDef>Producto </th>
      <td mat-cell *matCellDef="let element">{{ element.nombre }}</td>
    </ng-container>

    <!-- <ng-container matColumnDef="estado">
      <th mat-header-cell *matHeaderCellDef>Estado </th>
      <td mat-cell *matCellDef="let element">{{ element.estado }}</td>
    </ng-container> -->

    <ng-container matColumnDef="estado">
      <th mat-header-cell *matHeaderCellDef>Estado</th>
      <td mat-cell *matCellDef="let element" >
        <span [ngClass]="element.estado === 'Vigente' ? 'Vigente' : 'no-vigente'">
          {{ element.estado }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="bodega">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element"></td>
    </ng-container>

    <ng-container matColumnDef="stock">
      <th mat-header-cell *matHeaderCellDef>Stock </th>
      <td mat-cell *matCellDef="let element">{{ element.stock }}</td>
    </ng-container>

    <ng-container matColumnDef="costo">
      <th mat-header-cell *matHeaderCellDef>Costo </th>
      <td mat-cell *matCellDef="let element">
        <span matPrefix>$&nbsp;</span>
        {{ element.unitario | number}}
      </td>
    </ng-container>

    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef>Total </th>
      <td mat-cell *matCellDef="let element">
        <span matPrefix>$&nbsp;</span>
        {{ element.totall | number}}
      </td>
    </ng-container>

    <ng-container matColumnDef="codigo">
      <th mat-header-cell *matHeaderCellDef>Codigo</th>
      <td mat-cell *matCellDef="let element">{{ element.codigoSKU }}</td>
    </ng-container>

    <!-- Botones -->
    <ng-container matColumnDef="botones">
      <th mat-header-cell *matHeaderCellDef> Acciones </th>
      <td mat-cell *matCellDef="let element">
        <button mat-raised-button color="accent" style="margin-left: 1rem"
          (click)="verDisponibilidad(element.id, element.nombre, element.nombreCategoria)">
          <mat-icon>room</mat-icon> Disponibilidad
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="8">No existen coincidencias</td>
    </tr>

  </table>

  <mat-paginator [pageSizeOptions]="[10, 30, 50, 100]"></mat-paginator>
  
</div>


<div class="table-responsive" *ngIf="mostrarDetalleCategoria">

  <table mat-table [dataSource]="listadoCategoriasDetalle" class="" id="tabla1">

    <ng-container matColumnDef="categoria">
      <th mat-header-cell *matHeaderCellDef>Categoria </th>
      <td mat-cell *matCellDef="let element">{{ element.nombreCategoria }}</td>
    </ng-container>

    <ng-container matColumnDef="stock">
      <th mat-header-cell *matHeaderCellDef>Stock </th>
      <td mat-cell *matCellDef="let element">{{ element.stock }}</td>
    </ng-container>

    <ng-container matColumnDef="costopromedio">
      <th mat-header-cell *matHeaderCellDef>Costo Promedio </th>
      <td mat-cell *matCellDef="let element">
        <span matPrefix>$&nbsp;</span>
        {{ element.unitario | number}}</td>
    </ng-container>


    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef>Total </th>
      <td mat-cell *matCellDef="let element">
        <span matPrefix>$&nbsp;</span>
        {{ element.totall | number}}</td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns2"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No existen coincidencias</td>
    </tr>
  </table>


  <mat-paginator #paginatorCategorias [pageSizeOptions]="[10, 30, 50, 100]" showFirstLastButtons (page)="onPaginateChangeCategories($event)"></mat-paginator>



</div>

</div>
<br><br>

</div>

