import { Usuario } from './../_models/shared/usuario';
import { Component } from '@angular/core'
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { Observable } from 'rxjs'
import { map, shareReplay } from 'rxjs/operators'
import { MenusItems } from 'src/app/_models/menu-items'
import { environment } from '../../environments/environment'
import { AuthSharedService } from '@app/_pages/shared/shared-services/auth-shared.service';
import { Roles } from '@app/_pages/shared/enums/roles.enum';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent {
  rolUser: string;
  isRentacar: boolean; // VARIABLE PROVISORIA PARA MOSTRAR ALGO CUANDO ES DE RENTACAR

  usuario: Usuario = JSON.parse(localStorage.getItem('usuario') + '')

  menuItems: MenusItems[]
  subMenuItems: MenusItems[]
  subMenuItemsAgrofirma: MenusItems[]
  subMenuItemsPrestamos: MenusItems[]
  subMenuItemsGastoPersonal: MenusItems[]
  subMenuItemsGastosFijos: MenusItems[]
  subMenuItemsSii: MenusItems[]
  menuItemsAdmin: MenusItems[]
  subMenuItemsAdmin: MenusItems[]
  subMenuItemsConsolidados: MenusItems[]
  menuModulosConfig: MenusItems[]
  submenuModulosConfig: MenusItems[]
  menuModulosEmpresa: MenusItems[]
  menuModuloVentas: MenusItems[]
  submenuModulosVentas: MenusItems[]
  menuModuloCliente: MenusItems[]
  menuModuloCompras: MenusItems[]
  menuModuloInventario: MenusItems[]
  menuModuloRecaudacion: MenusItems[]
  submenuModulosInventario: MenusItems[]
  subMenuEgreso: MenusItems[]
  menuModulosConsolidadoBancario: MenusItems[]
  menuModuloRecaudaciones: MenusItems[]
  menuSolicitudOrdenCompra: MenusItems[]
  menuPagoOrdenCompra: MenusItems[]
  submenuModulosPago: MenusItems[]
  submenuModuloConsolidadoPago: MenusItems[]
  //submenuModuloGastosFijosCalendario: MenusItems[]
  submenuModuloGastosFijosCalendario: MenusItems[]
  subMenuModuloGarridoProyectos:MenusItems[]
  subMenuModuloAutosYa:MenusItems[]

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    )

  constructor(
    private breakpointObserver: BreakpointObserver,
    public auth: AuthSharedService,
  ) {
    // VARIABLES GRUPO DE ROLES
    const groupAdmin = [Roles.Administrador];
    const groupPrimary = [...groupAdmin, Roles.Finanza, Roles.Operaciones, Roles.RecursosHumanos];
    const groupRentacar = [Roles.Rentacar];
    //const groupNotOperaciones = [...groupAdmin, Roles.Finanza, Roles.RecursosHumanos]

    this.rolUser = this.auth.usuario.rol;
    /*
    🤑🤑🤑🤑🤑🤑🤑🤑🤑🤑🤑🤑
    MODULO DE VENTAS
    */
    this.menuModuloVentas = [{ name: 'VENTAS', icon: 'paid_white_24dp', rol: [...groupAdmin, ...groupPrimary, ...groupRentacar] }]
    this.submenuModulosVentas = [
      /* {
        name: 'REGISTRO VENTAS',
        icon: 'app_registration_24dp',
        url: 'registroVentas',
        rol: groupPrimary
      },
      { name: 'ESTADO DTE', icon: 'insights_24dp', url: 'EstadoDTE', rol: groupPrimary },*/
      {
        name: 'COTIZACIONES',
        icon: 'pending_actions_white_24dp',
        url: 'cotizaciones',
        rol: groupPrimary
      }, 
      {
        name: 'GUIA DE DESPACHO',
        icon: 'published_with_changes_24dp',
        url: 'despacho',
        rol: groupPrimary
      },
      { name: 'FACTURAS', icon: 'receipt_white_24dp', url: 'facturas', rol: [...groupAdmin, ...groupPrimary, ...groupRentacar] },
      { name: 'BOLETAS', icon: 'grading_white_24dp', url: 'boletas', rol: [...groupAdmin, ...groupPrimary, ...groupRentacar] },
      {
        name: 'NOTAS DE CRÉDITO',
        icon: 'edit_document_white_24dp',
        url: 'notaCredito',
        rol: groupPrimary
      },
      {
        name: 'NOTAS DE DEBITO',
        icon: 'edit_square_white_24dp',
        url: 'notaDebito',
        rol: groupPrimary
      },
      {
        name: 'CESIONES FACTORING',
        icon: 'pinch_white_24dp',
        url: 'cesiones-factoring',
        rol: groupPrimary
      }
    ]

    /*
  😎😎😎😎😎😎😎😎😎😎😎😎
  MODULO DE COMPRAS
  */
    /* this.menuModuloCompras = [
      { name: 'COMPRAS', icon: 'shopping_cart_white_24dp', url: 'compras', rol: groupPrimary }
    ] */

    /*
    🚚🚚🚚🚚🚚🚚🚚🚚🚚🚚
    MODULO DE INVENTARIO
    */
    this.menuModuloInventario = [
      { name: 'INVENTARIO', icon: 'inventory_white_24dp', url: 'inventario', rol: groupPrimary }
    ]

    this.submenuModulosInventario = [
      {
        name: 'AJUSTE INVENTARIO',
        icon: 'dashboard_white_24dp',
        url: 'ajusteInventario',
        rol: groupPrimary
      },
      { name: 'STOCK', icon: 'dashboard_customize_white_24dp', url: 'stock', rol: groupPrimary },
      { name: 'BODEGA', icon: 'warehouse_white_24dp', url: 'bodega', rol: groupPrimary },
      {
        name: 'TRANFERENCIA BODEGA',
        icon: 'sync_alt_white_24dp',
        url: 'transferencia-bodega',
        rol: groupPrimary
      }
    ]

    /*
    🏛🏛🏛🏛🏛🏛🏛
   MODULO DE RECAUDACION
   */
   /*  this.menuModuloRecaudacion = [
      {
        name: 'RECAUDACION',
        icon: 'swap_horizontal_circle_white_24dp',
        url: 'recaudacion',
        rol: groupPrimary
      }
    ] */

    /*
👩‍🎨👩‍🎨👩‍🎨👩‍🎨👩‍🎨👩‍🎨👩‍🎨👩‍🎨
MODULO DE CLIENTES
*/

    this.menuModuloCliente = [
      {
        name: 'CLIENTES',
        icon: 'supervised_user_circle_white_24dp',
        url: 'clientes',
        rol: groupPrimary
      }
    ]

    //---- COMINEZO DEL CODIGO EL CUAL SE AGREGA EL MODULO Y SUB MODULO DE FINANZA------------
    this.menuModulosConfig = [
      {
        name: 'CONFIGURACION',
        icon: 'settings_black_24dp.svg',
        rol: groupPrimary
      }
    ]

    this.submenuModulosConfig = [
      {
        name: 'PROVEEDORES',
        icon: 'safety_divider_white_24dp',
        url: 'proveedores',
        rol: groupPrimary
      },
      {
        name: 'CESIONARIOS (Factoring)',
        icon: 'business_white_24dp',
        url: 'cesionarios',
        rol: groupPrimary
      },
      {
        name: 'PRODUCTOS',
        icon: 'real_estate_agent_white_24dp',
        url: 'productos',
        rol: groupPrimary
      },
      { name: 'CATEGORIAS', icon: 'category_white_24dp', url: 'categorias', rol: groupPrimary },
      { name: 'FOLIO', icon: 'post_add_white_18dp', url: 'folio', rol: groupPrimary }
    ]

    /*

MODULO DE EMPRESAS 😳😳😳😳😳😳
    */

    this.menuModulosEmpresa = [
      { name: 'EMPRESAS', icon: 'home_work_black_24dp.svg', rol: [...groupAdmin, ...groupPrimary, ...groupRentacar] }
    ]
    this.menuItems = [
      {
        name: 'CONSOLIDADOS',
        icon: 'pie_chart',
        url: 'consolidadostotal',
        rol: groupPrimary
      },
      {
        name: 'EGRESOS GRUPO FIRMA',
        icon: 'api_white_24dp',
        url: 'egresosFirmaGeneral',
        rol: groupPrimary
      },
      { name: 'HOSTAL', icon: 'bed', url: 'hostal', rol: groupPrimary },
      {
        name: 'LUBRICENTRO',
        icon: 'commute',
        url: 'lubricentro',
        rol: [...groupAdmin, ...groupPrimary, ...groupRentacar]
      },
      {
        name: 'RENTACAR',
        icon: 'directions_car',
        url: 'rentacar',
        rol: [...groupAdmin, ...groupPrimary, ...groupRentacar]
      },
      {
        name: 'INMOBILIARIA',
        icon: 'chair',
        url: 'inmobiliaria',
        rol: groupPrimary
      },
      {
        name: 'FIRMA ABOGADOS',
        icon: 'account_balance',
        url: 'firmaAbogado',
        rol: groupPrimary
      },
      {
        name: 'AGROFIRMA',
        icon: 'agriculture',
        url: 'agrofirma',
        rol: groupPrimary
      },
      {
        name: 'EXPORTADORA',
        icon: 'flight_takeoff',
        url: 'exportadora',
        rol: groupPrimary
      },
      {
        name: 'IMPORTADORA',
        icon: 'flight_land',
        url: 'importadora',
        rol: [...groupAdmin, ...groupPrimary, ...groupRentacar]
      },
      {
        name: 'PRESTAMOS',
        icon: 'flip_camera_android',
        url: 'prestamos',
        rol: groupPrimary
      },
      {
        name: 'GASTO PERSONAL',
        icon: ' transfer_within_a_station',
        url: 'gasto-personal',
        rol: groupPrimary
      },
      {
        name: 'GARRIDO PROYECTOS',
        icon: 'description',
        url: 'garrido-proyectos',
        rol: groupAdmin
      },
      {
        name: 'AUTOS YA',
        icon: 'directions_car',
        url :'autos-ya',
        rol:groupAdmin
      }

      //  { name: 'SII', icon: 'description', url: 'sii' }

      /* { name: 'AGROFIRMA PROYECTOS', icon: 'agriculture', url: 'agrofirma' } */
    ]
    this.subMenuItems = [
      { name: 'INGRESOS', icon: 'align_horizontal_right', url: 'ingresos', rol: [...groupAdmin,...groupPrimary,  ...groupRentacar] },
      { name: 'EGRESOS', icon: 'align_horizontal_right', url: 'egresos', rol: groupPrimary},
      // { name: 'CALENDARIO GASTOS FIJOS', icon: 'align_horizontal_right', url: 'gastos-fijos', rol: groupPrimary },
    ]
    this.subMenuItemsConsolidados = []

    this.subMenuItemsAgrofirma = [
      { name: 'INGRESOS', icon: 'align_horizontal_right', url: 'ingresos', rol: [...groupAdmin, ...groupPrimary, ...groupRentacar] },
      { name: 'EGRESOS', icon: 'align_horizontal_right', url: 'egresos', rol: groupPrimary },
      { name: 'PROYECTOS', icon: 'align_horizontal_right', url: 'proyectos', rol: groupPrimary },
      // {
      //   name: 'CONSOLIDADOS',
      //   icon: 'align_horizontal_right',
      //   url: 'consolidados',
      //   rol: groupPrimary
      // }
    ]
    //* MODULO EMPRESA GARRIDO PROYECTOS
    this.subMenuModuloGarridoProyectos = [
      { name: 'INGRESOS', icon: 'align_horizontal_right', url: 'ingresos', rol: groupPrimary },
      { name: 'EGRESOS', icon: 'align_horizontal_right', url: 'egresos', rol: groupPrimary },
      

    ]

    //* MODULO EMPRESA AUTOS YA
    this.subMenuModuloAutosYa = [
      // {
      //   name: 'INGRESOS',
      //   icon: '',
      //   url: 'ingresos',
      //   rol: groupPrimary
      // },
      // {
      //   name: 'EGRESOS',
      //   icon: '',
      //   url: 'egresos',
      //   rol: groupPrimary
      // }
    ]

    this.subMenuItemsPrestamos = [
      { name: 'EMPRESAS', icon: 'align_horizontal_right', url: 'empresas', rol: groupPrimary }
    ]

    this.subMenuItemsGastoPersonal = [
      { name: 'EGRESOS', icon: 'align_horizontal_right', url: 'egresos', rol: groupPrimary },
      // { name: 'CALENDARIO GASTOS FIJOS', icon: 'align_horizontal_right', url: 'gastos-fijos', rol: groupPrimary },
    ]


    this.subMenuItemsSii = [
      {
        name: 'EMISIÓN DTES',
        icon: 'align_horizontal_right',
        url: 'emision-dtes',
        rol: groupPrimary
      },
      {
        name: 'RECAUDACIONES',
        icon: 'align_horizontal_right',
        url: 'recaudacion',
        rol: groupPrimary
      },
      { name: 'PRODUCTO', icon: 'align_horizontal_right', url: 'productos', rol: groupPrimary },
      { name: 'INVENTARIO', icon: 'align_horizontal_right', url: 'inventario', rol: groupPrimary },
      { name: 'CATEGORIAS', icon: 'align_horizontal_right', url: 'categorias', rol: groupPrimary },
      { name: 'FACTURA', icon: 'align_horizontal_right', url: 'factura', rol: [...groupAdmin, ...groupPrimary, ...groupRentacar] },
      { name: 'BOLETA', icon: 'align_horizontal_right', url: 'boleta', rol: [...groupAdmin, ...groupPrimary, ...groupRentacar] }
    ]

    this.menuItemsAdmin = [
      { name: 'ADMINISTRACIÓN', icon: 'manage_accounts', url: 'administracion', rol: groupAdmin }
    ]
    this.subMenuItemsAdmin = [
      { name: 'USUARIOS', icon: 'align_horizontal_right', url: 'usuarios', rol: groupAdmin },

      {
        name: 'EMPRESAS',
        icon: 'align_horizontal_right',
        url: 'empresas',
        rol: groupAdmin
      },

      {
        name: 'SUCURSALES',
        icon: 'align_horizontal_right',
        url: 'sucursales',
        rol: groupAdmin
      },
      {
        name: 'ROLES',
        icon: 'align_horizontal_right',
        url: 'roles',
        rol: groupAdmin
      },
      {
        name: 'VERSION',
        icon: 'align_horizontal_right',
        url: 'version',
        rol: groupAdmin
      }
      /*    { name: 'ACTIVOS', icon: 'align_horizontal_right', url: '' },
         { name: 'PASIVOS', icon: 'align_horizontal_right', url: '' },
         { name: 'CONSOLIDADOS', icon: 'align_horizontal_right', url: '' } */
    ]

    this.menuModulosConsolidadoBancario = [
      {
        name: 'CONSOLIDADO BANCARIO',
        icon: 'account_balance',
        url: 'consolidadoBancario',
        rol: groupPrimary
      }
    ]

    this.menuSolicitudOrdenCompra = [
      {
        name: 'SOLICITUD O/C',
        icon: 'import_contacts',
        url: 'solicitudOrdenCompra',
        rol: groupPrimary
      }
    ]

    this.menuPagoOrdenCompra = [
      {
        name: 'PAGO O/C',
        icon: 'shopping_cart_checkout',
        url: 'consolidadoBancario/pagoOrdenCompra',
        rol: groupPrimary
      }
    ]

    this.submenuModulosPago = [
      {
        name: 'CONSOLIDADO',
        icon: 'dashboard_white_24dp',
        url: 'consolidado-bancario-graf',
        rol: groupPrimary
      }
    ]
    this.submenuModuloConsolidadoPago = [
      {
        name: 'CONSOLIDADO DIARIO DE GASTO',
        icon: 'dashboard_white_24dp',
        url: 'consolidado-diario-pago-gasto',
        rol: groupPrimary
      }
    ]

  }

  verificarPermiso(rol: string, rolesPermitos: string[]) {
    return rolesPermitos?.includes(rol)

  }
  volverVistaAtigua() {
    window.location.href = `${environment.indexUrlAntigua}`
    //window.location.href = `${environment.indexUrl}`;
  }

  cerrarSesion() {
    this.auth.cerrarSesion()
    //console.log(this.auth.usuario.rol);
  }
}
