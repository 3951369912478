import { Component, OnInit } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { SucursalSharedService } from '@app/_pages/shared/shared-services/sucursal-shared.service'
import { Sucursal } from '@app/_models/shared/sucursal'
import { Empresa } from '@app/_models/shared/empresa'
import { EmpresaSharedService } from '@app/_pages/shared/shared-services/empresa-shared.service'
import { first } from 'rxjs/operators'
import { MatTableDataSource } from '@angular/material/table'
import { MatSnackBar } from '@angular/material/snack-bar'
import { AbogadosTabsService } from '../../../abogados-tabs.service'
import { nuevoContrato } from '../../../../../_models/abogados/nuevoContrato'
import { nuevaCuota } from '../../../../../_models/abogados/nuevaCuota'
import { Usuario } from '@app/_models/shared/usuario'
import { HttpClient } from '@angular/common/http'
import { AbogadosService } from '../../../abogados.service'
import { environment } from '@environments/environment'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
//import { CrearContratoResponse } from './abogados-ingresos/abogados-ingresos-acciones/dialog-contratos/response.interface'
//import { DialogMostrarContratosComponent } from './abogados-ingresos/abogados-ingresos-acciones/dialog-mostrar-contratos/dialog-mostrar-contratos.component'
import { MatDialog } from '@angular/material/dialog'

@Component({
  selector: 'app-dialog-contratos',
  templateUrl: './dialog-contratos.component.html',
  styleUrls: ['./dialog-contratos.component.scss']
})
export class DialogContratosComponent implements OnInit {
  idEmpresa = 2
  sucursales!: Sucursal[]
  empresa = new Empresa()
  contrato = new nuevoContrato()
  selectedPdf: File | null = null
  selectedFoto: File | null = null
  public loading: boolean
  pdfUrl: string | null = null

  pdfPreview: SafeResourceUrl | null = null

  cuota = new nuevaCuota()
  listaCuotas: nuevaCuota[] = []
  dataSource: MatTableDataSource<nuevaCuota> = new MatTableDataSource()
  fechaCompromisoCuota: any
  selectedFile: File | null = null
  public archivos: any = []
  dataArrayImg = []
  public previsualizacion: string
  clickButton = true

  saldoPendiente = 0
  usuario: Usuario = JSON.parse(localStorage.getItem('usuario') + '')
  idCliente = localStorage.getItem('idCliente')
  nombreClienteLocal = localStorage.getItem('nombreCliente')

  // Variables que permiten realizar los filtros
  addressFormContrato = this.fb.group({
    fechaContrato: ['', Validators.required],
    idSucursal: ['', Validators.required],
    montoContrato: ['', Validators.required],
    nContrato: ['', Validators.required],
    pdf: [null]
  })

  // Variables que permiten realizar los filtros
  addressFormCuotas = this.fb.group({
    fechaCuota: ['', Validators.required],
    montoCuota: ['', Validators.required]
  })

  displayedColumns: string[] = ['fecha', 'montoCuotaTabla']

  constructor(
    private fb: FormBuilder,
    private sucursalService: SucursalSharedService,
    private empresaService: EmpresaSharedService,
    private abogadosTabService: AbogadosTabsService,
    private snackBar: MatSnackBar,
    private abogadosService: AbogadosService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog
  ) {
    this.sucursales = this.sucursalService.sucursalListValue
  }

  ngOnInit(): void {
    this.obtenerEmpresa(this.idEmpresa)
  }

  //Metodo que ayuda a obtener los valores del formulario
  get f(): any {
    return this.addressFormContrato.controls
  }

  //Metodo que ayuda a obtener los valores del formulario
  get c(): any {
    return this.addressFormCuotas.controls
  }

  //Metodo que los datos del contrato (formulario) esten correctos
  validarContrato() {
    // Recoger los datos del formulario
    this.contrato.fechaContrato = this.f.fechaContrato.value
    this.contrato.idSucursal = this.f.idSucursal.value
    this.contrato.montoContrato = this.f.montoContrato.value
    this.contrato.nContrato = this.f.nContrato.value
    this.fechaCompromisoCuota = this.f.fechaContrato.value
    this.contrato.saldoPendiente = this.f.montoContrato.value
    this.contrato.idCliente = Number(this.idCliente)
    this.contrato.idUsuario = this.usuario.id
    this.contrato.estadoPago = 'pendiente'
    this.contrato.file = this.f.pdf.value

    // Crear un FormData para manejar los archivos
    const formData = new FormData()
    formData.append('fechaContrato', this.contrato.fechaContrato)
    formData.append('idSucursal', this.contrato.idSucursal.toString())
    formData.append('montoContrato', this.contrato.montoContrato.toString())
    formData.append('nContrato', this.contrato.nContrato.toString())
    formData.append('saldoPendiente', this.contrato.saldoPendiente.toString())
    formData.append('idCliente', this.contrato.idCliente.toString())
    formData.append('idUsuario', this.contrato.idUsuario.toString())
    formData.append('estadoPago', this.contrato.estadoPago)
    formData.append('file', this.contrato.file)

    // Añadir el archivo PDF al FormData

    // Para depuración, puedes mostrar los contenidos del FormData
    formData.forEach((value, key) => {
      console.log(`${key}:`, value)
    })

    // Llamar al servicio para crear o actualizar el contrato
    console.log(this.contrato)
    this.abogadosTabService
      .crearSinoExisteContrato(formData)
      .pipe()
      .subscribe(
        (x: any) => {
          this.pdfUrl = x.pdfUrl // Aquí se corrige `response` por `x`
          console.log(this.pdfUrl)
          this.snackBar.open(x.respuesta, 'cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
          this.contrato = x.contrato
          this.saldoPendiente = this.contrato.montoContrato
        },
        (error) => {
          console.error('Error al crear el contrato:', error)
          this.snackBar.open('Error al crear el contrato', 'cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
        }
      )
  }

  //Metodo que permite agregar las cuotas a una tabla
  agregarCuotas(): any {
    this.cuota.montoCuota = this.c.montoCuota.value
    //Si el monto de las cuotas es menor al saldo pendiente
    if (this.cuota.montoCuota <= this.saldoPendiente) {
      this.cuota.estado = 'pendiente'
      this.cuota.fechaPago = this.c.fechaCuota.value
      this.cuota.idContrato = this.contrato.id
      this.cuota.idUsuario = this.usuario.id
      this.listaCuotas.push(this.cuota)

      this.saldoPendiente = this.saldoPendiente - this.cuota.montoCuota
      this.cuota = new nuevaCuota()
      this.dataSource = new MatTableDataSource(this.listaCuotas)
    }
    //Si no se muestra el error
    else {
      alert('monto no coincide con saldo pendiente')
    }
  }

  guardarContrato() {
    this.abogadosTabService
      .guardarCuotas(this.listaCuotas)
      .pipe()
      .subscribe((x: any) => {
        this.abogadosService.closeDialogModal()
        this.listaCuotas = []
        // this.openDialogIngresoAbogadoContrato()

        this.snackBar.open('Contato generado con exito', 'cerrar', {
          duration: 2000,
          verticalPosition: 'top'
        })
      })
  }

  obtenerEmpresa(id: number): any {
    this.empresaService
      .getByIdWithSucursales(id)
      .pipe(first())
      .subscribe((x) => {
        x.Sucursals = Object.values(x.Sucursals)
        this.empresa = x
      })
  }

  // openDialogIngresoAbogadoContrato(): void {
  //   const dialogRef = this.dialog.open(DialogMostrarContratosComponent, {})

  //   dialogRef.afterOpened().subscribe(() => {
  //     const instance = dialogRef.componentInstance
  //     instance.getContratosCliente() // Llama a la función para obtener y refrescar los contratos del cliente
  //   })

  //   dialogRef.afterClosed().subscribe((res) => {
  //     // Manejar el cierre del diálogo si es necesario
  //   })
  // }
  // accionIngresoAbogado(id: any) {
  //   localStorage.setItem('idContratoPago', id)
  //   console.log(id)
  //   this.abogadosService.openDialogIngresoAbogadoContrato()
  // }
  onFileChange(event: any) {
    const file = event.target.files[0]

    // Actualiza el formulario con el archivo seleccionado
    this.addressFormContrato.patchValue({
      pdf: file
    })
    this.addressFormContrato.get('pdf')!.updateValueAndValidity()

    // Usa FileReader para crear una URL segura para la vista previa
    const reader = new FileReader()
    reader.onload = () => {
      // Sanitiza la URL del PDF y asigna la URL segura a pdfPreview
      this.pdfPreview = this.sanitizer.bypassSecurityTrustResourceUrl(
        reader.result as string
      )
    }
    reader.readAsDataURL(file)
  }

  onFotoSelected(event: any): void {
    const file = event.target.files[0]
    if (file && file.type.startsWith('image/')) {
      this.selectedFoto = file
    } else {
      this.snackBar.open('Por favor, selecciona una imagen válida.', 'Cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
    }
  }
  subirIMG() {
    try {
      this.loading = true
      const formularioDeDatos = new FormData()
      this.archivos.forEach((archivo) => {
        formularioDeDatos.append('File', archivo)
      })
      this.http
        .post(
          `${environment.apiUrl}/contratoCienteAbogado/Upload/`,
          formularioDeDatos
        )
        .subscribe((res) => {
          this.loading = false
          this.snackBar.open(`Imagen subida  con exito`, 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
        })
    } catch (e) {
      this.loading = false
      this.snackBar.open(`Imagen subida sin exito`, 'Cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
      console.log('ERROR AL SUBIR IMG', e)
    }
  }

//   capturarFile(event) {
//     this.archivos = []
//     this.dataArrayImg = []
//     const archivoCapturado = event.target.files[0]
//     const extension = archivoCapturado.name.split('.').pop()
//     const nuevoNombre = 'ingresoHostal' + Date.now() + '.' + extension
//     this.dataArrayImg.push(nuevoNombre)
//     const archivoNuevo = new File([archivoCapturado], nuevoNombre, {
//       type: archivoCapturado.type
//     })
//     this.extraerBase64(archivoNuevo).then((imagen: any) => {
//       this.previsualizacion = imagen.base
//     })
//     this.archivos.push(archivoNuevo)
//     this.clickButton = false
//   }
//   extraerBase64 = async ($event: any) =>
//     new Promise((resolve, reject) => {
//       try {
//         const unsafeImg = window.URL.createObjectURL($event)
//         const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg)
//         console.log(image)
//         const reader = new FileReader()
//         reader.readAsDataURL($event)
//         reader.onload = () => {
//           resolve({
//             base: reader.result
//           })
//         }
//         reader.onerror = (error) => {
//           resolve({
//             base: null
//           })
//         }
//       } catch (e) {
//         return null
//       }
//     })
}
