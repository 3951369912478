import { HttpClient } from '@angular/common/http'
import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { MatSnackBar } from '@angular/material/snack-bar'
import { DomSanitizer } from '@angular/platform-browser'
import { IngresosHostal } from '@app/_models/hostal/ingresoHostal'
import { Sucursal } from '@app/_models/shared/sucursal'
import { Usuario } from '@app/_models/shared/usuario'
import { SucursalSharedService } from '@app/_pages/shared/shared-services/sucursal-shared.service'
import { environment } from '@environments/environment'
import { first } from 'rxjs/operators'
import { HostalService } from '../../hostal.service'
import { Banco } from '@app/_models/agroFirma/Banco'

@Component({
  selector: 'app-hostal-ingresos-formulario',
  templateUrl: './hostal-ingresos-formulario.component.html',
  styleUrls: ['./hostal-ingresos-formulario.component.scss']
})
export class HostalIngresosFormularioComponent implements OnInit {
  @Output()
  formularioListo = new EventEmitter<string>()

  usuario: Usuario = JSON.parse(localStorage.getItem('usuario') + '')
  modelo: IngresosHostal = new IngresosHostal()
  tiposIngresos: any[] = []
  sucursales: Sucursal[]
  hasUnitNumber = false
  defTiposIngreso: string[]
  tiposCliente: string[]
  referencias: string[]
  tiposPagos: string[]
  estadoPagos: string[]
  dataArrayImg = []
  numberConvert = ''
  result2 = ''
  tipoDocumento: any
  nAutorizacion: any
  form!: FormGroup
  clickButton = true
  tipoIngreso: any
  Servicios: any[] = []
  defTiposServicios: string[]
  bancos:any[] = []

  addressForm = this.fb.group({
    fecha: [null, Validators.required],
    monto: [null, Validators.required],
    nAutorizacion: [null, Validators.required],
    tipoPago: [null, Validators.required],
    cliente: [null, Validators.required],
    descripcion: [null, Validators.required],
    tipoIngreso: ['', [Validators.required]],

    idSucursal: [null, Validators.required],

    tipoCliente: [null, Validators.required],
    referencia: [null, Validators.required],

    estadoPago: ['PENDIENTE', Validators.required],
    nDocumento: [null, Validators.required],

    tipoDocumento: [null, Validators.required],
    archivos: [null],
    banco: [null],
    numeroCuenta: [null, [Validators.pattern('^[0-9]{4}$')]]
  })

  public archivos: any = []
  public previsualizacion: string
  public loading: boolean

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private hostalService: HostalService,
    private sucursalService: SucursalSharedService,
    private sanitizer: DomSanitizer,
    private http: HttpClient
  ) {
    this.sucursalService.sucursalList.subscribe((sucursales) => {
      this.sucursales = sucursales
    })
    //this.sucursales = this.sucursalService.sucursalListValue
    this.defTiposServicios = [
      'Alojamiento',
      'Desayuno',
      'Almuerzo',
      'Cena',
      ' Consumo Bebidas',
      'Consumo Varios'
    ]
    this.tiposCliente = ['Particular', 'Empresa']
    this.referencias = [
      'Llamada',
      'Booking',
      'Correo',
      'PaginaWeb',
      'Facebook',
      'Directo a Hostal'
    ]
    this.tiposPagos = [
      'Efectivo',
      'Tarjeta de debito',
      'Tarjeta de credito',
      'Transferencia',
      'Billetera Copec', 
      'Caja Chica',
      'Billetera Mercado Libre',
      'Pago Retail',
      'Cheque'
    ]
    this.estadoPagos = ['PENDIENTE', 'PAGADO']
    this.tipoDocumento = ['BOLETA', 'FACTURA']
   
  }
  addBanc: Boolean
  selectedTipoPago: string
  numeroCuentaLength: string

  addBancNumber(tipoPagoValue: string): void {
    if (tipoPagoValue !== 'Efectivo') {
      this.addBanc = true
    } else {
      this.addBanc = false
    }
  }

  ngOnInit(): void {
    this.addressForm.get('tipoPago').valueChanges.subscribe((tipoPagoValue) => {
      this.selectedTipoPago = tipoPagoValue
      this.addBancNumber(tipoPagoValue)
      if (tipoPagoValue === 'Efectivo') {
        this.addressForm.patchValue({
          banco: null,
          numeroCuenta: null
        })
      }
    })

  this.hostalService.getBancos().subscribe((res) => {
    this.bancos = res
  })
  }

  extraerBase64 = async ($event: any) =>
    new Promise((resolve, reject) => {
      try {
        const unsafeImg = window.URL.createObjectURL($event)
        const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg)
        const reader = new FileReader()
        reader.readAsDataURL($event)
        reader.onload = () => {
          resolve({
            base: reader.result
          })
        }
        reader.onerror = (error) => {
          resolve({
            base: null
          })
        }
      } catch (e) {
        return null
      }
    })

  capturarFile(event) {
    this.archivos = []
    this.dataArrayImg = []
    const archivoCapturado = event.target.files[0]
    const extension = archivoCapturado.name.split('.').pop()
    const nuevoNombre = 'ingresoHostal' + Date.now() + '.' + extension
    this.dataArrayImg.push(nuevoNombre)
    const archivoNuevo = new File([archivoCapturado], nuevoNombre, {
      type: archivoCapturado.type
    })
    this.extraerBase64(archivoNuevo).then((imagen: any) => {
      this.previsualizacion = imagen.base
    })
    this.archivos.push(archivoNuevo)
    this.clickButton = false
  }

  subirIMG() {
    try {
      this.loading = true
      const formularioDeDatos = new FormData()
      this.archivos.forEach((archivo) => {
        formularioDeDatos.append('File', archivo)
      })
      this.http
        .post(
          `${environment.apiUrl}/ingresoHostal/Upload/IngresoHostal`,
          formularioDeDatos
        )
        .subscribe((res) => {
          this.loading = false
          this.snackBar.open(`Imagen subida  con exito`, 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
        })
    } catch (e) {
      this.loading = false
      this.snackBar.open(`Imagen subida sin exito`, 'Cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
      console.log('ERROR AL SUBIR IMG', e)
    }
  }

  onSubmit() {
    console.log(this.addressForm.value)
    this.form = this.fb.group(this.addressForm.value)
    if (this.dataArrayImg.length > 0) {
      this.form.value.archivos = this.dataArrayImg

      switch (this.addressForm.status) {
        case 'VALID':
         const montoString = this.form.value.monto;
         const montoFormat = montoString.replace(/\./g, '');
          this.modelo.fecha = this.form.value.fecha
          this.modelo.tipoIngreso = this.form.value.tipoIngreso
          // this.modelo.monto = parseInt(this.numberConvert)
          this.modelo.monto = parseInt(montoFormat, 10)
          this.modelo.tipoPago = this.form.value.tipoPago
          this.modelo.cliente = this.form.value.cliente
          this.modelo.tipoCliente = this.form.value.tipoCliente
          this.modelo.descripcionIngreso = this.form.value.descripcion
          this.modelo.nDocumento = this.form.value.nDocumento
          // this.modelo.estadoPago = this.form.value.estadoPago
          this.modelo.nAutorizacion = this.form.value.nAutorizacion
          this.modelo.referenciaCliente = this.form.value.referencia
          this.modelo.archivos = this.form.value.archivos
          this.modelo.idSucursal = this.form.value.idSucursal
          this.modelo.idUsuario = this.usuario.id
          this.modelo.tipoDocumento = this.form.value.tipoDocumento
          this.modelo.banco = this.form.value.banco
          this.modelo.numeroCuenta = this.form.value.numeroCuenta
          let cadena = ''
          for (const tipos of this.Servicios) {
            cadena = cadena + ' ' + tipos
          }


          this.subirIMG()
          this.modelo.Servicios = cadena
          console.log(this.modelo);
          this.hostalService
            .IngresoHostal(this.modelo)
            .pipe(first())
            .subscribe(
              (data: any) => {
                this.snackBar.open('Ingreso agregado con exito!', 'cerrar', {
                  duration: 2000,
                  verticalPosition: 'top'
                })
                //window.location.reload()
              },
              (error: any) => {
                this.snackBar.open(
                  'Ocurrio un problema, contacte a soporte',
                  'cerrar',
                  {
                    duration: 2000,
                    verticalPosition: 'top'
                  }
                )
              }
            )
          break
        case 'INVALID':
          this.snackBar.open('EL formulario debe ser Completado !!', 'cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
          break

        default:
          break
      }
    }
  }

  //===> Checkbox
  chequearTipoIngreso(e: MatCheckboxChange) {
    if (e.checked) {
      this.tiposIngresos.push(e.source.name)
    }
    if (!e.checked) {
      this.tiposIngresos = this.tiposIngresos.filter(
        (data) => data !== e.source.name
      )
    }
  }
  transform(val: any) {
    if (val) {
       val = this.format_number(val, '')
    }
    return val
  }

  format_number(number: any, prefix: any) {
    let thousand_separator = '.',
      decimal_separator = ',',
      regex = new RegExp('[^' + decimal_separator + '\\d]', 'g'),
      number_string = number.replace(regex, '').toString(),
      split = number_string.split(decimal_separator),
      rest = split[0].length % 3,
      result = split[0].substr(0, rest),
      thousands = split[0].substr(rest).match(/\d{3}/g)
    if (thousands) {
      let separator = rest ? thousand_separator : ''
      result += separator + thousands.join(thousand_separator)
    }
    result =
      split[1] != undefined ? result + decimal_separator + split[1] : result

    this.result2 = result
    this.numberConvert = number_string
    return prefix == undefined ? result : result ? prefix + result : ''

  }

  restrictNumeric(e: any) {
    let input
    if (e.metaKey || e.ctrlKey) {
      return true
    }
    if (e.which === 32) {
      return false
    }
    if (e.which === 0) {
      return true
    }
    if (e.which < 33) {
      return true
    }
    input = String.fromCharCode(e.which)
    return !!/[\d\s]/.test(input)
  }
  chequearTiposServicios(e: MatCheckboxChange) {
    if (e.checked) {
      this.Servicios.push(e.source.name)
    }
    if (!e.checked) {
      this.Servicios = this.Servicios.filter((data) => data !== e.source.name)
    }
  }
}
