import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { modeloClientes } from '@app/_models/clientes/clientes'
import { environment } from '@environments/environment'
import { ClientesTableEditComponent } from './clientes-home/clientes-table/clientes-table-edit/clientes-table-edit.component'

@Injectable({
  providedIn: 'root'
})
export class ClientesService {
  constructor(private http: HttpClient, private dialog: MatDialog) {}

  //Registar cliente
  registrarClientes(modelo: modeloClientes): any {
    return this.http.post(`${environment.apiUrl}/clientes`, modelo)
  }

  //Llamar tabla de usuario
  getAllClientes(): any {
    return this.http.get<[]>(`${environment.apiUrl}/clientes`)
  }

  //Eliminar Clientes
  deleteClientes(id: any) {
    return this.http.delete(`${environment.apiUrl}/clientes/${id}`)
  }

  //Editar cliente
  updateCliente(id: any, params: any[]) {
    return this.http.put(`${environment.apiUrl}/clientes/${id}`, params)
  }
  // Metodo que permite abrir un Dialog (Modal)
  openDialogEditCliente(): void {
    const dialogRef = this.dialog.open(ClientesTableEditComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }

  getById(id: string): any {
    return this.http.get<any>(`${environment.apiUrl}/clientes/${id}`)
  }

  closeDialogModal() {
    this.dialog.closeAll()
    localStorage.removeItem('idCliente')
  }
  getSugerencias(termino: any) {
    return this.http.get<[]>(
      `${environment.apiUrl}/clientes?q=${termino}&_limit=6`
    )
  }
}
