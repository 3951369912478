import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper'
import { ClientesService } from '../../clientes.service'
import { modeloClientes } from '@app/_models/clientes/clientes'
import { MatSnackBar } from '@angular/material/snack-bar'
import { first } from 'rxjs/operators'
import { validateRUT, clearRUT } from 'validar-rut'

@Component({
  selector: 'app-clientes-form',
  templateUrl: './clientes-form.component.html',
  styleUrls: ['./clientes-form.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    }
  ]
})
export class ClientesFormComponent implements OnInit {
  @Output() formularioListo = new EventEmitter<string>()

  codigo= '';
  subido = false
  estado = false
  state = false;
  form!: FormGroup
  result1 = ' '
  termino: any;

  //Modelo de proveedores
  modelo: modeloClientes = new modeloClientes()

  constructor(
    private _formBuilder: FormBuilder,
    private clienteService: ClientesService,
    private snackBar: MatSnackBar
  ) {}
  tamano_rut: string
  ngOnInit(): void {
  }

  infBasica = this._formBuilder.group({
    nombre: ['', [Validators.required]],
    alias: ['', [Validators.required]],
    rut: ['', [Validators.required]],
    giro: ['', [Validators.required]],
    codigo: this.result1,
    estado: [true, [Validators.required]]
  })

  infUbicacion = this._formBuilder.group({
    direccion: ['', [Validators.required]],
    ciudad: ['', [Validators.required]],
    comuna: ['', [Validators.required]]
    // docReferencia: ['', Validators.required],
    // direccionDespacho: ['', Validators.required],
  })

  infPago = this._formBuilder.group({
    nombreResponsable: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    telefono: [
      '',
      [Validators.required, Validators.maxLength(9), Validators.minLength(9)]
    ]
  })

  infAdicional = this._formBuilder.group({
    contactoComercial: ['', [Validators.required]],
    emailComercial: ['', [Validators.required, Validators.email]],
    telefonoComercial: [
      '',
      [Validators.required, Validators.maxLength(9), Validators.minLength(9)]
    ],
    celular: [
      '',
      [Validators.required, Validators.maxLength(9), Validators.minLength(9)]
    ],
    descuento: ['', [Validators.required]],
    credito: ['', [Validators.required]],
    lineaCredito: ['', [Validators.required]],
    formaPago: ['', [Validators.required]],
    referencia: ['', [Validators.required, Validators.maxLength(200)]]
  })

  formatoRut() {
    if (this.tamano_rut.length >= 10 && this.tamano_rut.length < 11) {
      const rut = this.infBasica.value.rut
      const limpiarRut = clearRUT(rut)
      const validacionRut = validateRUT(limpiarRut)
      if (validacionRut == true) {
        console.log('El rut es valido para ser registrado')
        this.validarRut()
      } else {
        this.snackBar.open('Rut es invalido', 'X', {
          duration: 2000,
          verticalPosition: 'top'
        })
      }
    } else {
    }
  }

  validarRut() {
    this.clienteService.getAllClientes().subscribe(
      (data: any) => {
        for (let i = 0; i < data.length; i++) {
          if (data[i].rut === this.infBasica.value.rut) {
            this.snackBar.open('Rut ya existe', 'X', {
              verticalPosition: 'top'
            })
            break
          } else {
            // this.snackBar.open('Rut no existe', 'X', {
            //   duration: 2000,
            //   verticalPosition: 'top'
            // })
          }
        }
      },
      (err) => {
        console.log(err)
      }
    )
  }

  generateRandomString = (num) => {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    const charactersLength = characters.length
    for (let i = 0; i < num; i++) {
      this.result1 += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return  this.result1

  }



  onSubmit() {
    this.form = this._formBuilder.group(
      Object.assign(
        this.infAdicional.value,
        this.infPago.value,
        this.infUbicacion.value,
        this.infBasica.value
      )
    )
    switch (this.form.status) {
      case 'VALID':
        this.subido = true

        this.modelo.nombre = this.form.value.nombre
        this.modelo.alias = this.form.value.alias
        this.modelo.rut = this.form.value.rut
        this.modelo.giro = this.form.value.giro
        this.modelo.codigo = this.form.value.codigo
        this.modelo.direccion = this.form.value.direccion
        this.modelo.ciudad = this.form.value.ciudad
        this.modelo.comuna = this.form.value.comuna
        this.modelo.nombreResponsable = this.form.value.nombreResponsable
        this.modelo.email = this.form.value.email
        this.modelo.telefono = this.form.value.telefono
        this.modelo.contactoComercial = this.form.value.contactoComercial
        this.modelo.emailComercial = this.form.value.emailComercial
        this.modelo.telefonoComercial = this.form.value.telefonoComercial
        this.modelo.celular = this.form.value.celular
        this.modelo.descuento = this.form.value.descuento
        this.modelo.credito = this.form.value.credito
        this.modelo.lineaCredito = this.form.value.lineaCredito
        this.modelo.formaPago = this.form.value.formaPago
        this.modelo.referencia = this.form.value.referencia
        this.modelo.estado = this.form.value.estado

        //Subir al servicio
        this.clienteService
          .registrarClientes(this.modelo)
          .pipe(first())
          .subscribe(
            (data: any) => {
              this.snackBar.open('Cliente  registrado con exito', 'cerrar', {
                duration: 2000,
                verticalPosition: 'top'
              })
              window.location.reload()
            },
            (error: any) => {
              this.snackBar.open(
                'Tenemos Problemas para realizar el registro, porfavor contactar al equipo de desarrollo',
                'cerrar',
                {
                  duration: 2000,
                  verticalPosition: 'top'
                }
              )
              this.snackBar.open(error, 'cerrar', {
                duration: 3000,
                verticalPosition: 'top'
              })
            }
          )
        break
      case 'INVALID':
        this.snackBar.open('Debe completar el Formulario', 'cerrar', {
          duration: 2000,
          verticalPosition: 'top'
        })
        break

      default:
        break
    }
  }
}
