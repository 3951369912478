import { getLocaleDateFormat } from '@angular/common'
import { SolictudFormComponent } from './../../solictud-form/solictud-form.component'
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper'
import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { DomSanitizer } from '@angular/platform-browser'
import { modeloSolicitudBancaria } from '@app/_models/consolidadoBancario/solicitudBancaria'
import { Sucursal } from '@app/_models/shared/sucursal'
import { Usuario } from '@app/_models/shared/usuario'
import { AdministracionService } from '@app/_pages/administracion/administracion.service'
import { ConsolidadoBancarioService } from '@app/_pages/consolidado-bancario/consolidado-bancario.service'
import { SucursalSharedService } from '@app/_pages/shared/shared-services/sucursal-shared.service'
import { first } from 'rxjs/operators'
import { CuentasBancariasService } from '@app/_pages/shared/shared-services/cuentas-bancarias.service'
import { HttpClient } from '@angular/common/http'
import { environment } from '@environments/environment'
import { image } from 'html2canvas/dist/types/css/types/image'

@Component({
  selector: 'app-solicitud-table-acciones',
  templateUrl: './solicitud-table-acciones.component.html',
  styleUrls: ['./solicitud-table-acciones.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    }
  ]
})
export class SolicitudTableAccionesComponent implements OnInit {
  @Output() formularioListo = new EventEmitter<string>()
  usuario: Usuario = JSON.parse(localStorage.getItem('usuario') + '')
  nombreUsuario = this.usuario.nombre
  apellidoUsuario = this.usuario.apellido
  nombreCompleto = this.usuario.nombre + ' ' + this.usuario.apellido
  panelOpenState = false
  blockSelect = true
  State = false
  clickButton = true
  descomponerArray: any
  UpdateCuentaBancaria = []

  dataArrayImg = []
  public archivos: any = []
  public previsualizacion: string
  public loading: boolean



  dataArrayFactura = []
  public fotoFactura: any = []
  estadoFactura: any
  public previsualizacion2: string
  public loading2: boolean




  //data
  idSolicitudPagoBancario: any
  NumeroCuenta: any
  nombreResponsable: any
  rut: any
  jefeAutoriza: any
  fechaSolicitud: any
  estado: any
  montoPago: any
  motivoPago: any
  NumeroCuentaAsume: any
  link: any
  razonRechazo: any
  correo: any
  est: any
  bancoCliente: any
  nombreTransferencia: any
  inversion: any
  horaSolicitud: Date
  horaEstado: Date
  RutEmpresa: any

  tipoCuenta: any
  public mostrarDiv: boolean = false

  sucursales: Sucursal[]
  idSucursal: any
  sucursal: any
  asumeFactura: any

  idUsuario: any
  nombreAutoriza: any

  linkValue: any

  //Modelo de Solicitud bancaria
  modelo: modeloSolicitudBancaria = new modeloSolicitudBancaria()
  factura: any

  imagen:any

  user: any = {};
  rol: string = '';

  constructor(
    private administracionService: AdministracionService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private consolidadoBancarioService: ConsolidadoBancarioService,
    private sanitizer: DomSanitizer,
    private sucursalService: SucursalSharedService,
    private http: HttpClient,

    
  ) {}

  ngOnInit(): void {

    this.user = localStorage.getItem('usuario');
    const parsedUser = JSON.parse(this.user)
    console.log(parsedUser);
    this.rol = parsedUser.rol;
    console.log("ASÑDLASKDÑLASKÑDSKASLÑ",this.rol);
    
    this.idSolicitudPagoBancario = localStorage.getItem(
      'idSolicitudPagoBancario'
    )

    this.getSolicitudPagoBancario()
  }

  ////----------------------------Cambiar estado ------
  //Configuracion de formulario
  addressForm = this.fb.group({
    NumeroCuenta: null,
    nombreResponsable: null,
    rut: null,
    jefeAutoriza: null,
    fechaSolicitud: null,
    estado: ['', [Validators.required]],
    razonRechazo: null,
    montoPago: null,
    motivoPago: null,
    correo: null,
    inversion: null,
    nombreTransferencia: null,
    bancoCliente: null,
    NumeroCuentaAsume: null,
    nombreSucursal: null,
    //empresaAsumeGasto: null,
    asumeFactura: null,
    horaSolicitud: null,
    horaEstado: null,
    RutEmpresa: null,
    tipoCuenta: null,
    banco: null,
    razonApruebo: null,
    nombreCompleto: null,
    nombreAutoriza: null,
    link: null,
    fotoFactura:null,
    estadoFactura: null,
    archivos:null,
    Usuario: null,

  })
  get f() {
    return this.addressForm.controls
  }
  // factura
  // imagen

  //0215457696 banco itau monto disponible
  getSolicitudPagoBancario() {
    console.log("local storage", this.idSolicitudPagoBancario)
    this.consolidadoBancarioService
      .getByIdPagoBancario(this.idSolicitudPagoBancario)
      .pipe(first())
      .subscribe((x: any) => {
        console.log(x[0])
        if (x[0].id == this.idSolicitudPagoBancario) {
          this.est = x[0].link
          this.f.NumeroCuenta.setValue(x[0].NumeroCuenta)
          console.log(this.f.nombreResponsable.setValue(x[0].nombreResponsable))
          this.f.nombreResponsable.setValue(x[0].nombreResponsable)
          this.f.rut.setValue(x[0].rut)
          this.f.jefeAutoriza.setValue(x[0].jefeAutoriza)
          this.f.fechaSolicitud.setValue(x[0].fechaSolicitud.slice(0, 10))
          this.f.estado.setValue(x[0].estado)
          this.f.montoPago.setValue(x[0].montoPago)
          this.f.link.setValue(x[0].link)
          this.linkValue = x[0].link
          
          if(this.linkValue !== null && this.linkValue !== undefined && this.link !== ''){
            if(!this.linkValue?.startsWith('http')){
              //* se quitan las comillas dobles al link 
              if (this.linkValue.startsWith('"') && this.linkValue.endsWith('"')) {
                this.linkValue = this.linkValue.slice(1,-1)
              }else{
              this.linkValue = 'https://' + this.linkValue.trim()
              }
            }
          }


          this.f.razonRechazo.setValue(x[0].razonRechazo)
          this.f.correo.setValue(x[0].correo)
          this.f.motivoPago.setValue(x[0].motivoPago)
          this.f.NumeroCuentaAsume.setValue(x[0].NumeroCuentaAsume)
          this.f.inversion.setValue(x[0].inversion)
          this.f.nombreTransferencia.setValue(x[0].nombreTransferencia)
          this.f.bancoCliente.setValue(x[0].bancoCliente)
          //this.f.empresaAsumeGasto.setValue(x[0].empresaAsumeGasto)
          this.f.asumeFactura.setValue(x[0].asumeFactura)
          this.f.banco.setValue(x[0].banco)
          this.f.tipoCuenta.setValue(x[0].tipoCuenta)

          this.f.nombreAutoriza.setValue(this.usuario.nombreUsuario)
          this.f.fotoFactura.setValue(x[0].fotoFactura)

          this.f.estadoFactura.setValue(x[0].estadoFactura)


          this.imagen = this.f.archivos.setValue(x[0].archivos)

          this.factura = this.f.fotoFactura.setValue(x[0].fotoFactura)
          this.f.Usuario.setValue(x[0].Usuario)





          const fechaHora = new Date(x[0].createdAt)
          const hora = fechaHora.getHours()
          const minutos = fechaHora.getMinutes()
          const horaFormateada = `${hora.toString().padStart(2, '0')}:${minutos
            .toString()
            .padStart(2, '0')}`
          this.f.horaSolicitud.setValue(horaFormateada)
        }
        if (this.f.asumeFactura.value === /* 'TERESA 1 BANCO ITAU 76.791.832-1' */
        'EMPRESA TERESA DEL CARMEN GARRIDO ROJAS E HIJOS BANCOITAU 76791832-1'  
        ) {
          this.f.RutEmpresa.setValue('76.791.832-1')
        } else if (
          this.f.asumeFactura.value ===
          /* 'FIRMA ABOGADOS CHILE BANCO ITAU 76.438.914-K' */
          'Empresa Firma Abogados'
        ) {
          this.f.RutEmpresa.setValue('76.438.914-K')
        } else if (
          this.f.asumeFactura.value === 
          'Empresa Soc. Garrido Vargas'
        ){
          this.f.RutEmpresa.setValue('76971509-6')
        }
         else if (
          this.f.asumeFactura.value === 
          'SOC. GARRIDO BANCO ITAU 76.971.509-6'
        ) 
        {
          this.f.RutEmpresa.setValue('76.971.509-6')
        } else if(
          this.f.asumeFactura.value === 
          'Empresa Miguel Vargas Espinoza e hijos'
        ){
          this.f.RutEmpresa.setValue('76849914-k')
        }
        
        else if (
          this.f.asumeFactura.value ===
          'MIGUEL VARGAS E HIJOS BANCO ITAU 76.849.793-1'
        ) {
          this.f.RutEmpresa.setValue('76.849.793-1')
        } else if (
          this.f.asumeFactura.value === /* 'TERESA 2 BANCO ITAU 77.265.668-8' */
          'Empresa Teresa II'
        ) {
          this.f.RutEmpresa.setValue('77.265.668-8')
        } else if (
          this.f.asumeFactura.value === /* 'TERESA GARRIDO ROJAS BANCO ESTADO' */
          'Señora  Teresa'
        ) {
          this.f.RutEmpresa.setValue('11070934-K')
        } else if (this.f.asumeFactura.value === /* 'DIEGO VARGAS GARRIDO BANCO ITAU' */
        'Diego Vargas'
        ) {
          this.f.RutEmpresa.setValue('18891594-9')
        } else if (
          this.f.asumeFactura.value === /* 'MIGUEL VARGAS GARRIDO BANCO SANTANDER' */
          'Miguel Vargas'
        ) {
          this.f.RutEmpresa.setValue('17886328-2')
        } else if (
          this.f.asumeFactura.value === /* 'MIGUEL VARGAS ESPINOZA BANCO ESTADO' */
          'Miguel A Vargas Espinoza'
        ) {
          this.f.RutEmpresa.setValue('8781641-9')
        } else if (
          this.f.asumeFactura.value === 'BRAULIO VARGAS GARRIDO BANCO SANTANDER'
        ) {
          this.f.RutEmpresa.setValue('18228581-1')
        } else if (this.f.asumeFactura.value === 'JAVIER VARGAS GARRIDO BANCO ITAU') {
          this.f.RutEmpresa.setValue('19474232-0')
        } else if (
          this.f.asumeFactura.value === /* 'SOC. VARGAS 2 BANCO ITAU 77.258.157-2' */
          'SOC. VARGAS II'
        ) {
          this.f.RutEmpresa.setValue('77.258.157-2')
        } else if (
          //this.f.asumeFactura.value === 'SOC. VARGAS 1 BANCO ITAU 76.457.046-4'
          this.f.asumeFactura.value === 'SOC. VARGAS I'
        ) {
          this.f.RutEmpresa.setValue('76.457.046-4')
        } else if (this.f.asumeFactura.value === 'Empresa Solanch Macarena Tejos Carrasco') {
          this.f.RutEmpresa.setValue('19.105.142-4')
        }
        this.previsualizacion = x[0].archivos

        this.previsualizacion2 = x[0].fotoFactura


        if (
          this.f.estado.value === 'Aceptada' ||
          this.f.estado.value === 'Autorizada' ||
          this.f.estado.value === 'Saldada' ||
          this.f.estado.value === 'Pagado' ||
          this.f.estado.value === 'Rechazada'
        ) {
          const fechaEstado = new Date(x[0].updatedAt)
          const horas = fechaEstado.getHours()
          const minuto = fechaEstado.getMinutes()
          const fechaFormateada = `${horas.toString().padStart(2, '0')}:${minuto
            .toString()
            .padStart(2, '0')}`
          this.f.horaEstado.setValue(fechaFormateada)
          this.mostrarDiv = true

          this.f.razonApruebo.setValue(x[0].razonApruebo)
        }
        this.NumeroCuentaAsume = x[0].NumeroCuentaAsume

        this.idSucursal = x[0].idSucursal

        this.sucursalService
          .getAll()
          .pipe(first())
          .subscribe((data: any) =>
            data.forEach((element) => {
              if (element.id === this.idSucursal) {
                this.sucursal = element.razonSocial
                this.f.nombreSucursal.setValue(this.sucursal)
              }
            })
          )
      })
  }

  capturarFile(event) {
    this.archivos = []
    this.dataArrayImg = []
    const archivoCapturado = event.target.files[0]
    const extension = archivoCapturado.name.split('.').pop()
    const nuevoNombre = 'pagoOC' + Date.now() + '.' + extension
    this.dataArrayImg.push(nuevoNombre)
    const archivoNuevo = new File([archivoCapturado], nuevoNombre, {
      type: archivoCapturado.type
    })
    this.extraerBase64(archivoNuevo).then((imagen: any) => {
      this.previsualizacion = imagen.base
    })
    this.archivos.push(archivoNuevo)
    this.clickButton = false
  }


  //Capturar factura
  capturarFactura(event) {
    this.fotoFactura = []
    this.dataArrayFactura = []
    console.log(this.dataArrayFactura)
    const archivoCapturado = event.target.files[0]
    const extension = archivoCapturado.name.split('.').pop()
    const nuevoNombre = 'factura' + Date.now() + '.' + extension
    this.dataArrayFactura.push(nuevoNombre)
    const archivoNuevo = new File([archivoCapturado], nuevoNombre, {
      type: archivoCapturado.type
    })
     this.extraerBase64Factura(archivoNuevo).then((imagen: any) => {
      this.previsualizacion2 = imagen.base
    }) 
    this.fotoFactura.push(archivoNuevo)
    this.clickButton = false
  }

  extraerBase64Factura = async ($event: any) =>
  new Promise((resolve, reject) => {
    try {
      const unsafeImg = window.URL.createObjectURL($event)
      const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg)
      const reader = new FileReader()
      reader.readAsDataURL($event)
      reader.onload = () => {
        resolve({
          base: reader.result
        })
      }
      reader.onerror = (error) => {
        resolve({
          base: null
        })
      }
    } catch (e) {
      return null
    }
  })

  subirIMG() {
    if (this.addressForm.value.estado == 'Rechazada') {
      this.snackBar.open('Debe Actulizar Estado para Avanzar', 'Cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
    } else {
      try {
        this.loading = true
        const formularioDeDatos = new FormData()
        this.archivos.forEach((archivo) => {
          formularioDeDatos.append('File', archivo)
        })
        this.http
        .post(
          //`http://localhost:3000/api/solicitudBancaria/uploadFactura`,
          `${environment.apiUrl}/solicitudBancaria/uploadFactura`,
          formularioDeDatos
        )
          .subscribe((res) => {
            this.loading = false
            this.snackBar.open(`Imagen cargada con exito`, 'Cerrar', {
              duration: 2000,
              verticalPosition: 'top'
            })
          })
          window.location.reload()
      } catch (e) {
        this.loading = false
        this.snackBar.open(`Error al cargar imagen`, 'Cerrar', {
          duration: 2000,
          verticalPosition: 'top'
        })
        console.log('ERROR AL SUBIR IMG', e)
      }
    }
  }

subirFactura() {
    try {
      this.loading2 = true
      const formularioDeDatos = new FormData()
      console.log('fotofactura',this.fotoFactura)
      this.fotoFactura.forEach((fotoFactura) => {
        formularioDeDatos.append('File', fotoFactura)
      })
      this.http
      .post(
        //`http://localhost:3000/api/solicitudBancaria/uploadFactura`,
        `${environment.apiUrl}/solicitudBancaria/uploadFactura`,
        formularioDeDatos
      )
        .subscribe((res) => {
          this.loading2 = false
          this.snackBar.open(`Imagen cargada con exito`, 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
        })
    } catch (e) {
      this.loading2 = false
      this.snackBar.open(`Error al cargar imagen`, 'Cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
      console.log('ERROR AL SUBIR IMG', e)
    }
  }



  //Extraer la img a base 64 para poder visualizar
  extraerBase64 = async ($event: any) =>
    new Promise((resolve, reject) => {
      try {
        const unsafeImg = window.URL.createObjectURL($event)
        const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg)
        const reader = new FileReader()
        reader.readAsDataURL($event)
        reader.onload = () => {
          resolve({
            base: reader.result
          })
        }
        reader.onerror = (error) => {
          resolve({
            base: null
          })
        }
      } catch (e) {
        return null
      }
    })

  //Desshabilutar mat-expansion-panel
  moveUp() {
    this.clickButton = true
  }
  //Cerrar Modal
  closeDialog() {
    this.administracionService.closeDialogModal()
    this.consolidadoBancarioService.closeDialogModal()
    window.location.reload()
  }
  CuentaBancaria() {
    if (
      this.f.estado.value === 'Autorizada' ||
      this.f.estado.value === 'Rechazada' ||
      this.f.estado.value === 'Devuelto'
    ) {
      switch (this.addressForm.status) {
        case 'VALID':
          this.consolidadoBancarioService
            .updateCuentaBancaria(
              this.UpdateCuentaBancaria[0].id,
              this.UpdateCuentaBancaria[0]
            )
            .pipe(first())
            .subscribe(
              (data) => {
                this.snackBar.open('Cuenta editada con éxito.', 'Cerrar', {
                  duration: 2000,
                  verticalPosition: 'top'
                })
                this.addressForm.reset()
                this.consolidadoBancarioService.closeDialogModal()
                // window.location.reload()
              },
              (error) => {
                this.snackBar.open(
                  'No se pudo editar la cuenta, favor contactar a informática.',
                  'Cerrar',
                  {
                    duration: 2000,
                    verticalPosition: 'top'
                  }
                )
              }
            )

          break
        case 'INVALID':
          this.snackBar.open('El formulario debe ser completado.', 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
          break

        default:
          break
      }
    } else {
      this.snackBar.open('Falta cambiar estado.', 'Cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
    }
  }
  onSubmit() {

    this.consolidadoBancarioService
    .getByIdPagoBancario(this.idSolicitudPagoBancario)
    .pipe(first())
    .subscribe((x: any) => {
      this.addressForm.value.fotoFactura=this.fotoFactura
      console.log('fotofacturaabaj',this.addressForm.value.fotoFactura)
      this.factura = x[0].estadoFactura
      console.log(this.factura)
      this.imagen = x[0].fotoFactura
      console.log('image',x[0].fotoFactura)
    })
    if (
      this.addressForm.value.estado === 'Aceptada' ||
      this.addressForm.value.estado === 'Autorizada' ||
      this.addressForm.value.estado === 'Saldado' ||
      this.addressForm.value.estado === 'Pagada' ||
      this.addressForm.value.estado === 'Rechazada'
    ) {

      if (this.dataArrayFactura.length > 0) {
        this.addressForm.value.fotoFactura = this.dataArrayFactura
        console.log('dataarray',this.dataArrayFactura)
        this.addressForm.value.estadoFactura = 'Por Confirmar'
      } else if (this.fotoFactura) {
        // Si no hay factura nueva pero hay una factura actual, mantenerla
        this.addressForm.value.fotoFactura = this.factura
      }

      if (this.dataArrayImg.length > 0) {
        this.addressForm.value.archivos = this.dataArrayImg
      } else if (this.fotoFactura) {
        // Si no hay factura nueva pero hay una imagen actual, mantenerla
        this.addressForm.value.archivos = this.imagen
      }
      this.addressForm.value.nombreCompleto = this.nombreCompleto



      switch (this.addressForm.status) {
        case 'VALID':
          console.log('adrresform',this.addressForm)

          this.consolidadoBancarioService
            .updatePagoBancario(
              this.idSolicitudPagoBancario,
              this.addressForm.value
              
            )
            .pipe(first())
            .subscribe(
              (data) => {
                this.snackBar.open('Operacion realizada con exito', 'Cerrar', {
                  duration: 2000,
                  verticalPosition: 'top'
                })
                this.addressForm.reset()
                this.consolidadoBancarioService.closeDialogModal()
                window.location.reload()
              },
              (error) => {
                this.snackBar.open(
                  'No se pudo editar la cuenta, favor contactar a informática.',
                  'Cerrar',
                  {
                    duration: 2000,
                    verticalPosition: 'top'
                  }
                )
              }
            )
          break
        case 'INVALID':
          this.snackBar.open('El formulario debe ser completado.', 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
          break

        default:
          break
      }
    } else {
      this.snackBar.open('Falta cambiar estado.', 'Cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
    }
  }
  verificarURL() {
    // Verificar si la URL no comienza con "http://" o "https://"
    if (!this.linkValue.startsWith('http://') && !this.linkValue.startsWith('https://')) {
      // Si no comienza con ninguno de los dos, agregar "https://"
      this.linkValue = 'https://' + this.linkValue;
    }
  }
}
