import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Producto } from "@app/_models/productos/producto";
import { environment } from "@environments/environment";
import { DialogProductosFotoComponent } from "./productos-list/dialog-productos-foto/dialog-productos-foto.component";
/* Imports Excel */
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { DialogProductosEditarComponent } from './productos-list/dialog-productos-editar/dialog-productos-editar.component';
import { DialogProductosFotoSubirComponent } from "./productos-list/dialog-productos-foto/dialog-productos-foto-subir/dialog-productos-foto-subir.component";
import { DialogVariantesComponent } from "./productos-list/dialog-variantes/dialog-variantes.component";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const EXCEL_EXTENSION = '.xlsx'
/* Fin Import Excel */


@Injectable({
  providedIn: 'root'
})

export class ProductosService {

  constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private http: HttpClient) {

  }


  getConsultaP(): any {
    return this.http.get(`${environment.apiUrl}/productos/data/`)
  }
  //Metodo crear Productos
  // create(producto: Producto) {
  //   return this.http.post(`${environment.apiUrl}/productos/crear/`, producto);
    
  // }

  create(producto: Producto) {
    return this.http.post(`${environment.apiUrl}/productos/crear/`, producto).pipe(
      catchError((error) => {
        console.error('Error al crear el producto:', error);
        return throwError('Ocurrió un error al crear el producto.');
      })
    );
  }


  updateProducto(id: any, params: any) {
    return this.http.put(`${environment.apiUrl}/productos/update/${id}`, params);
  }

  delete(id: number) {
    return this.http.delete(`${environment.apiUrl}/productos/borrar/${id}`);
  }

  //Metodo que trae todas las productos de la bd
  productoGet(): any {
    return this.http.get<[]>(`${environment.apiUrl}/productos/`);
  }

  productoGetId(id: number) {
    console.log(id);
    return this.http.get<Producto>(`${environment.apiUrl}/productos/${id}`);
  }

  productoGetIdconArray(id: number): Observable<Producto[]> {
    return this.http.get<Producto[]>(`${environment.apiUrl}/productos/${id}`);
  }

  // getProducts() {
  //   return this.http.get<[]>(`${environment.apiUrl}/productos/productsAll`);
  // }

  getTotalProductosStock(params: any){
    const url = `${environment.apiUrl}/productos/total`;
    const queryParams = new HttpParams({fromObject: params});
    return this.http.get<[]>(url, {params: queryParams});
  }

  productsStockDetails(id: number){
    return this.http.get<[]>(`${environment.apiUrl}/productos/productsStockDetails/${id}`);
  }

  getProducts(params: any){
    const url = `${environment.apiUrl}/productos/productsAll`;
    const queryParams = new HttpParams({ fromObject: params });
    return this.http.get<any>(url, { params: queryParams});
  }


  // Metodo que permite abrir un Dialog (Modal)
  openDialogFotoProducto(): void {
    const dialogRef = this.dialog.open(DialogProductosFotoComponent, {})
    dialogRef.afterClosed().subscribe((res) => { })
  }

  openDialogVariante(): void {
    const dialogRef = this.dialog.open(DialogVariantesComponent, {})
    dialogRef.afterClosed().subscribe((res) => { })
  }

  openDialogFotoSubir(): void {
    const dialogRef = this.dialog.open(DialogProductosFotoSubirComponent, {})
    dialogRef.afterClosed().subscribe((res) => { })
  }

  // Metodo que permite abrir un Dialog (Modal)
  openDialogEditProductos(): void {
    const dialogRef = this.dialog.open(DialogProductosEditarComponent, {})
    dialogRef.afterClosed().subscribe((res) => { })
  }

  closeDialogModal() {
    this.dialog.closeAll()
  }

  /* Metodo Excel */
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json)
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data']
    }
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    })
    this.saveAsExcelFile(excelBuffer, excelFileName)
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE })
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    )
  }

  getDetailCategory(params: any) {
    const url = `${environment.apiUrl}/productos/detailCategory`;
    const queryParams = new HttpParams({ fromObject: params });

    return this.http.get<any>(url, { params: queryParams });
  }







}
