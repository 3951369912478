/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { EgresoHostal } from '@app/_models/hostal/egresoHostal'
import { IngresosHostal } from '@app/_models/hostal/ingresoHostal'
import { environment } from '@environments/environment'
import { BehaviorSubject, Observable } from 'rxjs'
import { ConsolidadosHostal } from '@app/_models/hostal/consolidadosHostal'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { MatDialog } from '@angular/material/dialog'
import { HostalEgresosCuotasComponent } from './hostal-egresos/hostal-egresos-list/hostal-egresos-cuotas/hostal-egresos-cuotas.component'
import { HostalEgresosCuotaDialogComponent } from './hostal-egresos/hostal-egresos-list/hostal-egresos-cuotas/hostal-egresos-cuota-dialog/hostal-egresos-cuota-dialog.component'
import { MatSnackBar } from '@angular/material/snack-bar'
import { EgresoHostalCuota } from '@app/_models/hostal/egresoHostalCuota'
import { DialogEditEgresosComponent } from './hostal-egresos/hostal-egresos-list/dialog-edit-egresos/dialog-edit-egresos.component'
import { HostalIngresosAccionesComponent } from './hostal-ingresos/hostal-ingresos-tabla/hostal-ingresos-acciones/hostal-ingresos-acciones.component'
import { HostalEgresosAccionesComponent } from './hostal-egresos/hostal-egresos-tabla/hostal-egresos-acciones/hostal-egresos-acciones.component'
import { GastosFijos} from '@app/_models/gastosFijos/gastosFijos'
import { HostalGastosFijosEditComponent} from './hostal-gastos-fijos/hostal-gastos-fijos-edit/hostal-gastos-fijos-edit.component'
import { UsuarioSharedService } from '../shared/shared-services/usuario-shared.service';
import { SucursalSharedService } from '../shared/shared-services/sucursal-shared.service';
import { TryCatchStmt } from '@angular/compiler'
import { Sucursal } from '@app/_models/shared/sucursal'
import { webPay } from '@app/_models/webpay/webpay'


const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const EXCEL_EXTENSION = '.xlsx'

/* Fin Import Excel */
@Injectable({
  providedIn: 'root'
})
export class HostalService {
  // private value

  public tiposIngresosList!: Observable<string[]>
  public tiposEgresosList!: Observable<string[]>
  sucursales: Sucursal[] = []
  private empresa = 'Hostal'

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private usuarioService: UsuarioSharedService,
    private sucursalService: SucursalSharedService
  ) {
    this.sucursalService.sucursalList.subscribe((sucursales) => {
      this.sucursales = sucursales
    })
  }

  /* ingresos */
  ingresoRegistrar(ingresos: IngresosHostal) {
    return this.http.post(
      `${environment.apiUrl}/ingreso${this.empresa}/conRespaldo`,
      ingresos
    )
  }
  ingresoGetAll(): any {
    return this.http.get<[]>(`${environment.apiUrl}/ingresoHostal`)
  }
  ingresoGetFiles(fileName: string) {
    const extencion = fileName.split('.')
    const extend = extencion[1]
    return this.http
      .get(
        `${environment.apiUrl}/ingreso${this.empresa}/download/${fileName}`,
        {
          responseType: 'blob'
        }
      )
      .subscribe((res) => {
        window.open(window.URL.createObjectURL(res))
      })
  }
  buscarImagen(url: string) {
    const extencion = url.split('.')
    const extend = extencion[1]
    return this.http.get(
      `${environment.apiUrl}/ingreso${this.empresa}/download/${url}`,
      {
        responseType: 'blob'
      }
    )
  }

  ingresoGetAllWithUsuario() {
    return this.http.get<[]>(
      `${environment.apiUrl}/ingreso${this.empresa}/conUsuario`
    )
  }
  ingresoGetById(id: string) {
    return this.http.get(`${environment.apiUrl}/ingreso${this.empresa}/${id}`)
  }
  /* /ingresos */

  /* egresos */
  egresoRegistrar(egreso: EgresoHostal): any {
    return this.http.post(
      `${environment.apiUrl}/egreso${this.empresa}/conRespaldo`,
      egreso
    )
  }
  egresoGetAll(): any {
    return this.http.get<[]>(`${environment.apiUrl}/egresoHostal`)
  }

  buscarCuotas(): any {
    return this.http.get<EgresoHostalCuota>(
      `${environment.apiUrl}/egresoHostalCuota/`
    )
  }

  egresoGetFiles(fileName: string): any {
    return this.http
      .get(`${environment.apiUrl}/egreso${this.empresa}/download/${fileName}`, {
        responseType: 'blob'
      })
      .subscribe((res) => {
        window.open(window.URL.createObjectURL(res))
      })
  }
  egresosBuscarImagen(url: string) {
    const extencion = url.split('.')
    const extend = extencion[1]
    return this.http.get(
      `${environment.apiUrl}/egreso${this.empresa}/download/${url}`,
      {
        responseType: 'blob'
      }
    )
  }
  getById(id: string): any {
    return this.http.get<any>(
      `${environment.apiUrl}/egreso${this.empresa}/${id}`
    )
  }
  closeDialogModal() {
    this.dialog.closeAll()
    localStorage.removeItem('idEgresoPago')
  }

  /* /egresos */

  /** metodo actualizar datos editados de una hostal */
  updateHostalIngreso(id: any, params: any[]) {
    return this.http.put(
      `${environment.apiUrl}/egreso${this.empresa}/${id}`,
      params
    )
  }

  /* CONSOLIDADOS */
  buscarConsolidado(consolidado: ConsolidadosHostal): any {
    return this.http.post(
      `${environment.apiUrl}/ingreso${this.empresa}/ingresosEgresos`,
      consolidado
    )
  }

  /* Egresos Por cuotas */
  getCuotas(id: any) {
    return this.http.get<HostalEgresosCuotasComponent>(
      `${environment.apiUrl}/egresoHostalCuota/${id}`
    )
  }

  buscarImagenCuota(url: string) {
    const extencion = url.split('.')
    const extend = extencion[1]
    return this.http.get(
      `${environment.apiUrl}/egresoHostalCuota/download/${url}`,
      {
        responseType: 'blob'
      }
    )
  }

  agregarRespaldos(arrayRespaldos: any): any {
    return this.http.post(
      `${environment.apiUrl}/egresoHostalCuota/agregarRespaldos/`,
      arrayRespaldos
    )
  }
  buscarImagenC(id: any): any {
    return this.http.get<HostalEgresosCuotasComponent>(
      `${environment.apiUrl}/respaldoEgresoHostalCuota/${id}`
    )
  }

  // Metodo que permite abrir un Dialog (Modal)
  openDialogCuota(): void {
    const dialogRef = this.dialog.open(HostalEgresosCuotaDialogComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }

  // Metodo que permite abrir un Dialog (Modal)
  openDialogEditEgreso(): void {
    const dialogRef = this.dialog.open(DialogEditEgresosComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }

  updateMonto(id: any, body: any[]) {
    return this.http.put(`${environment.apiUrl}/egresoHostalCuota/${id}`, body)
  }

  // Metodo que permite abrir un Dialog (Modal)
  openDialogRegistrarPago(idEgreso: any): void {
    //Si el cliente selecciono un contrato se habre el modal
    if (idEgreso != null) {
      const dialogRef = this.dialog.open(HostalEgresosCuotasComponent, {})
      dialogRef.afterClosed().subscribe((res) => {
        console.log(res)
      })
    } else {
      //Si no, se muestra un error
      this.snackBar.open(
        'Por favor seleccione un egreso con cuotas sin pagar',
        'cerrar',
        {
          duration: 2000,
          verticalPosition: 'top'
        }
      )
    }
  }

  // METODO PARA EXPORTAR EXCEL
  public async exportAsExcelFile(
    json: any[],
    excelFileName: string,
    selectedColumns: string[]
  ): Promise<void> {
    const columnMappings = {
      id: 'ID',
      tipoEgreso: 'Tipo de Egreso',
      fecha: 'Fecha',
      monto: 'Monto',
      responsable: 'Responsable',
      descripcionIngreso: 'Descripción',
      tipoPago: 'Tipo de Pago',
      idUsuario: 'Usuario',
      idSucursal: 'Sucursal',
      referenciaCliente: 'Referencia',
      tipoIngreso:'Servicios'
      // Agrega más columnas según sea necesario
    }

    const newData = await Promise.all(
      json.map(async (item) => {
        const newItem: any = {}
        for (const col of selectedColumns) {
          if (col === 'idSucursal') {
            if (item[col] !== null) {
              try {
                const sucursal = await this.sucursalService
                  .getById(item[col])
                  .toPromise()
                newItem[columnMappings[col]] = sucursal.razonSocial
              } catch (error) {
                console.error(
                  'Error al obtener el nombre de la sucursal:',
                  error
                )
                newItem[columnMappings[col]] = ''
              }
            } else {
              newItem[columnMappings[col]] = ''
            }
          } else if (col === 'idUsuario') {
            if (item[col] !== null) {
              try {
                const usuario = await this.usuarioService
                  .getById(item[col])
                  .toPromise()
                newItem[columnMappings[col]] = usuario.nombre + ' ' + usuario.apellido
              } catch (error) {
                console.error('Error al obtener el nombre del usuario:', error)
                newItem[columnMappings[col]] = ''
              }
            } else {
              newItem[columnMappings[col]] = ''
            }
          } else if (col === 'fecha') {
            if (item[col] !== null) {
              try {
                const fecha = new Date(item[col])
                const formattedFecha = `${fecha.getDate()}/${
                  fecha.getMonth() + 1
                }/${fecha.getFullYear()}`
                newItem[columnMappings[col]] = formattedFecha
              } catch (error) {
                console.error('Error al obtener la fecha:', error)
                newItem[columnMappings[col]] = ''
              }
            } else {
              newItem[columnMappings[col]] = ''
            }
          } else {
            newItem[columnMappings[col]] = item[col]
          }
        }

        return newItem
      })
    )

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(newData)

    // Establecer el estilo del encabezado (primera fila)
    const headerRange = XLSX.utils.decode_range(worksheet['!ref'])
    for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
      const headerCell = XLSX.utils.encode_cell({ r: headerRange.s.r, c: C })
      console.log('Setting style for cell:', headerCell)
      // Limpiar cualquier estilo existente
      worksheet[headerCell].s = {}
      // Aplicar nuevo estilo
      worksheet[headerCell].s = {
        fill: { fgColor: { rgb: 'FFFF00' } },
        font: { bold: true }
      }
    }

    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data']
    }
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    })
    this.saveAsExcelFile(excelBuffer, excelFileName)
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE })
    FileSaver.saveAs(
      data,
      fileName + 'export' + new Date().getTime() + EXCEL_EXTENSION
    )
  }

  /*################### INGRESOS ######################
    Modificaciones realizadas por Mendex  💪💪💪💪💪
            Definicion de Metodos
  ######################################################*/

  //==> Crear Ingresos
  IngresoHostal(modelo: IngresosHostal): any {
    return this.http.post(`${environment.apiUrl}/ingresoHostal`, modelo)
  }

  openDialogEditIngresoInmobiliaria(): void {
    const dialogRef = this.dialog.open(HostalIngresosAccionesComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }

  getByIdIngreso(id: any) {
    return this.http.get<any>(
      `${environment.apiUrl}/ingresoHostal/oneGetIngresoHostal/${id}`
    )
  }
  getByIdIngresoSinBase64(id: any) {
    return this.http.get<any>(
      `${environment.apiUrl}/ingresoHostal/getByIdSinBase64/${id}`
    )
  }

  updateIngresoHostal(id: any, params: any[]) {
    //console.log(params)
    return this.http.put(
      `${environment.apiUrl}/ingresoHostal/updateIngresoHostal/${id}`,
      params
    )
  }
  deleteIngreso(id: number) {
    return this.http.delete(`${environment.apiUrl}/ingresoHostal/${id}`)
  }

  /*################### EGRESOS ########################
    Modificaciones realizadas por Mendex  💪💪💪💪💪
            Definicion de Metodos
  ######################################################*/

  //==> Crear Egresos
  EgresoHostal(modelo: EgresoHostal): any {
    console.log(modelo)
    return this.http.post(`${environment.apiUrl}/egresoHostal`, modelo)
  }

  openDialogEditEgresoHostal(): void {
    const dialogRef = this.dialog.open(HostalEgresosAccionesComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }

  getByIdEgreso(id: any) {
    return this.http.get<any>(
      `${environment.apiUrl}/egresoHostal/oneGetEgresoHostal/${id}`
    )
  }

  getByIdEgresoSinBase64(id: any) {
    return this.http.get<any>(
      `${environment.apiUrl}/egresoHostal/getByIdSinBase64/${id}`
    )
  }

  updateEgresoHostal(id: any, params: any[]) {
    console.log(params, id)
    return this.http.put(
      `${environment.apiUrl}/egresoHostal/updateEgresoHostal/${id}`,
      params
    )
  }
  createGastoFijos(gastos: GastosFijos): any {
    console.log('ruta', gastos)
    return this.http.post(`${environment.apiUrl}/gastosFijos`, gastos)
  }

  getGastoFijos(): Observable<GastosFijos[]> {
    return this.http.get<GastosFijos[]>(`${environment.apiUrl}/gastosFijos`)
  }

  openDialogGastoFijo(detallesGasto: any) {
    const dialogRef = this.dialog.open(HostalGastosFijosEditComponent, {
      width: '1000px',
      height: '500px',
      data: detallesGasto
    })

    dialogRef.afterClosed().subscribe((result) => {})
  }
  getByIdGastoFijo(id: any) {
    return this.http.get<any>(
      `${environment.apiUrl}/gastosFijos/getIDSinBase64/${id}`
    )
  }
  getByIdGastoFijoImagen(id: any) {
    return this.http.get<any>(
      `${environment.apiUrl}/gastosFijos/oneGetGastoFijos/${id}`
    )
  }
  updateGastoFijos(id: any, data: any) {
    return this.http.put(
      `${environment.apiUrl}/gastosFijos/updateGastoFijos/${id}`,
      data
    )
  }

  deleteEgreso(id: number) {
    return this.http.delete(`${environment.apiUrl}/egresoHostal/${id}`)
  }

  createCredito(egresoHostal: EgresoHostal): any {
    try {
      return this.http.post(
        `${environment.apiUrl}/egresoHostal/createEgresoHome`,
        egresoHostal
      )
    } catch (error) {
      return error
    }
  }
  test() {
    return this.http.get(`${environment.apiUrl}/egresoHostal/test`)
  }

  pagar(idCuota: number, monto: number, empresa: string) {
    let body = {
      idCuota,
      monto,
      empresa
    }
    console.log(body)
    return this.http.post(`${environment.apiUrl}/egresoHostal/pagar`, body)
  }

  confirmar(token: string) {
    console.log('HostalService token: ', token)
    let body = { token }
    return this.http.post(`${environment.apiUrl}/egresoHostal/confirmar`, body)
  }
  actualizarPagado(id: number): any {
    let body = {
      id
    }
    return this.http.post(
      `${environment.apiUrl}/egresoHostalCuota/actualizarPagado/`,
      body
    )
  }

  getBancos() {
    return this.http.get<any>(`${environment.apiUrl}/banco/obtenerBancos/`)
  }
}
