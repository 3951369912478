import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { ProductosService } from '@app/_pages/productos/productos.service';
import { StockActualService } from '@app/_pages/stockActual/stockActual.service';

@Component({
  selector: 'app-dialog-stockActual',
  templateUrl: './dialog-stockActual.component.html',
  styleUrls: ['./dialog-productos-editar.component.scss']
})
export class DialogStockActualComponent implements OnInit {

  productId: number;
  nombreProducto: string;
  categoria: string;
  listProducts:[]=[];

  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  formularioListo = new EventEmitter<string>()
  idStock: any;
  nombre: any;
  displayedColumns: string[] = [
    // 'select',
    'bodega',
    'estado',
    'stock',
    'costo',

  ];

  constructor(
    private route: ActivatedRoute,
    private stockService: StockActualService,
    public dialog: MatDialog,
    private productosService: ProductosService,
    @Inject(MAT_DIALOG_DATA) public data: { id: number, nombre: string, categoria: string }

  ) { }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.productId = +params['id'];
      this.loadProductDetails(this.data.id);
    });

    this.categoria = this.data.categoria;
    this.nombreProducto = this.data.nombre;

  }

  loadProductDetails(id: number): void {
    this.productosService.productsStockDetails(id).subscribe(data => {
      this.listProducts = data;
    });
  }

  openDialogVariante(id: any) {

    localStorage.getItem("idStock");

    this.stockService.productoGet();
  }

  closeDialog() {
    this.stockService.CloseDialogVariante();
  }

}
