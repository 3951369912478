<mat-tab-group>
  <!-- <mat-tab label="Actualizar IMG">
    <form [formGroup]="addressForm" novalidate (ngSubmit)="agregarIMG()">
      <mat-card class="shipping-card">
        <mat-expansion-panel
          (opened)="panelOpenState = true"
          (closed)="panelOpenState = false"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>Informacion IMG </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col">
              <input
                mat-stroked-button
                (change)="capturarFile($event)"
                type="file"
              />
              <br />
              <br />
            <button
                mat-stroked-button
                color="primary"
                [disabled]="loading"
                (click)="subirIMG()"
                (click)="clickButton = false"
                class="separador"
              >
                {{ loading ? 'Cargando....' : 'Subir IMG' }}
              </button> 
              <hr />
              <h3>Previsualizacion</h3>
              <div class="row">
                <div class="col">
                  <img width="400px" [src]="previsualizacion" alt="img" />
                </div>
              </div>
            </div>
            <hr class="separador" />
          </div>
        </mat-expansion-panel>
        <mat-card-actions>
          <button mat-raised-button color="primary" type="submit">
            <mat-icon>send</mat-icon>
            Guardar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab> -->
  <mat-tab label="IMAGEN 📃 ">
    <form [formGroup]="addressForm" novalidate>
      <mat-card class="shipping-card">
        <mat-card-content>
          <mat-card>
            <h3>Imagen Previsualizacion</h3>
            <hr />
            <div class="row">
              <div class="col" *ngIf="previsualizacion">
                <img width="400px" [src]="previsualizacion" alt="img" />
              </div>
            </div>
          </mat-card>
        </mat-card-content>
        <mat-card-actions>
          <button
            mat-stroked-button
            color="accent"
            style="margin-left: 1rem; margin-bottom: 3px"
            (click)="closeDialog()"
          >
            <mat-icon>cancel_white_18dp</mat-icon>Cancelar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>
  <mat-tab label="EDITAR 📝">
    <button
      color="accent"
      class="cerrar"
      (click)="closeDialog()"
      title="Cerrar"
    >
      <mat-icon>highlight_off</mat-icon>
    </button>
    <!-- Formulario -->
    <form [formGroup]="addressForm" novalidate class="mat-elevation-z10">
      <mat-card>
        <mat-card-header>
          <h1>Egreso Hostal</h1>
        </mat-card-header>
        <mat-card-content>
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>Información Cliente </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <!-- Fecha -->
              <div class="col">
                <form [formGroup]="addressForm">
                  <mat-form-field appearance="fill">
                    <mat-label>Fecha</mat-label>
                    <input matInput [value]="addressForm.controls['fecha'].value | date:'dd/MM/yyyy, h:mm a'" disabled>
                    <mat-error *ngIf="addressForm.controls['fecha']">
                      
                    </mat-error>
                  </mat-form-field>
                </form>
                <!-- <mat-form-field class="full-width">
                  <mat-label>Seleccionar fecha</mat-label>
                  <input
                    matInput
                    formControlName="fecha"
                    [matDatepicker]="picker"
                    [readonly]="true"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error
                    *ngIf="addressForm.controls['fecha'].hasError('required')"
                  >
                    fecha es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field> -->
              </div>

              <!-- Monto -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input matInput placeholder="Monto" formControlName="monto" />
                  <mat-error
                    *ngIf="addressForm.controls['monto'].hasError('required')"
                  >
                    este campo es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
              </div>

              <!-- Tipo egresos -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Tipo Egresos"
                    formControlName="tipoEgreso"
                  >
                    <mat-option
                      *ngFor="let tipoEgreso of tiposEgresos"
                      [value]="tipoEgreso"
                    >
                      {{ tipoEgreso }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      addressForm.controls['tipoPago'].hasError('required')
                    "
                  >
                    este campo es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <!-- gastos fijos -->
             
            <div class="col">
              <div *ngIf="addt">
              <mat-form-field class="full-width">
                <mat-select placeholder="Gastos Fijos" formControlName="gastosFijos">
                  <mat-option *ngFor="let gastosFijos of gastosFijo" [value]="gastosFijos">
                    {{ gastosFijos }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="addressForm.controls['gastosFijos'].hasError('required')">
                  este campo es <strong>requrido</strong>
                </mat-error>
              </mat-form-field>
              </div>
            
              
            </div>
            <!-- Numero cuenta -->
            <div class="col">
              <div *ngIf="addBanc">
                <mat-form-field class="full-width">
                  <mat-label>N° Cuenta corriente/Tarjeta</mat-label>
                  <input matInput placeholder="XXXX/4 Últimos Dígitos" formControlName="numeroCuenta" type="number" />
                  <mat-error *ngIf="addressForm.get('numeroCuenta').hasError('required')">
                    Este campo es obligatorio.
                  </mat-error>
                  <mat-error *ngIf="addressForm.get('numeroCuenta').hasError('pattern')">
                    Debe ingresar los últimos dígitos de la tarjeta/cuenta
                  </mat-error>
                </mat-form-field>
              </div>
            </div>


            <div class="row">
              <!-- responsable -->
              <div class="col">
                <mat-form-field class="full-width">
                 
                  <input matInput [value]="addressForm.controls['responsable'].value " disabled>
                  <mat-icon matSuffix>edit_18dp</mat-icon>
                  <mat-error
                    *ngIf="
                      addressForm.controls['responsable']
                    "
                  >
                    
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- descripcion -->
              <div class="col">
                <mat-form-field class="full-width">
                  <input
                    matInput
                    placeholder="ingrese una descripcion del registro"
                    formControlName="descripcion"
                  />
                  <mat-error
                    *ngIf="
                      addressForm.controls['descripcion'].hasError('required')
                    "
                  >
                    descripcion es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <!-- tipo pago -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Tipo Pago"
                    formControlName="tipoPago"
                  >
                    <mat-option
                      *ngFor="let tipoPago of tiposPagos"
                      [value]="tipoPago"
                    >
                      {{ tipoPago }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      addressForm.controls['tipoPago'].hasError('required')
                    "
                  >
                    este campo es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Grupo de envio de gastos -->
              <div class="col">
                <mat-form-field class="full-width">
                  <mat-select
                    placeholder="Sucursales"
                    formControlName="idSucursal"
                  >
                    <mat-option
                      *ngFor="let s of empresa.Sucursals"
                      [value]="s.id"
                    >
                      {{ s.razonSocial }}
                    </mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      addressForm.controls['idSucursal'].hasError('required')
                    "
                  >
                    este campo es <strong>requrido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </mat-expansion-panel>
          <br />
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>Información Imagen</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <div class="col">
                <!-- Capturar img -->
                <div>
                  <input
                    mat-stroked-button
                    (change)="capturarFile($event)"
                    type="file"
                  />
                </div>
                <br />
                <div>
                  <!-- <button
                    mat-stroked-button
                    color="primary"
                    [disabled]="loading"
                    (click)="subirIMG()"
                    (click)="clickButton = false"
                  >
                    {{ loading ? 'Cargando....' : 'Subir IMG' }}
                  </button> -->
                </div>
              </div>
            </div>
            <br />
            <h3>Imagen Previsualizacion</h3>
            <hr />
            <div class="row">
              <!-- Preview img -->
              <div class="col" *ngIf="previsualizacion">
                <img width="400px" [src]="previsualizacion" alt="img" />
              </div>
            </div>
            <hr />
          </mat-expansion-panel>
        </mat-card-content>
        <mat-card-actions>
          <button
            mat-raised-button
            color="primary"
            type="submit"
            (click)="onSubmit()"
          >
            <mat-icon>send</mat-icon>
            Guardar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>
</mat-tab-group>
