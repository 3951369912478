<mat-tab-group>
  <mat-tab label="PREVISUALIZACION📃 ">
    <form [formGroup]="addressForm" novalidate>
      <mat-card
        class="shipping-card"
        style="overflow-y: auto; max-height: 500px"
      >
        <mat-card-content>
          <mat-card>
            <div *ngIf="cuotavalidator">
            <h3>Contrato Previsualizacion</h3>
            <hr />
            </div>
            <div *ngIf="!cuotavalidator">
            <h3>Respaldo de Cuota Previsualizacion</h3>
            <hr />

            </div>

            <!------------ se muestra solo en contratos --------------------------->
            <div *ngIf="cuotavalidator">
              <div *ngIf="browser === 'Safari'">
                <!-- Contenido específico para Safari -->
                <p>Este contenido solo se muestra en Safari.</p>
                <iframe
                [src]="url"
                class="iframe-window"
                width="800"
                height="600"
                frameborder="0"
              >
              </iframe>
              <button
              mat-raised-button
              color="accent"
              (click)="descargarPDF()"
            >
              Descargar PDF
              </button>


              </div>

              <div *ngIf="browser !== 'Safari'">
                <!-- Contenido para otros navegadores -->
                <p>Este contenido se muestra en todos los navegadores excepto Safari.</p>
                <div class="row">
                  <iframe
                    [src]="url"
                    class="iframe-window"
                    width="800"
                    height="600"
                    frameborder="0"
                  >
                  </iframe>
                 </div>
                
              </div>
             
            </div>
            <!------------ se muestra solo en cuotas --------------------------->
            
            <div *ngIf="!cuotavalidator && estadoCuota === 'pagado'">
              <div class="img-container">
                <img
                  [src]="photoUrl"
                  alt="Imagen del contrato"
                  class="img-styled"
                />
              </div>
              <button
                mat-raised-button
                color="accent"
                (click)="descargarImagen()"
              >
                Descargar Imagen
              </button>
            </div>
          </mat-card>
        </mat-card-content>
        <mat-card-actions>
          <button
            mat-stroked-button
            color="accent"
            style="margin-left: 1rem; margin-bottom: 3px"
            (click)="guardarYVerificarCambios()"
          >
            <mat-icon>cancel_white_18dp</mat-icon>Cancelar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>

  <mat-tab label="EDITAR 📝">
    <button
      color="accent"
      class="cerrar"
      (click)="closeDialog()"
      title="Cerrar"
    >
      <mat-icon>highlight_off</mat-icon>
    </button>
    <form
      [formGroup]="addressForm"
      novalidate
      class="mat-elevation-z10"
      (ngSubmit)="onSubmit()"
    >
      <mat-card
        class="shipping-card-edit"
        style="overflow-y: auto; max-height: 500px"
      >
        <mat-card-header>
          <h1>Ingresos Firma Abogados</h1>
        </mat-card-header>
        <mat-card-content>
          <!------------ se muestra solo en contratos --------------------------->
          <div *ngIf="cuotavalidator">
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>Información Cliente </mat-panel-title>
              </mat-expansion-panel-header>
              <!-- Formulario de edicion -->
              <div class="row">
                <mat-card
                  class="shipping-card-edit"
                  style="overflow-y: auto; max-height: 500px"
                >
                  <mat-card-header>
                    <h1>Información del Cliente</h1>
                  </mat-card-header>
                  <mat-card-content>
                    <div class="row">
                      <!-- Nombre Cliente -->
                      <div class="col">
                        <mat-form-field class="full-width">
                          <mat-label>Nombre Cliente</mat-label>
                          <input
                            matInput
                            formControlName="nombreCliente"
                            placeholder="Nombre del Cliente"
                          />
                        </mat-form-field>
                      </div>
                      <!-- RUT Cliente -->
                      <div class="col">
                        <mat-form-field class="full-width">
                          <mat-label>RUT Cliente</mat-label>
                          <input
                            matInput
                            formControlName="rutCliente"
                            placeholder="RUT del Cliente"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <!-- Email Cliente -->
                      <div class="col">
                        <mat-form-field class="full-width">
                          <mat-label>Email Cliente</mat-label>
                          <input
                            matInput
                            formControlName="emailCliente"
                            placeholder="Email del Cliente"
                          />
                        </mat-form-field>
                      </div>
                      <!-- Dirección Cliente -->
                      <div class="col">
                        <mat-form-field class="full-width">
                          <mat-label>Dirección Cliente</mat-label>
                          <input
                            matInput
                            formControlName="direccionCliente"
                            placeholder="Dirección del Cliente"
                          />
                        </mat-form-field>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>

              <!-- </div> -->
            </mat-expansion-panel>
          </div>

          <br class="separador" />
          <!------------ se muestra solo en contratos --------------------------->
          <!-- Subir imagen -->
          <div *ngIf="!cuotavalidator">
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>Información de Contrato</mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row">
                <!-- Nombre Cliente -->
                <mat-card class="contract-info-card">
                  <mat-card-header>
                    <mat-card-title>Información del Contrato</mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    <div class="row">
                      <div class="col">
                        <div class="info-item">
                          <label>ID del Contrato:</label>
                          <span>{{ dataF?.id || 'N/A' }}</span>
                        </div>

                        <div class="info-item">
                          <label>Monto del Contrato:</label>
                          <span>{{ dataF?.montoContrato || 'N/A' }}</span>
                        </div>

                        <div class="info-item">
                          <label>Cliente ID:</label>
                          <span>{{ dataF?.idCliente || 'N/A' }}</span>
                        </div>
                      </div>

                      <div class="col">
                        <div class="info-item">
                          <label>Estado de Pago:</label>
                          <span>{{ dataF?.estadoPago || 'N/A' }}</span>
                        </div>

                        <div class="info-item">
                          <label>Usuario ID:</label>
                          <span>{{ dataF?.idUsuario || 'N/A' }}</span>
                        </div>

                        <div class="info-item">
                          <label>Número de Contrato:</label>
                          <span>{{ dataF?.nContrato || 'N/A' }}</span>
                        </div>
                      </div>

                      <div class="col">
                        <div class="info-item">
                          <label>Fecha del Contrato:</label>
                          <span>{{ formattedDate || 'N/A' }}</span>
                        </div>

                        <div class="info-item">
                          <label>Sucursal:</label>
                          <div *ngIf="empresa?.Sucursals?.length > 0">
                            <span>{{
                              empresa.Sucursals[0]?.razonSocial ||
                                'No disponible'
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>

              <hr />
              <div class="row">
                <!-- Preview img -->
                <div class="col" *ngIf="previsualizacion">
                  <img width="400px" [src]="previsualizacion" alt="img" />
                </div>
              </div>
              <hr />
            </mat-expansion-panel>
          </div>

          <!------------ se muestra solo en contratos --------------------------->
          <br class="separador" />

          <!-- SUBIR CONTRATO -->

          <div *ngIf="cuotavalidator">
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>Subir contrato PDF</mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row">
                <div class="col">
                  <!-- Capturar PDF -->
                  <!-- <div>
                    <input
                      mat-stroked-button
                      type="file"
                      accept="application/pdf"
                      (change)="onFileChange($event)"
                    />
                  </div>
                  <br />
                  <div>
                    <button
                      mat-stroked-button
                      color="primary"
                      [disabled]="
                        !addressFormContrato.get('pdf')?.value || loading
                      "
                      (click)=" validarContrato()"
                    >
                      {{ loading ? 'Cargando....' : 'Subir PDF' }}
                    </button> -->
                    <div>
                      <label for="imagenInput" class="custom-upload-button">
                        Seleccionar Archivo
                      </label>
                      <input
                        id="imagenInput"
                        type="file"
                        accept="application/pdf"
                        (change)="onFileChange($event)"
                        style="display: none;"
                      />
                    </div>
                    <br />
                    <div>
                      <button
                        mat-stroked-button
                        color="primary"
                        class="custom-submit-button"
                        [disabled]="!addressFormContrato.get('pdf')?.value || loading"
                        (click)="validarContrato()"
                      >
                        {{ loading ? 'Cargando....' : 'Subir Imagen' }}
                      </button>
                  </div>
                </div>
              </div>
              <br />
              <h3>PDF Previsualización</h3>
              <hr />
              <div class="row">
                <div *ngIf="pdfPreview">
                  <embed
                    [src]="pdfPreview"
                    type="application/pdf"
                    width="400"
                    height="300"
                  />
                </div>
              </div>
              <hr />
            </mat-expansion-panel>
          </div>

          <!------------ se muestra solo en cuotas --------------------------->
          <!-- Subir imagen -->
          <div *ngIf="!cuotavalidator">
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title>Subir Respaldo de la Cuota</mat-panel-title>
              </mat-expansion-panel-header>

              <div class="row">
                <div class="col">
                  <!-- Capturar Imagen -->
                  <div>
                    <!-- <input
                      mat-stroked-button
                      type="file"
                      accept="image/*"
                      (change)="onImagenChange($event)"
                    />
                  </div>
                  <br />
                  <div>
                    <button
                      mat-stroked-button
                      color="primary"
                      [disabled]="
                        !addressFormimagen.get('imagen')?.value || loading
                      "
                      (click)="subirImagen()"
                    >
                      {{ loading ? 'Cargando....' : 'Subir Imagen' }}
                    </button> -->
                    <div>
                      <label for="imagenInput" class="custom-upload-button">
                        Seleccionar Archivo
                      </label>
                      <input
                        id="imagenInput"
                        type="file"
                        accept="image/*"
                        (change)="onImagenChange($event)"
                        style="display: none;"
                      />
                    </div>
                    <br />
                    <div>
                      <button
                        mat-stroked-button
                        color="primary"
                        class="custom-submit-button"
                        [disabled]="!addressFormimagen.get('imagen')?.value || loading"
                        (click)="subirImagen()"
                      >
                        {{ loading ? 'Cargando....' : 'Subir Imagen' }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <h3>Previsualización de la Imagen</h3>
              <hr />
              <div class="row">
                <div *ngIf="imagenPreview">
                  <img
                    [src]="imagenPreview"
                    alt="Previsualización de la imagen"
                    width="400"
                    height="300"
                  />
                </div>
              </div>
              <hr />
            </mat-expansion-panel>
          </div>
        </mat-card-content>
        <mat-card-actions>
          <button
            mat-raised-button
            color="primary"
            type="submit"
            (click)=" guardarYVerificarCambios()"
          >
            <mat-icon>send</mat-icon>
            Guardar
          </button>
        </mat-card-actions>
      </mat-card>
    </form>
  </mat-tab>
</mat-tab-group>
