        <form [formGroup]="addressForm" novalidate class="mat-elevation-z10" (ngSubmit)="onSubmit()">
            <mat-card class="shipping-card">
                <mat-card-header>
                    <h1>Productos/Servicios</h1>
                </mat-card-header>
                <br>
                <mat-card-content>
                    <div class="row">
                        <!-- Tipo: Productos/Servicios -->
                        <div class="col">
                            <mat-form-field class="full-width">

                                    <mat-select placeholder="Seleccione" formControlName="tipo">
                                        <mat-option value="Producto"> Producto </mat-option>
                                        <mat-option value="Servicio"> Servicio </mat-option>
                                    </mat-select>
                                <mat-error *ngIf="addressForm.controls['tipo'].hasError('required')">
                                    tipo es <strong>requerido</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col">
                            <section>
                                <mat-checkbox style="width: 10%;" id="cosa" (click)="mostrarFormulario()">El producto/servicio contiene variantes</mat-checkbox>
                            </section>
                            <section>
                              <mat-checkbox style="width: 10%;" id="afecto" (click)="afecto()" formControlName="afecto">El producto/servicio se encuentra afecto a IVA</mat-checkbox>

                          </section>

                        </div>

                        </div>

                    <div class="row">

                        <!-- Codigo SKU -->
                        <div class="col">
                            <mat-form-field class="full-width">
                                <mat-select placeholder="Codigo SKU" formControlName="codigoSKU">
                                    <mat-option value="07PZU"> 07PZU - Rancagua</mat-option>
                                    <mat-option value="15PZU"> 15PZU - Talca</mat-option>
                                    <mat-option value="88PZU"> 88PZU - Concepcion</mat-option>
                                </mat-select>
                            <mat-error *ngIf="addressForm.controls['tipo'].hasError('required')">
                                CodigSKU es <strong>requerido</strong>
                            </mat-error>
                                </mat-form-field>
                        </div>

                        <!-- Nombre Productos/Servicios -->
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input matInput placeholder="Nombre" formControlName="nombre"/>
                                <mat-error *ngIf="addressForm.controls['nombre'].hasError('required')">
                                    Nombre es <strong>requerido</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">

                        <!-- Categoria -->
                        <div class="col">
                            <mat-form-field class="full-width">
                                <mat-select placeholder="Categoria" formControlName="idCategoria">
                                    <mat-option *ngFor="let categoria of categorias" value="{{ categoria.id }}">
                                        {{ categoria.nombre }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="addressForm.controls['idCategoria'].hasError('required')">
                                    Categoria es <strong>requerido</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <!-- Tipo de Moneda-->
                        <div class="col">
                            <mat-form-field class="full-width">
                                    <mat-select placeholder="Seleccione Tipo Moneda" formControlName="moneda">
                                        <mat-option value="pesos"> Pesos </mat-option>
                                        <mat-option value="uf"> UF </mat-option>
                                    </mat-select>
                                <mat-error>
                                    Tipo Moneda es <strong>requerido</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>

                    </div>

                    <div class="row">

                        <!-- Precio Unitario -->
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input matInput placeholder="Costo Unitario" formControlName="unitario" type="number" (input)="calcularUnitario()" blur="afecto()"/>
                                <mat-error>
                                    Precio Unitario es <strong>requerido</strong>
                                </mat-error>
                            </mat-form-field>
                        </div> 

                         <!-- Margen de Ganancia -->
                         <div class="col">
                            <mat-form-field class="full-width">
                                <!-- <input matInput placeholder="Margen de Ganancia" formControlName="ganancia" (input)="calcularGanancia()"  /> -->
                                <input matInput placeholder="Margen de Ganancia" formControlName="ganancia" (blur)="calcularGanancia()"  />

                                <mat-icon matSuffix>percent_black_24dp</mat-icon>
                                <mat-error *ngIf="addressForm.controls['ganancia'].hasError('required')">
                                    Margen de Ganancia es <strong>requerido</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>

                    </div>
                    

                    <div class="row">

                        <!-- Precio Neto -->
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input matInput placeholder="Precio Neto" formControlName="neto" type="number" (input)="calcularGanancia()" />
                                {{gananciaMargen.toFixed() | currency:'CLP':'symbol':'1.0-0'}}
                                <mat-error>
                                     Precio Neto es <strong>requerido</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <!-- Precio Venta-->
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input matInput placeholder="Precio Venta" formControlName="venta" type="number" readonly />
                                {{montoTotal.toFixed() | currency:'CLP':'symbol':'1.0-0' }}
                                <mat-error>
                                     Precio Venta es <strong>requerido</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <!-- Estado -->
                        <div class="col">
                            <mat-form-field class="full-width">
                                    <mat-select placeholder="Estado" formControlName="estado">
                                        <mat-option value="Vigente"> Vigente </mat-option>
                                        <mat-option value="No Vigente"> No Vigente </mat-option>
                                    </mat-select>
                                <mat-error>
                                    Estado es <strong>requerido</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>


                </mat-card-content>

                <!-- botón volver -->
                <button routerLink="/productos" mat-raised-button color="primary" type="submit">
                    <mat-icon>arrow_back_black_24dp</mat-icon> Volver
                </button>

                <!-- botón enviar -->
                <button *ngIf="!isShown" style="float: right" mat-raised-button color="primary" type="submit">
                    <mat-icon>send</mat-icon> Enviar
                </button>



            </mat-card>
        </form>

        <br>

        <form [formGroup]="addressForm2" novalidate class="mat-elevation-z10" (ngSubmit)="onSubmit()">
        <mat-card class="shipping-card" *ngIf="isShown"><br>
            <div>
                <a class="btn btn-primary" mat-raised-button style="float: right" color="primary" (click)="agregarVariante()"><mat-icon>add_black_18dp</mat-icon>Agregar Variante</a>
            </div>
            <mat-card-header>
                <h1>Variantes</h1>
            </mat-card-header>
            <br>
            <ng-container formArrayName="variantes">
            <ng-container *ngFor="let variante of variantes.controls; let i=index">
            <div [formGroupName]="i">
            <mat-card-content>
                <div class="row">
                    <!-- Variantes Nombre-->
                    <div class="col-sm-4">
                        <mat-form-field class="full-width">
                            <input [attr.for]="'nombreVariante' + i" [attr.id]="'nombreVariante' + i" matInput placeholder="Nombre" formControlName="nombreVariante"/>

                        </mat-form-field>
                    </div>

                    <!-- Codigo SKU -->
                    <div class="col-sm-4">
                        <mat-form-field class="full-width">
                            <input [attr.for]="'codigoSKUVariante' + i" [attr.id]="'codigoSKUVariante' + i"  matInput placeholder="Codigo SKU" formControlName="codigoSKUVariante"/>

                        </mat-form-field>
                    </div>

                    <!-- Precio Neto -->
                    <div class="col-sm-3">
                        <mat-form-field class="full-width">
                            <input [attr.for]="'netoVariante' + i" [attr.id]="'netoVariante' + i"  matInput placeholder="Precio Neto" formControlName="netoVariante"/>

                        </mat-form-field>
                    </div>
                </div>

                <div class="row">

                    <!-- Precio Unitario -->
                    <div class="col-sm-4">
                        <mat-form-field class="full-width">
                            <input [attr.for]="'unitarioVariante' + i" [attr.id]="'unitarioVariante' + i"  matInput placeholder="Costo Unitario" formControlName="unitarioVariante"/>

                        </mat-form-field>
                    </div>

                    <!-- Estado -->
                    <div class="col-sm-4">
                        <mat-form-field class="full-width">
                                <mat-select [attr.for]="'estadoVariante' + i" [attr.id]="'estadoVariante' + i"  placeholder="Estado" formControlName="estadoVariante">
                                    <mat-option value="Vigente"> Vigente </mat-option>
                                    <mat-option value="No Vigente"> No Vigente </mat-option>
                                </mat-select>

                        </mat-form-field>
                    </div>

                    <!-- Descripcion-->
                    <div class="col-sm-3">
                        <mat-form-field class="full-width">
                            <input  [attr.for]="'descripcionVariante' + i" [attr.id]="'descripcionVariante' + i"  matInput placeholder="Descripcion" formControlName="descripcionVariante"/>

                        </mat-form-field>
                    </div>

                    <div>
                        <a style="height: 10%;" class="btn btn-danger" style="float: right" (click)="eliminarVariante(i)"><mat-icon>delete_black_24dp</mat-icon></a>
                    </div>
                </div>
                <br>


            </mat-card-content>
            </div>
            </ng-container>
            </ng-container>
        </mat-card>
        <button *ngIf="isShown" style="float: right" mat-raised-button color="primary" type="submit">
            <mat-icon>send</mat-icon> Enviar
        </button>
    </form>



