<h1>Registro de Cliente</h1>
<mat-horizontal-stepper linear>
  <mat-step [stepControl]="infBasica" completed="false">
    <form [formGroup]="infBasica" id="infBasica" action="##" novalidate>
      <ng-template matStepLabel>Informacion Basica</ng-template>
      <mat-card>
        <!-- Estado del cliente  -->
        <div class="col">
          <section class="estado-section">
            <p>
              <mat-checkbox formControlName="estado" value="{{ estado }}"
                >Cliente Vigente
              </mat-checkbox>
            </p>
          </section>
        </div>
        <div class="row">
          <div class="col">
            <!-- nombre -->
            <mat-form-field appearance="legacy">
              <mat-label>Nombre/Razon Social</mat-label>
              <input
                matInput
                type="string"
                placeholder="Ingresa Nombre"
                formControlName="nombre"
                required
              />
              <mat-icon matSuffix>badge_white_18dp</mat-icon>
              <mat-error
                *ngIf="infBasica.controls['nombre'].hasError('required')"
              >
                <strong> El nombre es requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <!-- alias -->
            <mat-form-field appearance="legacy">
              <mat-label>Nombre Fantasía</mat-label>
              <input
                matInput
                type="string"
                placeholder="Ingresa Nombre Fantasía"
                formControlName="alias"
                required
              />
              <mat-icon matSuffix>branding_watermark_white_18dp</mat-icon>
              <mat-error
                *ngIf="infBasica.controls['alias'].hasError('required')"
              >
                <strong> El nombre de fantasia es requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <!-- rut -->
            <mat-form-field appearance="legacy">
              <mat-label>Rut</mat-label>
              <input
                matInput
                minlength="8"
                maxlength="10"
                placeholder="Ingresa Rut"
                formControlName="rut"
                required
                [(ngModel)]="tamano_rut"
                type="string"
                (input)="formatoRut()"
              />
              <mat-icon matSuffix>fingerprint_white_18dp</mat-icon>
              <mat-error *ngIf="infBasica.controls['rut'].hasError('required')">
                <strong> El rut es requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <!-- giro -->
            <mat-form-field appearance="legacy">
              <mat-label>Giro</mat-label>
              <input
                matInput
                placeholder="Ingresa Giro"
                formControlName="giro"
                required
              />
              <mat-icon matSuffix>currency_exchange_white_18dp</mat-icon>
              <mat-error
                *ngIf="infBasica.controls['giro'].hasError('required')"
              >
                <strong> El giro es requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <!-- codigo -->
            <mat-form-field appearance="legacy">
              <mat-label>Codigo</mat-label>
              <input
                matInput
                id="ValorCodigo"
                placeholder="Ingresa Codigo"
                formControlName="codigo"
                required
                (click)="generateRandomString(6)"
              />
              <mat-icon matSuffix>vpn_key_white_18dp</mat-icon>
              <mat-error
                *ngIf="infBasica.controls['codigo'].hasError('required')"
              >
                <strong> El codigo es requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
      <div>
        <button mat-button matStepperPrevious>
          <mat-icon>arrow_back_ios_new_white_18dp</mat-icon> Anterior
        </button>
        <button mat-button matStepperNext>
          Siguiente <mat-icon>arrow_forward_ios_white_18dp</mat-icon>
        </button>
      </div>
    </form>
  </mat-step>
  <mat-step
    [stepControl]="infUbicacion"
    label="Informacion de Ubicacion"
    completed="false"
  >
    <form [formGroup]="infUbicacion" novalidate>
      <br />
      <mat-card>
        <div class="row">
          <div class="col">
            <!-- direccion -->
            <mat-form-field appearance="legacy">
              <mat-label>Direccion</mat-label>
              <input
                matInput
                type="string"
                placeholder="Ingresa Direccion"
                formControlName="direccion"
                required
              />
              <mat-icon matSuffix>home_white_18dp</mat-icon>
              <mat-error
                *ngIf="infUbicacion.controls['direccion'].hasError('required')"
              >
                <strong> La direccion es requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <!-- ciudad -->
            <mat-form-field appearance="legacy">
              <mat-label>Ciudad</mat-label>
              <input
                matInput
                type="string"
                placeholder="Ingrese Ciudad"
                formControlName="ciudad"
                required
              />
              <mat-icon matSuffix>location_city_white_18dp</mat-icon>
              <mat-error
                *ngIf="infUbicacion.controls['ciudad'].hasError('required')"
              >
                <strong> La ciudad es requerida</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <!-- comuna -->
            <mat-form-field appearance="legacy">
              <mat-label>Comuna</mat-label>
              <input
                matInput
                type="string"
                placeholder="Ingresa Comuna"
                formControlName="comuna"
                required
              />
              <mat-icon matSuffix>push_pin_white_18dp</mat-icon>
              <mat-error
                *ngIf="infUbicacion.controls['comuna'].hasError('required')"
              >
                <strong> La comuna es requerida</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
      <div>
        <button mat-button matStepperPrevious>
          <mat-icon>arrow_back_ios_new_white_18dp</mat-icon> Anterior
        </button>
        <button mat-button matStepperNext>
          Siguiente <mat-icon>arrow_forward_ios_white_18dp</mat-icon>
        </button>
      </div>
    </form>
  </mat-step>
  <mat-step
    [stepControl]="infPago"
    label="Informacion de Pago"
    completed="false"
  >
    <form [formGroup]="infPago" novalidate>
      <br />
      <mat-card>
        <div class="row">
          <div class="col">
            <!-- nombre Responsable -->
            <mat-form-field appearance="legacy">
              <mat-label>Responsable</mat-label>
              <input
                matInput
                type="string"
                placeholder="Ingresa Responsable"
                formControlName="nombreResponsable"
                required
              />
              <mat-icon matSuffix
                >transfer_within_a_station_white_18dp</mat-icon
              >
              <mat-error
                *ngIf="
                  infPago.controls['nombreResponsable'].hasError('required')
                "
                ><strong> El responsable es requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <!-- email -->
            <mat-form-field appearance="legacy">
              <mat-label>Email</mat-label>
              <input
                matInput
                type="string"
                placeholder="Ingrese Email"
                formControlName="email"
                required
              />
              <mat-icon matSuffix>mail_white_18dp</mat-icon>
              <mat-error *ngIf="infPago.controls['email'].hasError('required')"
                ><strong> El email es requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <!-- telefono -->
            <mat-form-field appearance="legacy">
              <mat-label>Telefono</mat-label>
              <input
                matInput
                minlength="8"
                maxlength="10"
                placeholder="Ingresa Telefono"
                formControlName="telefono"
                required
                type="number"
              />
              <mat-icon matSuffix>add_call_white_18dp</mat-icon>
              <mat-error
                *ngIf="infPago.controls['telefono'].hasError('required')"
                ><strong> El telefono es requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
      <div>
        <button mat-button matStepperPrevious>
          <mat-icon>arrow_back_ios_new_white_18dp</mat-icon> Anterior
        </button>
        <button mat-button matStepperNext>
          Siguiente <mat-icon>arrow_forward_ios_white_18dp</mat-icon>
        </button>
      </div>
    </form>
  </mat-step>
  <mat-step
    [stepControl]="infAdicional"
    label="Informacion Adicional"
    completed="false"
  >
    <form [formGroup]="infAdicional" novalidate (ngSubmit)="onSubmit()">
      <br />
      <mat-card>
        <div class="row">
          <div class="col">
            <!--  contacto Comercial -->
            <mat-form-field appearance="legacy">
              <mat-label>Contacto Comercial</mat-label>
              <input
                matInput
                type="string"
                placeholder="Ingresa Contacto"
                formControlName="contactoComercial"
                required
              />
              <mat-icon matSuffix>supervisor_account_white_18dp</mat-icon>
              <mat-error
                *ngIf="
                  infAdicional.controls['contactoComercial'].hasError(
                    'required'
                  )
                "
                ><strong> El contacto comercial es requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <!-- emailComercial -->
            <mat-form-field appearance="legacy">
              <mat-label>Email Comercial</mat-label>
              <input
                matInput
                type="string"
                placeholder="Ingrese Email"
                formControlName="emailComercial"
                required
              />
              <mat-icon matSuffix>outgoing_mail_white_18dp</mat-icon>
              <mat-error
                *ngIf="
                  infAdicional.controls['emailComercial'].hasError('required')
                "
                ><strong> El email comercial es requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <!-- telefono -->
            <mat-form-field appearance="legacy">
              <mat-label>Telefono Comercial</mat-label>
              <input
                matInput
                minlength="8"
                maxlength="10"
                placeholder="Ingresa Telefono"
                formControlName="telefonoComercial"
                required
                type="number"
              />
              <mat-icon matSuffix>settings_phone_white_18dp</mat-icon>
              <mat-error
                *ngIf="
                  infAdicional.controls['telefonoComercial'].hasError(
                    'required'
                  )
                "
                ><strong> El telefono comercial es requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <!-- celular -->
            <mat-form-field appearance="legacy">
              <mat-label>Celular</mat-label>
              <input
                matInput
                minlength="8"
                maxlength="10"
                placeholder="Ingresa Celular"
                formControlName="celular"
                required
                type="number"
              />
              <mat-icon matSuffix>settings_cell_white_18dp</mat-icon>
              <mat-error
                *ngIf="infAdicional.controls['celular'].hasError('required')"
                ><strong> El celular es requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <!-- descuento -->
            <mat-form-field appearance="legacy">
              <mat-label>Descuento</mat-label>
              <input
                matInput
                placeholder="Ingresa Descuento"
                formControlName="descuento"
                required
                type="number"
              />
              <mat-icon matSuffix>local_offer_white_18dp</mat-icon>
              <mat-error
                *ngIf="infAdicional.controls['descuento'].hasError('required')"
                ><strong> El descuento es requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <!-- credito -->
            <mat-form-field appearance="legacy">
              <mat-label>Credito</mat-label>
              <input
                matInput
                placeholder="Nº de dias"
                formControlName="credito"
                required
                type="number"
              />
              <mat-icon matSuffix>credit_card_white_18dp</mat-icon>
              <mat-error
                *ngIf="infAdicional.controls['credito'].hasError('required')"
                ><strong> El credito es requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col">
            <!-- lineaCredito -->
            <mat-form-field appearance="legacy">
              <mat-label>Linea Credito</mat-label>
              <input
                matInput
                placeholder="0"
                formControlName="lineaCredito"
                required
                type="number"
              />
              <mat-icon matSuffix>show_chart_white_18dp</mat-icon>
              <mat-error
                *ngIf="
                  infAdicional.controls['lineaCredito'].hasError('required')
                "
                ><strong> La linea de Credito es requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col">
            <mat-form-field appearance="legacy">
              <mat-label>Tipo de Pago </mat-label>
              <mat-select formControlName="formaPago">
                <mat-option value="cheque">Cheque</mat-option>
                <mat-option value="credito">Credito</mat-option>
                <mat-option value="DepositoEfectivo"
                  >Deposito en efectivo</mat-option
                >
                <mat-option value="efectivo">Efectivo</mat-option>
                <mat-option value="tarjetaCredito">Tarjeta Credito</mat-option>
                <mat-option value="tarjetaDebito">Tarjeta Debito</mat-option>
                <mat-option value="transferenicaElectronica"
                  >Transferencia Electronica</mat-option
                >
                <mat-option value="notaCreditoDevolucion"
                  >Nota Credito Devolcuion</mat-option
                >
                <mat-option value="webPay">Webpay</mat-option>
              </mat-select>
              <mat-icon matSuffix>payments_white_18dp</mat-icon>
              <mat-error
                *ngIf="infAdicional.controls['formaPago'].hasError('required')"
                ><strong> La forma de pago es requerida</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <!-- referencia -->
            <mat-form-field appearance="legacy">
              <mat-label>Referencia</mat-label>
              <textarea
                matInput
                type="string"
                placeholder="Ingrese Referencia"
                formControlName="referencia"
                required
              ></textarea>
              <mat-icon matSuffix>description_white_18dp</mat-icon>
              <mat-error
                *ngIf="infAdicional.controls['referencia'].hasError('required')"
                ><strong> La referencia es requerida</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-card>
      <div>
        <button mat-button matStepperPrevious>
          <mat-icon>arrow_back_ios_new_white_18dp</mat-icon> Anterior
        </button>
        <button
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="infAdicional.invalid"
        >
          <mat-icon>send</mat-icon> Enviar
        </button>
      </div>
    </form>
  </mat-step>
</mat-horizontal-stepper>
