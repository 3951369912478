<mat-card class="open-card" (click)="showFullContent = !showFullContent">
    <mat-card-title>Folios restantes: {{nombreDTE}}</mat-card-title>
    <mat-card-content>
        <div class="spoiler">
            <div class="content" [ngClass]="{ 'expanded': showFullContent, 'gradient-box': !showFullContent }">
                <mat-chip-list class="single-line-chip-list">
                    <mat-chip *ngFor="let empresa of empresasFolios" [color]="empresa.color" selected>
                        <span>
                            <b>{{empresa.nombre}}:</b> {{empresa.folioRestante ? empresa.folioRestante : 'Sin folios'}}
                        </span>
                    </mat-chip>
                </mat-chip-list>
            </div>
        </div>
    </mat-card-content>
</mat-card>