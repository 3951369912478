import { SelectionModel } from '@angular/cdk/collections'
import { HttpClient } from '@angular/common/http'
import {
  Component,
  Input,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSnackBar } from '@angular/material/snack-bar'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { DialogShow } from '@app/_components/dialogs/dialog-downloads/dialog-downloads.component'
import { EgresoHostal } from '@app/_models/hostal/egresoHostal'
import { Sucursal } from '@app/_models/shared/sucursal'
import { SucursalSharedService } from '@app/_pages/shared/shared-services/sucursal-shared.service'
import { environment } from '@environments/environment'
import { HostalService } from '../../hostal.service'
import { saveAs } from 'file-saver'
import { Usuario } from '@app/_models/shared/usuario'
import * as ExcelJS from 'exceljs'
import { EgresoAgroFirma } from '@app/_models/agroFirma/egresoAgroFirma';

@Component({
  selector: 'app-hostal-egresos-tabla',
  templateUrl: './hostal-egresos-tabla.component.html',
  styleUrls: ['./hostal-egresos-tabla.component.scss']
})
export class HostalEgresosTablaComponent implements OnInit {
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>()
  @ViewChild(MatSort) sort = new MatSort()

  @Input() refrescar = ''
  usuario: Usuario = JSON.parse(localStorage.getItem('usuario') + '')

  displayedColumns: string[] = [
    'select',
    'id',
    'tipoEgreso',
    'fecha',
    'monto',
    'responsable',
    'descripcion',
    // 'grupoGastos',
    'tipoPago',
    'sucursal',
    'acciones',
    
    //los siguientes campos se solicitaron fuera de los requerimientos formales: 
    // 'numeroCuota',
    // 'regiones',
    // 'proyecto',
    
  ]
  dataSource: MatTableDataSource<EgresoHostal> = new MatTableDataSource()
  dataEgresos: EgresoHostal[] = []
  rolUsuario: string

  changelog: string[] = []
  //filtros
  formFilter = new FormGroup({
    id: new FormControl(),
    responsable: new FormControl(''), // FormControl para el filtro del responsable
    monto: new FormControl(),
    start: new FormControl(),
    end: new FormControl(),
    idSucursal: new FormControl(),
    tipoEgreso: new FormControl(),
    tipoPago: new FormControl(),
    gastosFijos: new FormControl(),
    idUsuario: new FormControl(),
    numeroCuota: new FormControl(),
    descripcion: new FormControl(),
    grupoGastos:new FormControl(),
    regiones: new FormControl(),
    proyecto: new FormControl()
  })
  sucursales: Sucursal[] = []
  selection = new SelectionModel<EgresoHostal>(true, [])
  egresosForm!: FormGroup;
  tiposEgresos: string[] = []
  totalSeleccion = 0
  selectedRows!: any[]
  cuentasRegistradas: any[] = []
  tiposPagos: any[] = []
  gastosFijo: string[]
  grupos: string[] = [];
  parentCount = 0
  regiones: string[] = []
  result = 'N/A'

  egreso: EgresoAgroFirma = new EgresoAgroFirma();

  constructor(
    private hostalService: HostalService,
    public dialog: MatDialog,
    private sucursalService: SucursalSharedService,
    private snackBar: MatSnackBar,
    private http: HttpClient
  ) {
    this.sucursales = this.sucursalService.sucursalListValue
    this.gastosFijo = ['Luz', 'Agua', 'Gas', 'Internet']
    this.tiposEgresos = [
      'Gastos',
      'Gastos Fijos',
      'Costos',
      'Remuneraciones',
      'Impuestos',
      'Bancarios',
      'Prestamos Bancarios',
      'Prestamos Automotriz'
    ]
    this.tiposPagos = [
      'Efectivo',
      'Debito',
      'Credito',
      'Transferencia',
      'Cheque'
    ]
    this.regiones = [
      'Región de Arica y Parinacota',
      'Región de Tarapacá',
      'Región de Antofagasta',
      'Región de Atacama',
      'Región de Coquimbo',
      'Región de Valparaíso',
      'Región Metropolitana de Santiago',
      'Región del Libertador General Bernardo OHiggins',
      'Región del Maule',
      'Región del Biobío',
      'Región de La Araucanía',
      'Región de Los Ríos',
      'Región de Los Lagos',
      'Región de Aysén',
      'Región de Magallanes']

      this.grupos = [
        'TRANSFERENCIAS RENT A CAR',
        'TRANSFERENCIAS HOSTAL',
        'TRANSFERENCIAS GRUPO FIRMA',
        'TRANSFERENCIAS FIRMA ABOGADOS',
        'TRANSFERENCIAS IMPORTADORA',
        'TRANSFERENCIAS EXPORTADORA',
        'TRANSFERENCIAS CAMPO',
        'TRANSFERENCIAS INMOBILIARIA',
        'TRANSFERENCIAS LUBRICENTRO',
        'TRANSFERENCIAS GASTO PERSONAL',
        'TRANSFERENCIAS PRESTAMOS ENTRE EMPRESAS'  
    ]
  }

  ngOnInit(): void {
    this.aplicarfiltros()
    this.actualizarTabla()
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName of Object.keys(changes)) {
      const change = changes[propName]
      const to = JSON.stringify(change.currentValue)
      const from = JSON.stringify(change.previousValue)
      const changeLog = `${propName}: changed from ${from} to ${to} `
      this.changelog.push(changeLog)
      this.hostalService.egresoGetAll().subscribe((data: EgresoHostal[]) => {
        this.dataEgresos = data.map((egreso) => {
          //console.log(egreso)

          //egreso.sucursal = egreso.Sucursal.razonSocial

          //egreso.usuario = egreso.Usuario.nombreUsuario
          return egreso
        })
        this.egreso.grupoGastos = this.egresosForm.value.grupoGastos;
        this.dataSource = new MatTableDataSource(this.dataEgresos)
        this.dataSource.paginator = this.paginator.toArray()[0]
        this.dataSource.sort = this.sort
      })
    }
  }

  actualizarTabla() {
    this.hostalService.egresoGetAll().subscribe((data: EgresoHostal[]) => {
      this.dataEgresos = data.map((egreso) => {
        egreso.sucursal = egreso.Sucursal.razonSocial
        // egreso.usuario = egreso.Usuario.nombreUsuario;
        return egreso
      })
      this.dataSource = new MatTableDataSource(this.dataEgresos);
      this.dataSource.paginator = this.paginator.first;
      this.dataSource.sort = this.sort;

      
      //Conviertiendo los numeros de cuotas Nulos en N/A
      this.dataEgresos.forEach((data) => {
        if (data['numeroCuota'] == null) {
          data.numeroCuota = this.result
        }
        if (data['tipoPago'] == null) {
          data.tipoPago = this.result
        }
        if (data['proyecto'] == null) {
          data.proyecto = this.result
        }
        if (data['regiones'] == null) {
          data.region = this.result
        }
        if (data['grupoGastos'] == null) {
          data.grupoGastos = this.result
        }
      })
      this.dataSource = new MatTableDataSource(this.dataEgresos)
      this.dataSource.paginator = this.paginator.toArray()[0]
      this.dataSource.sort = this.sort
    })
    this.rolUsuario = this.usuario.rol
  }

  openDialogRegistrarPago() {
    //Selecciona los valores de la fila seleccionada
    this.selectedRows = []
    this.selection.selected.forEach((x) => {
      this.selectedRows.push(x)
    })
    if (this.selectedRows.length > 0) {
      this.selectedRows.forEach((x) => {
        localStorage.setItem('idEgresoPago', x.id)
        localStorage.setItem('numeroCuota', x.numeroCuota)
      })
      //Se ejecuta el metodo que abre el dialog, enviandole le id del Egreso por cuota
      let idEgresoPagoCuota = localStorage.getItem('idEgresoPago')
      let valorNumeroC = localStorage.getItem('numeroCuota')
      if (valorNumeroC != 'N/A') {
        this.hostalService.openDialogRegistrarPago(idEgresoPagoCuota)
      } else {
        this.snackBar.open(
          'Por favor seleccione un egreso con cuotas sin pagar',
          'cerrar',
          {
            duration: 2000,
            verticalPosition: 'top'
          }
        )
      }
    } else {
      this.snackBar.open(
        'Por favor seleccione un egreso con cuotas',
        'cerrar',
        {
          duration: 2000,
          verticalPosition: 'top'
        }
      )
    }
  }

  recuperarArchivos(listArchivos: any) {
    setTimeout(() => {
      this.dialog.open(DialogShow, {
        data: { archivos: listArchivos, servicio: 'hostal-egreso' }
      })
    }, 1000)
  }

  revelarTotal() {
    this.totalSeleccion = 0
    this.selection.selected.forEach((data) => {
      this.totalSeleccion += data.monto
    })
  }

  aplicarfiltros() {
    this.formFilter.valueChanges.subscribe((res) => {
      const { id, monto, responsable } = res
      let dataFiltered = this.dataEgresos

     if (responsable) {
     dataFiltered = dataFiltered.filter((data: EgresoHostal) => {
       const responsableData = data.responsable
         ? data.responsable.toLocaleLowerCase()
         : ''
       return responsableData.includes(responsable.toLowerCase())
     })
      }
      if (id) {
        dataFiltered = dataFiltered.filter((data: EgresoHostal) =>
          data.id.toString().includes(id)
        )
      }
      if (monto) {
        dataFiltered = dataFiltered.filter((data: EgresoHostal) =>
          data.monto.toString().includes(monto)
        )
      }

//       if (res.responsable) {

//  dataFiltered = dataFiltered.filter((data: EgresoHostal) =>
//           data.responsable.toString().toLocaleLowerCase().includes(res.responsable.toLocaleLowerCase())
//         )
//       }

      if (res.descripcion) {
        dataFiltered = dataFiltered.filter((data: EgresoHostal) =>
          data.descripcion.toString().toLocaleLowerCase().includes(res.descripcion.toLocaleLowerCase())
        )
      }

      if (res.idSucursal) {
        dataFiltered = dataFiltered.filter(
          (data: EgresoHostal) => data.Sucursal.id == res.idSucursal
        )
      }

      if (res.tipoEgreso) {
        dataFiltered = dataFiltered.filter(
          (data: EgresoHostal) => data.tipoEgreso == res.tipoEgreso
        )
      }

      if (res.tipoPago) {
        dataFiltered = dataFiltered.filter(
          (data: EgresoHostal) => data.tipoPago == res.tipoPago
        )
      }
      if (res.gastosFijos) {
        dataFiltered = dataFiltered.filter(
          (data: EgresoHostal) => data.gastosFijos == res.gastosFijos
        )
      }
      if (res.idUsuario) {
        dataFiltered = dataFiltered.filter(
          (data: EgresoHostal) => data.idUsuario == res.idUsuario
        )
      }

      if (res.proyecto) {
        dataFiltered = dataFiltered.filter(
          (data: EgresoHostal) => data.proyecto == res.proyecto
        )
      }

      if (res.region) {
        dataFiltered = dataFiltered.filter(
          (data: EgresoHostal) => data.region == res.region
        )
      }

      if (res.numeroCuota) {
        dataFiltered = dataFiltered.filter(
          (data: EgresoHostal) => data.numeroCuota == res.numeroCuota
        )
      }

      if (res.start && res.end) {
        dataFiltered = dataFiltered.filter(
          (data: EgresoHostal) =>
            new Date(data.fecha) >= res.start && new Date(data.fecha) <= res.end
        )
      }

      if (res.monto) {
        dataFiltered = dataFiltered.filter(
          (data: EgresoHostal) => data.monto == res.monto
        )
      }

      if (res.grupoGastos) {
        dataFiltered = dataFiltered.filter(
          (data: EgresoHostal) => data.grupoGastos == res.grupoGastos
        )
      }

      this.dataSource = new MatTableDataSource(dataFiltered)
      this.dataSource.paginator = this.paginator.toArray()[0]
      this.dataSource.sort = this.sort
      this.selection.clear()
    })
  }

  limpiarFiltros() {
    this.formFilter.patchValue({
      start: null,
      end: null,
      idSucursal: null,
      tipoEgreso: null,
      monto: null,
      idUsuario: null,
      responsable: null,
      descripcion: null,
      tipoPago : null 
    })
    this.dataSource = new MatTableDataSource(this.dataEgresos)
    this.dataSource.paginator = this.paginator.toArray()[0]
    this.dataSource.sort = this.sort
    this.dataSource.paginator['_pageIndex'] = 0
    this.actualizarTabla()
    this.selection.clear()
    this.totalSeleccion = 0
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length
    const numRows = this.dataSource.data.length
    return numSelected === numRows
  }

  masterToggle() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.filteredData.forEach((row) => {
          this.selection.select(row)
        })
  }

  //Excell
  selectedColumns: string[] = ['id','tipoEgreso','fecha','monto','responsable','descripcion','tipoPago',
  'idUsuario','idSucursal'];

  exportAsXLSX(): void {
    this.selectedRows = []
    if (this.selection.selected.length == 0) {
      this.snackBar.open('!Seleccione algún registro!', 'cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
    } else {
      this.selection.selected.forEach((x) => this.selectedRows.push(x))
      const newArray = this.selectedRows.map((item) => {
        const { RespaldoEgresos, Usuario, Sucursal, ...newObject } = item
        return newObject
      })

      console.log({newArray});
      console.log("este es: ",this.selectedColumns);

      this.hostalService.exportAsExcelFile(newArray, 'Lista-Egresos-Hostal',this.selectedColumns)
    }
  }

  //Botones de acciones 🚒🚒🚒🚒🚒🚒🚒🚒🚒
  accionIngresoInmobiliaria(id: any) {
    localStorage.setItem('idEgresoHostal', id)
    this.hostalService.openDialogEditEgresoHostal()
  }

  downloadImage(id: any) {
    localStorage.setItem('idEgresoHostal', id)
    console.log(id)
    this.http
      .get(
        `${environment.apiUrl}/egresoHostal/Upload/download-image/${id}`, 
        { responseType: 'blob'}
      )
      .subscribe((response) => {
        const url = URL.createObjectURL(response)
        fetch(url)
          .then((res) => res.blob())
          .then((blob) => {
            saveAs(blob)
          })
      })
      // this.hostalService.buscarImagenC(id).subscribe(
      //   (dataImagen: any) => {                  
      //     setTimeout(() => {
      //       this.dialog.open(DialogShow, {
      //         data: { archivos: dataImagen, servicio: 'hostal-egreso' },
      //       });
      //     }, 1000);       
      //   },
      //   (error: any) => {
      //     console.log(error);
      //   }
      // );
  }
  deleteEgreso(id: number): void {
    if (confirm('Seguro que deseas eliminar el Registro!!')) {
      console.log(id)
      this.hostalService.deleteEgreso(id).subscribe(
        (res) => {
          this.snackBar.open('Egreso eliminado', 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
          this.limpiarFiltros()
        },
        (error) => {
          this.snackBar.open(
            'No se pudo editar la hostal, favor contactar a informática.',
            'Cerrar',
            {
              duration: 2000,
              verticalPosition: 'top'
            }
          )
        }
      )
    }
  }
}
