import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { AjusteInventario } from '@app/_models/ajusteInventario/ajusteInventario';
import { AjusteProducto } from '@app/_models/ajusteProducto/ajusteProducto';
import { Bodega } from '@app/_models/inventario/bodega/bodega';
import { modeloProveedor } from '@app/_models/proveedores/proveedores';
import { AjusteProductoService } from '@app/_pages/ajusteProducto/ajusteProducto.service';
import { BodegaService } from '@app/_pages/inventario/bodega/bodega.service';
import { ProveedoresService } from '@app/_pages/proveedores/proveedores.service';
import { first } from 'rxjs/operators';
import { AjusteInventarioService } from '../ajusteInventario.service';

@Component({
  selector: 'app-ajuste-inventario-list',
  templateUrl: './ajuste-inventario-list.component.html',
  styleUrls: ['./ajuste-inventario-list.component.scss']
})
export class AjusteInventarioListComponent implements OnInit {

  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();

  displayedColumns: string[] = [
    'select',
    'tipo',
    'fecha',
    'bodega',
    'proveedor',
    'documento',
    'producto',
    'botones',
  ];

  addressForm = this.fb.group({
    estado: 'anulado',

  });

  selection = new SelectionModel<AjusteInventario>(true, [])
  totalSeleccion = 0;
  bodegas: Bodega[] = [];
  ajusteInventario : AjusteInventario[] = [];
  proveedores: modeloProveedor[] = [];
  dataNuevo : any;
  idProducto: any;
  estado : any;
  selectedRows!: any[];
  ajusteProducto : AjusteProducto[] = [];
idNuevo : any;
  conteo : any;
  nose : any;


  formFilter = new FormGroup({
    tipo: new FormControl(),
    fecha: new FormControl(),
    bodega: new FormControl(),
    proveedor: new FormControl(),
    documento: new FormControl(),
    productos: new FormControl(),
    botones: new FormControl(),
    })

  dataSource: MatTableDataSource<AjusteInventario> = new MatTableDataSource();
  dataAjusteInventario: AjusteInventario[] = [];

  constructor(
    private bodegasService: BodegaService,
    private proveedoresService: ProveedoresService,
    private ajusteInventarioService: AjusteInventarioService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private ajusteProductoService: AjusteProductoService
  ) { }

  ngOnInit(): void {
    this.getBodegas();
    this.getProveedores();
    this.getAjusteInventario();
    this.aplicarfiltros();
  }


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.filteredData.forEach((row) => {
          this.selection.select(row)
        });
  }

  getBodegas(){
    this.bodegasService
    .bodegasGetAll()
    .pipe(first())
    .subscribe((bodegas) => {
      this.bodegas = bodegas;
    });
  }

  getProveedores(){
    this.proveedoresService
    .getAllProveedores()
    .pipe(first())
    .subscribe((proveedores) => {
      this.proveedores = proveedores;
    });
  }



      //Abrir Modal Editar
    openDialogVer(id: any) {
      localStorage.setItem('idAjusteInventario', id);

      this.ajusteInventarioService.openDialogVerAjusteInventario();
    }

  getAjusteInventario() {
    //Carga Tabla
    this.ajusteInventarioService.ajusteInventarioGet().pipe(first()).subscribe((result: AjusteInventario[]) => {
        this.dataAjusteInventario = result.map((AjusteInventario) => {

          this.dataNuevo = this.dataAjusteInventario

          this.ajusteProductoService.traerNumeroProductos(AjusteInventario.id).subscribe((res: any) => {
            this.dataNuevo= res
            this.conteo = this.dataNuevo[0].consultaProductos
            AjusteInventario.consulta = this.conteo
            //console.log("este es el id:",this.nose[i].id, "y este es el valor del contador",this.conteo)
          })


          this.bodegas.forEach((bodegas) => {
            //console.log(Producto.idCategoria, "  -  " , categorias.id);
            if (AjusteInventario.idBodega == bodegas.id) {
               AjusteInventario.nombreBodega = bodegas.nombre;

            }
          })
          this.proveedores.forEach((proveedores) => {
            //console.log(Producto.idCategoria, "  -  " , categorias.id);
            if (AjusteInventario.idProveedor == proveedores.id) {
               AjusteInventario.nombreProveedor = proveedores.nombre;

            }
          })

        return AjusteInventario;
      });
        this.dataSource = new MatTableDataSource(this.dataAjusteInventario);
        //console.log(this.dataSource)
        this.dataSource.paginator = this.paginator.toArray()[0];
      });
  }


          //Metodo cambiar un estado
          cambiarEstado(id : number) {


            this.ajusteInventarioService.ajusteInventarioGetId(id).pipe(first()).subscribe((dataAjusteInventario) => {
                this.dataNuevo = dataAjusteInventario;
                this.idProducto = this.dataNuevo.id;
                console.log(this.idProducto);



                if (confirm('Esta seguro que desea cambiar el estado de este ajuste? ')) {
                this.ajusteInventarioService
                .updateAjusteInventario(this.idProducto, this.addressForm.value)
                .pipe(first())
                .subscribe(
                  (data) => {
                    this.snackBar.open('Estado modificado con exito', 'cerrar', {
                    duration: 2000,
                    verticalPosition: 'top',
                  });

                  },

                  (error) => {
                    this.snackBar.open(error, 'cerrar', {
                    duration: 2000,
                    verticalPosition: 'top',
                  });
                  }
                );
                }
              });

        }

        exportAsXLSX(): void {
          this.selectedRows = [];
           this.selection.selected.forEach((x) => this.selectedRows.push(x));
           this.ajusteInventarioService.exportAsExcelFile(this.selectedRows, 'Lista-AjusteInventario');
         }


         aplicarfiltros() {
          this.formFilter.valueChanges.subscribe(res => {

          let dataFiltered = this.dataAjusteInventario;

          //Filtro Tipo
          if (res.tipo) {
              dataFiltered = dataFiltered.filter((data: AjusteInventario) => data.tipoAjuste == res.tipo);
          }

          //Filtro Bodega
          if (res.bodega) {
            dataFiltered = dataFiltered.filter((data: AjusteInventario) => data.nombreBodega== res.bodega);
          }

          //Filtro Proveedor
          if (res.proveedor) {
            dataFiltered = dataFiltered.filter((data: AjusteInventario) => data.nombreProveedor== res.proveedor);
          }


            this.dataSource = new MatTableDataSource(dataFiltered);
            this.dataSource.paginator = this.paginator.toArray()[0];
            this.selection.clear()
            this.totalSeleccion = 0;
            })
          }

    //Limpiar los filtros
      limpiarFiltros() {
        this.formFilter.patchValue({ tipoAjuste: null})
        this.formFilter.patchValue({ bodega: null})
        this.formFilter.patchValue({ proveedor: null})
        this.dataSource = new MatTableDataSource(this.dataAjusteInventario);
        this.dataSource.paginator = this.paginator.toArray()[0];
        this.selection.clear()
        this.totalSeleccion = 0;
      }

}
