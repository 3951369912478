import { SelectionModel } from '@angular/cdk/collections'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import {
  Component,
  Input,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core'
import { AbogadosTabsService } from '../../../abogados-tabs.service'
import { MatDialog } from '@angular/material/dialog'
import { FormControl, FormGroup } from '@angular/forms'
import { Sucursal } from '@app/_models/shared/sucursal'
import { Cliente } from '@app/_models/shared/cliente'
import { Contrato } from '../../../../../_models/abogados/contrato'
import { AbogadosIngresosAccionesComponent } from '../abogados-ingresos-acciones.component'
import { Empresa } from '@app/_models/shared/empresa'
import { EmpresaSharedService } from '@app/_pages/shared/shared-services/empresa-shared.service'
import { first } from 'rxjs/operators'
import { AbogadosService } from '../../../abogados.service'
import { environment } from '@environments/environment'
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-dialog-mostrar-contratos',
  templateUrl: './dialog-mostrar-contratos.component.html',
  styleUrls: ['./dialog-mostrar-contratos.component.scss']
})
export class DialogMostrarContratosComponent implements OnInit {
  // ? childrens
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>()
  nombreClienteLocal = localStorage.getItem('nombreCliente')
  idCliente = localStorage.getItem('idCliente')

  idEmpresa = 2
  empresa = new Empresa()
  contratoR: any
  idContato: any
  pdfUrl: any

  // ? table definitions.
  displayedColumns: string[] = [
    'select',
    'id',
    'rut',
    'fecha',
    'nContrato',
    'monto',
    'saldo',
    'estadoPago',
    'sucursal',
    'usuario',
    'acciones'
  ]
  dataSource: MatTableDataSource<Contrato> = new MatTableDataSource()
  dataContrato: Contrato[] = []

  formFilter = new FormGroup({
    rut: new FormControl(),
    start: new FormControl(),
    end: new FormControl(),
    nContrato: new FormControl(),
    estadoPago: new FormControl(),
    sucursal: new FormControl(),
    usuario: new FormControl()
  })
  selection = new SelectionModel<any>(true, [])
  totalSeleccion = 0
  selectedRows!: any[]

  constructor(
    private abogadosAcciones: AbogadosIngresosAccionesComponent,
    private abogadosTabsService: AbogadosTabsService,
    private empresaService: EmpresaSharedService,
    private abogadosService: AbogadosService,
    private snackBar: MatSnackBar
  ) {}

  //Metodos que se ejecutan al iniciar el componente
  ngOnInit(): void {
    this.getContratosCliente()
    this.aplicarfiltros()
    this.obtenerEmpresa(this.idEmpresa)
  }

  
  getContratosCliente() {
    // Carga Tabla
    this.abogadosTabsService
      .obtenerContratosCliente(this.idCliente)
      .subscribe((result: Contrato[]) => {
        this.dataContrato = result
          .map((Contrato) => {
            Contrato.sucursal = Contrato.Sucursal.razonSocial;
            Contrato.usuario = Contrato.Usuario.nombreUsuario;
  
            return Contrato;
          })
          // Asegurar que ID sea un número y ordenar en orden descendente
          .sort((a, b) => Number(b.id) - Number(a.id));
  
        this.dataSource = new MatTableDataSource(this.dataContrato);
        this.dataSource.paginator = this.paginator.toArray()[0];
      });
  }

  // Ocultar la tabla de los contratos del cliente
  desactivarTablaContratos() {
    this.abogadosAcciones.desactivarTabContratos()

    localStorage.removeItem('nombreCliente')
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length
    const numRows = this.dataSource.data.length
    return numSelected === numRows
  }

  masterToggle() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.filteredData.forEach((row) => {
          this.selection.select(row)
        })
  }

  aplicarfiltros() {
    this.formFilter.valueChanges.subscribe((res) => {
      let dataFiltered = this.dataContrato

      //Filtro N° Contrato
      if (res.nContrato) {
        dataFiltered = dataFiltered.filter(
          (data: Contrato) => data.nContrato == res.nContrato
        )
      }

      //Filtro Fecha
      if (res.start && res.end) {
        dataFiltered = dataFiltered.filter(
          (data: Contrato) =>
            new Date(data.fechaContrato) >= res.start &&
            new Date(data.fechaContrato) <= res.end
        )
      }

      //Filtro Estado Pago
      if (res.estadoPago) {
        dataFiltered = dataFiltered.filter(
          (data: Contrato) => data.estadoPago == res.estadoPago
        )
      }

      //Filtro Sucursal
      if (res.sucursal) {
        dataFiltered = dataFiltered.filter(
          (data: Contrato) => data.sucursal == res.sucursal
        )
      }

      //Filtro Usuario
      if (res.usuario) {
        dataFiltered = dataFiltered.filter(
          (data: Contrato) => data.usuario == res.usuario
        )
      }

      this.dataSource = new MatTableDataSource(dataFiltered)
      this.dataSource.paginator = this.paginator.toArray()[0]
      this.totalSeleccion = 0
      this.selection.clear()
    })
  }

  limpiarFiltros() {
    this.formFilter.patchValue({
      start: null,
      end: null,
      sucursal: null,
      usuario: null,
      estadoPago: null,
      nContrato: null
    })
    this.dataSource = new MatTableDataSource(this.dataContrato)
    this.dataSource.paginator = this.paginator.toArray()[0]
    this.selection.clear()
    this.totalSeleccion = 0
  }

  obtenerEmpresa(id: number): any {
    this.empresaService
      .getByIdWithSucursales(id)
      .pipe(first())
      .subscribe((x: any) => {
        x.Sucursals = Object.values(x.Sucursals)

        this.empresa = x
      })
  }

  // Metodo exportar excel
  // exportAsXLSX(): void {
  //   this.selectedRows = []
  //   this.selection.selected.forEach((x) => this.selectedRows.push(x))
  //   this.abogadosTabsService.exportAsExcelFile(
  //     this.selectedRows,
  //     'Lista-Contratos-Cliente'
  //   )
   exportAsXLSX(): void {
    this.selectedRows = [];
    if(this.selection.selected.length == 0) {
      this.snackBar.open('!Seleccione algún registro!', 'cerrar', {
        duration: 2000,
        verticalPosition: 'top',
      });
    } else {
      this.selection.selected.forEach((x) => this.selectedRows.push(x));
        const newArray = this.selectedRows.map((item) => {
          const { razonSocial, idComuna, idCiudad, idEmpresa, giro, Causas, Sucursal, Usuario, fechaContrato, ...newObject } = item;
          
          // Supongamos que 'razonSocial' es el nombre del cliente
          const clienteNombre = this.nombreClienteLocal
          // Formatear 'fechaContrato'
          const fechaContratoFormateada = new Date(fechaContrato).toLocaleString('es-ES', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
          });

          // Agregamos el nombre del cliente y la fecha formateada a newObject
          return {
            ...newObject,
            clienteNombre,                 // Añadimos 'clienteNombre'
            fechaContrato: fechaContratoFormateada  // Añadimos 'fechaContrato' formateada
          };
        });

        console.log(newArray);
    this.abogadosTabsService.exportAsExcelFile(newArray, 'Lista-Contratos-Cliente');

    }
  }

  // Abrir Ventana Modal Registrar Pago
  openDialogRegistrarPago() {
    //Selecciona los valores de la fila seleccionada
    this.selectedRows = []
    this.selection.selected.forEach((x) => this.selectedRows.push(x))
    this.selectedRows.forEach((x) => {
      localStorage.setItem('idContratoPago', x.id)
    })
    //Se ejecuta el metodo que abre el dialog, enviandole le id del contrato
    let idContrato = localStorage.getItem('idContratoPago')
    this.abogadosService.openDialogRegistrarPago(idContrato)
  }

  // Abrir Ventana Modal Repactar Cuotas
  openDialogRepactarCuotas() {
    //Selecciona los valores de la fila seleccionada
    this.selectedRows = []
    this.selection.selected.forEach((x) => this.selectedRows.push(x))
    this.selectedRows.forEach((x) => {
      localStorage.setItem('idContratoPago', x.id)
    })
    //Se ejecuta el metodo que abre el dialog, enviandole le id del contrato
    let idContato = localStorage.getItem('idContratoPago')
    this.abogadosService.openDialogRepactarCuotas(idContato)
  }
  accionIngresoAbogado(id: any) {
    localStorage.setItem('idContratoPago', id)
    console.log(id)
    this.abogadosService.openDialogEditIngresoAbogadocontratoB(true);
  }
  convertirRutaEnHttp(rutaArchivo: string): string {
    const baseUrl = `${environment.pathApiRent}`;
    const rutaRelativa = rutaArchivo.replace('/Users/grupofirma/Documents/GitHub/', '');  // Ajusta la ruta según sea necesario
    return `${baseUrl}${rutaRelativa}`;
  }
  descargarContrato(id: any) {
    localStorage.setItem('idContratoPago', id)
    this.idContato=id
    this.abogadosTabsService.obtenerContratosClienteID(this.idContato)
      .subscribe((contrato: any) => {
        if (contrato && contrato.file) {
          this.pdfUrl = contrato.file;
          this.pdfUrl = this.convertirRutaEnHttp(contrato.file);
          this.iniciarDescarga(this.pdfUrl);
        } else {
          console.error('No se encontró el archivo para el contrato');
        }
      }, error => {
        console.error('Error al obtener el contrato:', error);
      });
  }

  iniciarDescarga(pdfUrl: string) {
    window.open(pdfUrl, '_blank', 'width=800,height=600');
    // const nombreArchivo = pdfUrl.split('/uploads/').pop() || 'archivo.pdf';
  
    // const link = document.createElement('a');
    // link.href = pdfUrl;
    // link.download = nombreArchivo;
    // link.target = '_blank';  // Abrir en una nueva pestaña
  
    // const newWindow = window.open();
    // newWindow.document.body.appendChild(link);
    // link.click();
    
  }
}
  

