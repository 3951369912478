import { Component, EventEmitter, OnInit } from '@angular/core'
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Producto } from '@app/_models/productos/producto'
import { Variante } from '@app/_models/variante/variante'
import { first } from 'rxjs/operators'
import { CategoriasService } from '../../categorias/categorias.service'
import { ProductosService } from '../productos.service'
import { VariantesService } from '../../variantes/variantes.service'

@Component({
  selector: 'app-productos-form',
  templateUrl: './productos-form.component.html',
  styleUrls: ['./productos-form.component.scss']
})
export class ProductosFormComponent implements OnInit {
  formularioListo = new EventEmitter<string>()

  producto: Producto = new Producto()
  variante: Variante = new Variante()

   afectoExcento : boolean 

  categorias: any
  form!: FormGroup
  idProducto: number = 0
  dataProducto: any
  nuevo: any
  montoTotal: number = 0
  iva: number = 0
  montoNeto: number = 0
  unitarioMargen: number = 0
  gananciaMargen: number = 0
  porcentajeMargen: number = 0
  total: number = 0

  estaAfecto: boolean = false;

  

  addressForm = this.fb.group({
    tieneiva: true,
    cosa: false,
    tipo: ['', Validators.required],
    codigoSKU: ['', Validators.required],
    nombre: ['', Validators.required],
    idCategoria: ['', Validators.required],
    moneda: ['', Validators.required],
    totall: [''],
    unitario: [],
    ganancia: [],
    neto: [],
    venta: [],
    estado: ['', Validators.required],

    afecto: false//>>

  })

  addressForm2 = this.fb.group({
    variantes: this.fb.array([])
  })

  get variantes() {
    return this.addressForm2.get('variantes') as FormArray
  }

  isEditable = false

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private categoriasService: CategoriasService,
    private productosService: ProductosService,
    private variantesService: VariantesService
  ) {}

  ngOnInit(): void {
    this.getCategorias()
  }

  isShown: boolean = false // hidden by default
  isShown2: boolean = true // hidden by default

  mostrarFormulario() {
    if (this.addressForm.value.cosa == false) {
      this.isShown = !this.isShown
    }
    if (this.addressForm.value.cosa == true) {
      this.isShown = !this.isShown
    }
  }

  calcularMontos() {
    this.montoTotal = this.addressForm.controls.neto.value
    this.montoTotal.toFixed()
  }

  calcularGanancia() {
    this.unitarioMargen = this.addressForm.controls.unitario.value
    this.porcentajeMargen = this.addressForm.controls.ganancia.value / 100
    this.gananciaMargen = this.unitarioMargen / (1 - this.porcentajeMargen)

    this.montoNeto = this.addressForm.controls.neto.value
    this.iva = this.gananciaMargen * 0.19
    
    this.montoTotal.toFixed() 
    this.gananciaMargen.toFixed()

    this.producto.neto = this.gananciaMargen
    this.producto.unitario = this.unitarioMargen
    this.producto.venta = this.montoTotal
    this.producto.venta = this.producto.neto
    this.producto.ganancia = this.porcentajeMargen
    

   
  }



  afecto() {
     if (this.addressForm.value.afecto === false || this.addressForm.value.ganancia) {
      //this.gananciaMargen = this.montoTotal;
      this.iva = this.gananciaMargen * 0.19
      this.montoTotal = this.gananciaMargen + this.iva

      //this.montoTotal = this.producto.unitario + this.iva;
      this.gananciaMargen = this.producto.unitario +this.iva
      console.log('afecto');

      this.iva = this.addressForm.value.venta ? this.montoTotal * 0.19 : 0;
    this.calcularGanancia();
    }
    if (this.addressForm.value.afecto === true && this.addressForm.value.unitario) {
      this.montoTotal = this.producto.neto;
      console.log('excento');
    } 
  }






  



  calcularUnitario() {
    
    this.unitarioMargen = this.addressForm.controls.unitario.value
    this.gananciaMargen = this.unitarioMargen

    this.iva = this.gananciaMargen * 0.19
    this.montoTotal = this.gananciaMargen
    this.montoTotal.toFixed()
    this.gananciaMargen.toFixed()

    this.producto.neto = this.gananciaMargen
    this.producto.unitario = this.unitarioMargen
    this.producto.venta = this.montoTotal

    if (this.addressForm.controls.ganancia.value > 0) {
      this.calcularGanancia()
    }
  }

  getCategorias() {
    this.categoriasService
      .categoriaGet()
      .pipe(first())
      .subscribe((categorias) => {
        this.categorias = categorias
      })
  }

  agregarVariante() {
    this.productosService
      .productoGet()
      .pipe(first())
      .subscribe((result: Producto[]) => {
        this.dataProducto = result.map((Producto) => {
          return Producto
        })

        this.idProducto = this.dataProducto.pop().id
        this.total = this.idProducto + 1
        console.log('ultimo valor   ', this.total)
        //this.idProducto = this.dataProducto[this.dataProducto.length - 1].id;

        const varianteForm = this.fb.group({
          nombreVariante: [''],
          codigoSKUVariante: [''],
          unitarioVariante: [],
          netoVariante: [],
          estadoVariante: [''],
          descripcionVariante: [''],
          idProducto: [this.total],
        })
        this.variantes.push(varianteForm)
      })
  }

  eliminarVariante(indice: number) {
    this.variantes.removeAt(indice)
  }

  onSubmit() {
    // this.form = this.fb.group(
    //   Object.assign(
    //     this.addressForm.value,
    //     this.addressForm2.value,
    //   )
    // )
    switch (this.addressForm.status) {
      case 'VALID':
        this.calcularGanancia()
        this.calcularUnitario()
        //Obtener datos de productos
        
        this.producto.tipo = this.addressForm.value.tipo
        this.producto.codigoSKU = this.addressForm.value.codigoSKU
        this.producto.nombre = this.addressForm.value.nombre
        this.producto.idCategoria = this.addressForm.value.idCategoria
        this.producto.moneda = this.addressForm.value.moneda
        //this.producto.unitario = this.addressForm.value.unitario;
        this.producto.ganancia = this.addressForm.value.ganancia
        //this.producto.neto = this.addressForm.value.neto;
        //this.producto.venta = this.addressForm.value.venta;
        this.producto.estado = this.addressForm.value.estado

        this.producto.afecto = this.addressForm.value.afecto//>>
        console.log("este es el afecto",this.afectoExcento)
         console.log(this.producto);

         this.productosService
          .create(this.producto)
          .pipe()
          .subscribe(
            (data) => {
              this.snackBar.open('Producto ingresado con exito', 'cerrar', {
                duration: 2000,
                verticalPosition: 'top'
              })
              this.addressForm.reset()
            },
            (error) => {
              this.snackBar.open(
                'No se pudo ingresar la sucursal, contacte con informatica',
                'cerrar',
                {
                  duration: 2000,
                  verticalPosition: 'top'
                }
              )
            }
          ) 

        //   if(this.isShown == true)
        //   {
        //     //Obtener datos de variantes
        //     for (let i = 0; i < this.variantes.value.length; i++)
        // {
        //   this.variante.nombreVariante = this.variantes.value[i].nombreVariante;
        //     this.variante.codigoSKUVariante = this.variantes.value[i].codigoSKUVariante;
        //     this.variante.unitarioVariante = this.variantes.value[i].unitarioVariante;
        //     this.variante.netoVariante = this.variantes.value[i].netoVariante;
        //     this.variante.estadoVariante = this.variantes.value[i].estadoVariante;
        //     this.variante.descripcionVariante = this.variantes.value[i].descripcionVariante;
        //     this.variante.stockVariante = this.variantes.value[i].stockVariante;
        //     this.variante.idProducto = this.variantes.value[i].idProducto;

        //     this.variantesService
        //   .createVariante(this.variante)
        //   .pipe()
        //   .subscribe(
        //     (data) => {
        //       this.snackBar.open('Variante ingresado con exito', 'cerrar', {
        //         duration: 2000,
        //         verticalPosition: 'top',
        //       });
        //       this.addressForm2.reset();
        //     },
        //     (error) => {
        //     this.snackBar.open('No se pudo ingresar la variante, contacte con informatica', 'cerrar', {
        //       duration: 2000,
        //       verticalPosition: 'top',
        //     });

        //   }
        //   );
        //   }
        // }
        break
      //Si el formulario es erroneo
      case 'INVALID':
        this.snackBar.open('EL formulario debe ser Completado', 'cerrar', {
          duration: 2000,
          verticalPosition: 'top'
        })
        break

      default:
        break
    }
  }
}
