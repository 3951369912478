import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { NgSelectConfig } from '@ng-select/ng-select';
import { BodegaService } from '../../bodega/bodega.service';
import { TransferenciasService } from '../transferencias.service';
import { Usuario } from '@app/_models/shared/usuario';
import { Stock } from '@app/_models';
import Swal from 'sweetalert2';
import { ProductosService } from '../../../productos/productos.service';
import { TransferenciaBodega } from '../../../../_models/inventario/transferencia/transferenciaBodega';
import { DetalleTransferenciaService } from '../detalleTransferencia/detalle-transferencia.service';
import { DetalleTransferencia } from '../../../../_models/inventario/transferencia/DetalleTransferencia';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-transferencia-bodega-form',
  templateUrl: './transferencia-bodega-form.component.html',
  styleUrls: ['./transferencia-bodega-form.component.scss']
})

export class TransferenciaBodegaFormComponent implements OnInit {
  events: string[] = [];
  startDate = new Date();
  bodegas: any[] = [];
  stock: Stock | undefined;
  usuario: Usuario = JSON.parse(localStorage.getItem('usuario') + '');
  btnEnviar: boolean = true;
  stockProducto: any;
  cantidadInicial: any;
  nuevoStockActual: any;
  nuevoStockDestino: any;
  cantidadInicialDestino: any;

  idTransferencia: number;

  lista_productos: any;
  productosDisponibles: any[] = [];

  transferencia: TransferenciaBodega = new TransferenciaBodega()
  detalleTransferencia: DetalleTransferencia = new DetalleTransferencia()

  constructor(
    private fb: FormBuilder,
    private BodegaService: BodegaService,
    private TransferenciasService: TransferenciasService,
    private config: NgSelectConfig,
    private snackBar: MatSnackBar,
    private productosService : ProductosService,
    private detalleTransferenciaService : DetalleTransferenciaService
  ) {
    // select
    this.config.notFoundText = 'No se encontraron resultados';
    this.config.appendTo = 'body';
    this.config.bindValue = 'value';
  }

  ngOnInit(): void {
    this.getBodegas()
    this.createProductoForm()
    this.getProducts()
  }

  getBodegas() {
    this.BodegaService
      .GetBodegas()
      .pipe(first())
      .subscribe((bodegas: []) => {
        this.bodegas = bodegas;
      });
  }

  getProducts() {
    this.productosService.productoGet().subscribe(
      (res) => {
        this.lista_productos = res;
        this.productosDisponibles = [...this.lista_productos];
        console.log(this.lista_productos)
      }
    );
  }


  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events.push(`${type}: ${event.value}`);
  }

  /*
  //CONSULTA LOS PRODUCTOS QUE HAY EN ESA BODEGA
  getProductoStockBodega(idStock: any) {
    this.TransferenciasService
      .getProductoStockBodega(idStock)
      .pipe(first())
      .subscribe((productos: []) => {
        this.productos = productos;
      });
  }
  */

  // el codigo de form array 

  /*
  obtenerProductosBodega($event: any) {
    //this.getProductoStockBodega($event)
    const refTransferencia = this.formParent.get('DetalleTransferencia') as FormArray;
    refTransferencia.clear()
  }
  */
  getProductoStock(idBodega: any, idProductoServicio: any) {
    this.TransferenciasService
      .getProductoStock(idBodega, idProductoServicio)
      .pipe(first())
      .subscribe((stock) => {
        this.stockProducto = stock.cantidad;
      });
  }
  /*
  detalleProducto(id: any, formChild: number) {
    const refTransferencia = this.formParent.get('DetalleTransferencia') as FormArray;
    const items = (<FormArray>this.formParent.get('DetalleTransferencia'));
    const idProductoServicio = refTransferencia.value[0].idProductoServicio;
    const idBodega = this.formParent.value.bodegaInicio;
    //this.getProductoStock(idBodega, idProductoServicio)
    this.TransferenciasService
      .getProductoStock(idBodega, idProductoServicio)
      .pipe(first())
      .subscribe((stock) => {
        this.stockProducto = stock.cantidad;
        for (let index = 0; index < this.bodegas.length; index++) {
          if (this.formParent.value.bodegaInicio == this.bodegas[index][1]) {
            for (let i = 0; i < items.length; i++) {
              refTransferencia.at(i).patchValue(
                {
                  ubicacion: this.bodegas[index][0],
                  cantidad_inicial: this.stockProducto,
                }
              );
            }
          }

          if (this.formParent.value.bodegaDestino == this.bodegas[index][1]) {
            for (let i = 0; i < items.length; i++) {
              refTransferencia.at(i).patchValue(
                {
                  ubicacion_destino: this.bodegas[index][0],
                  idBodega: this.bodegas[index][1]
                }
              );
            }
          }
        }
      });
  }
  */

  /*
  public formParent: FormGroup = new FormGroup({});
  initFormParent(): void {
    this.formParent = new FormGroup(
      {
        fechaTransferencia: new FormControl(new Date(), [Validators.required]),
        observacion: new FormControl(''),
        bodegaInicio: new FormControl('', Validators.required),
        bodegaDestino: new FormControl('', Validators.required),
        idUsuario: new FormControl(''),
        idBodega: new FormControl(''),
        DetalleTransferencia: new FormArray([], [Validators.required])
      }
    )
  }
  */

  addressForm = this.fb.group({

    fechaTransferencia: ['', Validators.required],
    observacion: ['', Validators.required],
    bodegaInicio: ['', Validators.required],
    bodegaDestino: ['', Validators.required],
    estado: [''],
    idUsuario: [''],
    idBodega: [''],
  });

  addressForm2 = this.fb.group({
    productos: this.fb.array([])
  });

  get productos() {
    return this.addressForm2.get('productos') as FormArray;
  }

  private createProductoForm(): FormGroup {
    return this.fb.group({
      productosSeleccionados: ['', Validators.required],
      idProductoServicio: [''],
      ubicacion: [''],
      cantidad_inicial: [''],
      ubicacion_destino: ['',Validators.required],
      cantidad: ['',Validators.required], 
      productosDisponibles: [...this.productosDisponibles] 
    });
  }

  i: number = 0;


  public UpdateStock = new FormGroup({
    bodegaInicio: new FormControl('', Validators.required),
    bodegaDestino: new FormControl('', Validators.required),
    idProductoServicio: new FormControl('', Validators.required),
    agregarStock: new FormControl('', Validators.required),
    restarStock: new FormControl('', Validators.required),
    cantidad: new FormControl('', Validators.required),
  });

  //TODO: Agregar nuevo detalle transferenc en form 

  /*
  addTransferencia(): void {


    const refTransferencia = this.formParent.get('DetalleTransferencia') as FormArray;
    refTransferencia.push(this.initFormDetalleTransferencia())
  }
  */
  /*
  borrar(index: number) {
    const refTransferencia = this.formParent.get('DetalleTransferencia') as FormArray;
    refTransferencia.removeAt(index)
  }
  */

  //TODO: Obtener referencia a un formControl
  getCtrl(key: string, form: FormGroup): any {
    return form.get(key)
  }

  eliminarProducto(indice: number) {

    this.productos.removeAt(indice);
  
  }

  /*

  cantidad(formChild: number) {

    const refTransferencia = this.formParent.get('DetalleTransferencia') as FormArray;
    const cantidadInicio = refTransferencia.at(formChild);
    const items = (<FormArray>this.formParent.get('DetalleTransferencia'));

    for (let index = 0; index < this.productos[index].length; index++) {
      if (this.productos[0][1][index].id == cantidadInicio.value.idProductoServicio) {
        for (let s = 0; s < this.productos.length; s++) {
          if (cantidadInicio.value.cantidad > this.productos[0][1][index]['Stocks'][s].cantidad || cantidadInicio.value.cantidad < 0) {
            for (let i = 0; i < items.length; i++) {
              refTransferencia.at(formChild).patchValue(
                {
                  cantidad: this.productos['Stocks'][s].cantidad,
                }
              );
            }
          }
        }
      }
    }
  }
  */

  agregarProducto() {
    
    const productoForm = this.createProductoForm();   
    this.productos.push(productoForm);
  }


  verificarCantidadTransferencia(cantidad: number) {
    if (cantidad <= 0) {
      return true
    } else {
      return false;
    }
  }

  validarFormProductos(): boolean {
    for (let i = 0; i < this.productos.value.length; i++) {
      const productoForm = this.productos.controls[i] as FormGroup;
      if (!productoForm.valid) {
        return false;
      }
    }
    return true;
  }

  

  onSubmit() {
  
    if (this.productos.length > 0) {
      console.log("entra mayor a 0")

      if (this.addressForm.valid && this.validarFormProductos()) {

        this.transferencia.estado = 'nose';
        this.transferencia.bodegaInicio = this.addressForm.value.bodegaInicio;
        this.transferencia.bodegaDestino = this.addressForm.value.bodegaDestino;
        this.transferencia.fechaTransferencia = this.addressForm.value.fechaTransferencia;
        this.transferencia.observacion = this.addressForm.value.observacion;
        this.transferencia.idUsuario = this.usuario.id

        this.TransferenciasService
          .create(this.transferencia)
          .pipe()
          .subscribe(
            (data) => {
              //DATA
              const transferencia = data.payload;
              console.log(transferencia)
              //ID_AJUSTE
              this.idTransferencia = transferencia.id;
              //SUCCESS
              console.log("id transferencia",this.idTransferencia)
              console.log(data)
              const success = data.success;

              //INSERCION DE PRODUCTOS
              if (success) {
                console.log("entro al successs")
                for (let i = 0; i < this.productos.value.length; i++) {

                  this.detalleTransferencia.idTransferencia= this.idTransferencia
                  this.detalleTransferencia.idProductoServicio = this.productos.value[i].productosSeleccionados;
                  this.detalleTransferencia.cantidad = this.productos.value[i].cantidad;
                  // this.ajusteProducto.idAjusteInventario = this.productos.value[i].idAjusteInventario;

                  this.detalleTransferenciaService
                    .createDetalleTransferencia(this.detalleTransferencia)
                    .pipe()
                    .subscribe(
                      (data) => {
                        this.snackBar.open('Detalle transferencia ingresado con exito', 'cerrar', {
                          duration: 2000,
                          verticalPosition: 'top',
                        });
                      },
                      (error) => {
                        this.snackBar.open('No se pudo ingresar el detalle, contacte con informatica', 'cerrar', {
                          duration: 2000,
                          verticalPosition: 'top',
                        });
                      }
                    );
                }
                this.addressForm2.reset();

                this.addressForm.reset();
                this.productos.clear();
                this.agregarProducto();

              }
              this.snackBar.open(
                'Ajuste Inventario ingresado con exito',
                'cerrar',
                {
                  duration: 2000,
                  verticalPosition: 'top',
                }
              );
              this.addressForm.reset();
            },
            (error) => {
              this.snackBar.open('No se pudo ingresar el ajuste de inventario, contacte con informatica', 'cerrar', {
                duration: 2000,
                verticalPosition: 'top',
              });
            }
          );
           }else {
            this.snackBar.open('El formulario debe ser completado correctamente', 'Cerrar', {
              duration: 2000,
              verticalPosition: 'top',
            });
          }
    } else {
      this.snackBar.open('No ha ingresado ningún producto', 'cerrar', {
        duration: 3000,
        verticalPosition: 'top',
      });
    }
  }
/*
  onSubmit() {
    
    switch (this.formParent.status) {
      //Si el formulario esta correcto
      case 'VALID':
        const refTransferencia = this.formParent.get('DetalleTransferencia') as FormArray;
        const cantidadTransferencia = refTransferencia.value[0].cantidad;
        const verificar = this.verificarCantidadTransferencia(cantidadTransferencia);
        switch (verificar) {
          case (true):
            Swal.fire({
              icon: 'warning',
              title: 'MODIFIQUE LA CANTIDAD A TRANSFERIR',
              text: 'NO SE PUEDE TRANSFERIR UNA CANTIDAD IGUAL O MENOR A CERO!',
            });
            break;
          case (false):
            if (this.stockProducto < cantidadTransferencia) {
              Swal.fire({
                icon: 'warning',
                title: 'MODIFIQUE LA CANTIDAD A TRANSFERIR',
                text: 'NO SE PUEDE TRANSFERIR UNA CANTIDAD MAYOR A LA DEL STOCK',
              });
            } else {
              this.formParent.value.idUsuario = this.usuario.id;

              const idBodega_destino = this.formParent.value.bodegaDestino;
              const idBodega_Inicio = this.formParent.value.bodegaInicio;

              for (let index = 0; index < this.bodegas.length; index++) {
                if (this.formParent.value.bodegaInicio != this.formParent.value.bodegaDestino) {
                  if (this.formParent.value.bodegaInicio == this.bodegas[index][1]) {
                    this.formParent.value.bodegaInicio = this.bodegas[index][0];
                    this.formParent.value.idBodega = this.bodegas[index][1];
                  }
                  if (this.formParent.value.bodegaDestino == this.bodegas[index][1]) {
                    this.formParent.value.bodegaDestino = this.bodegas[index][0];
                  }
                } else {
                  return;
                }

              }
              this.TransferenciasService
                .create(this.formParent.value)
                .pipe()
                .subscribe(
                  (data) => {
                    const refTransferencia = this.formParent.get('DetalleTransferencia') as FormArray;
                    const idProducto_transferir = refTransferencia.value[0].idProductoServicio;
                    const cantidadTransferencia = refTransferencia.value[0].cantidad;

                    this.TransferenciasService
                      .getProductoStock(idBodega_destino, idProducto_transferir).pipe()
                      .subscribe((resp => {
                        if (resp == null) {
                          this.cantidadInicialDestino = 0;
                        } else {
                          this.cantidadInicialDestino = resp.cantidad;
                        }
                        this.nuevoStockDestino = this.cantidadInicialDestino + cantidadTransferencia;
                        this.nuevoStockActual = this.cantidadInicial - cantidadTransferencia;
                        this.UpdateStock.patchValue({
                          bodegaInicio: idBodega_Inicio,
                          bodegaDestino: idBodega_destino,
                          idProductoServicio: idProducto_transferir,
                          agregarStock: this.nuevoStockDestino,
                          restarStock: this.nuevoStockActual,
                          cantidad: cantidadTransferencia,
                        })
                        this.TransferenciasService
                          .updateStock(this.UpdateStock.value).pipe()
                          .subscribe((resp => {
                            //console.log(resp)
                          }));
                        refTransferencia.clear();
                        this.formParent.reset();
                        this.TransferenciasService.addResultBodega(null);
                      }));
                  },
                  (error) => {
                    console.log('Hay un error con la insercion de datos', error)
                  }
                );
            }
        }


        break;
      //Si el formulario es erroneo 
      case 'INVALID':
        this.formParent.markAllAsTouched()
        break;
      default:
        break;
    }

  }
  */

  

}
