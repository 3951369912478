import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper'
import { HttpClient } from '@angular/common/http'
import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { DomSanitizer } from '@angular/platform-browser'
import { Empresa } from '@app/_models/shared/empresa'
import { Sucursal } from '@app/_models/shared/sucursal'
import { Usuario } from '@app/_models/shared/usuario'
import { HostalService } from '@app/_pages/hostal/hostal.service'
import { EmpresaSharedService } from '@app/_pages/shared/shared-services/empresa-shared.service'
import { SucursalSharedService } from '@app/_pages/shared/shared-services/sucursal-shared.service'
import { environment } from '@environments/environment'
import { first } from 'rxjs/operators'

@Component({
  selector: 'app-hostal-egresos-acciones',
  templateUrl: './hostal-egresos-acciones.component.html',
  styleUrls: ['./hostal-egresos-acciones.component.scss'],
  providers: [
    { provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true } }
  ]
})
export class HostalEgresosAccionesComponent implements OnInit {
  @Output() formularioListo = new EventEmitter<string>()
  usuario: Usuario = JSON.parse(localStorage.getItem('usuario') + '')

  panelOpenState = false
  clickButton = true
  idEgresoHostal: any
  sucursales: Sucursal[]
  tiposPagos: string[]
  grupos: string[] = []
  tiposEgresos: string[]
  dataArrayImg = []
  idEmpresa = 1;
  empresa = new Empresa();
  gastosFijo: string[];
  addBanc: Boolean = true;
  addt: Boolean= true;
  selectedTipoPago: string;
  selectedTipoegreso: string;

  form!: FormGroup

  addressForm = this.fb.group({
    fecha: [''],
    monto: [''],
    tipoEgreso: [''],
    descripcion: [''],
    responsable: [''],
    idSucursal: [''],
    tipoPago: [''],
    grupoGastos: [''],
    archivos: null
  })

  public archivos: any = []
  public previsualizacion: string
  public loading: boolean

  constructor(
    private fb: FormBuilder,
    private hostalService: HostalService,
    private snackBar: MatSnackBar,
    private sucursalService: SucursalSharedService,
    private sanitizer: DomSanitizer,
    private empresaService: EmpresaSharedService,
    private http: HttpClient
  ) {
    this.sucursales = this.sucursalService.sucursalListValue
    this.tiposPagos = [
      'Efectivo',
      'Tarjeta de debito',
      'Tarjeta de credito',
      'Transferencia',
      'Cheque',
      'Pac'
    ]
    this.grupos = [
      'TRANSFERENCIAS RENT A CAR',
      'TRANSFERENCIAS HOSTAL',
      'TRANSFERENCIAS GRUPO FIRMA',
      'TRANSFERENCIAS FIRMA ABOGADOS',
      'TRANSFERENCIAS IMPORTADORA',
      'TRANSFERENCIAS EXPORTADORA',
      'TRANSFERENCIAS CAMPO',
      'TRANSFERENCIAS INMOBILIARIA',
      'TRANSFERENCIAS LUBRICENTRO',
      'TRANSFERENCIAS GASTO PERSONAL',
      'TRANSFERENCIAS PRESTAMOS ENTRE EMPRESAS'
    ]
    this.tiposEgresos = [
      'Gastos',
      'Costos',
      'Remuneraciones',
      'Bancarios',
      'Impuestos',
      'Inversiones',
      'Gastos Fijos'
    ]
  }

  ngOnInit(): void {
    this.idEgresoHostal = localStorage.getItem('idEgresoHostal')
    this.getEgresoHostal()
    this.getEmpresa(this.idEmpresa);
    this.addBanc = false;
    this.addt = false;
  }

  getEmpresa(id: number): any {
    this.empresaService
      .getByIdWithSucursales(id)
      .pipe(first())
      .subscribe((x) => {
        x.Sucursals = Object.values(x.Sucursals).sort((a, b) => (a.razonSocial > b.razonSocial) ? 1 : -1);
        this.empresa = x;
      });
  }

  get f() {
    return this.addressForm.controls
  }

  closeDialog() {
    this.hostalService.closeDialogModal()
    window.location.reload()
  }

  getEgresoHostal() {
    this.hostalService
      .getByIdEgreso(this.idEgresoHostal)
      .pipe(first())
      .subscribe((x: any) => {
        if (x[0].id == this.idEgresoHostal) {
          this.f.fecha.setValue(x[0].fecha)
          this.f.monto.setValue(x[0].monto)
          this.f.tipoEgreso.setValue(x[0].tipoEgreso)
          this.f.descripcion.setValue(x[0].descripcion)
          this.f.responsable.setValue(x[0].responsable)
          this.f.tipoPago.setValue(x[0].tipoPago)
          this.f.grupoGastos.setValue(x[0].grupoGastos)
          this.f.archivos.setValue(x[0].archivos)
          this.f.idSucursal.setValue(x[0].idSucursal)
          this.f.numeroCuenta.setValue(x[0].numeroCuenta)
        }
        this.previsualizacion = x[0].archivos
      })
  }
  //=====> Actualiza la Imagen
  agregarIMG() {
    if (confirm('Seguro que desea actulizar IMG de Ingreso')) {
      this.hostalService
        .getByIdEgresoSinBase64(this.idEgresoHostal)
        .pipe(first())
        .subscribe((x: any) => {
          if (x[0].id == this.idEgresoHostal) {
            const data = x[0]
            this.addressForm.value.fecha = data.fecha
            this.addressForm.value.monto = data.monto
            this.addressForm.value.tipoEgreso = data.tipoEgreso
            this.addressForm.value.descripcion = data.descripcion
            this.addressForm.value.responsable = data.responsable
            this.addressForm.value.tipoPago = data.tipoPago
            this.addressForm.value.grupoGastos = data.grupoGastos
            this.addressForm.value.idSucursal = data.idSucursal
            this.addressForm.value.archivos = this.dataArrayImg
            this.hostalService
              .updateEgresoHostal(this.idEgresoHostal, this.addressForm.value)
              .pipe(first())
              .subscribe(
                (data) => {
                  this.snackBar.open(
                    'Imagen actulizada correctamente!.',
                    'Cerrar',
                    {
                      duration: 2000,
                      verticalPosition: 'top'
                    }
                  )
                  this.addressForm.reset()
                  this.hostalService.closeDialogModal()
                  window.location.reload()
                },
                (error) => {
                  this.snackBar.open(
                    'No se pudo editar el Ingreso de Hostal, favor contactar a Soporte.',
                    'Cerrar',
                    {
                      duration: 2000,
                      verticalPosition: 'top'
                    }
                  )
                }
              )
          }
        })
    }
  }

  capturarFile(event) {
    this.archivos = []
    this.dataArrayImg = []
    const archivoCapturado = event.target.files[0]
    const extension = archivoCapturado.name.split('.').pop()
    const nuevoNombre = 'egresoHostal' + Date.now() + '.' + extension
    this.dataArrayImg.push(nuevoNombre)
    const archivoNuevo = new File([archivoCapturado], nuevoNombre, {
      type: archivoCapturado.type
    })
    this.extraerBase64(archivoNuevo).then((imagen: any) => {
      this.previsualizacion = imagen.base
    })
    this.archivos.push(archivoNuevo)
    this.clickButton = false
  }

  extraerBase64 = async ($event: any) =>
    new Promise((resolve, reject) => {
      try {
        const unsafeImg = window.URL.createObjectURL($event)
        const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg)
        console.log(image)
        const reader = new FileReader()
        reader.readAsDataURL($event)
        reader.onload = () => {
          resolve({
            base: reader.result
          })
        }
        reader.onerror = (error) => {
          resolve({
            base: null
          })
        }
      } catch (e) {
        return null
      }
    })

  subirIMG() {
    try {
      this.loading = true
      const formularioDeDatos = new FormData()
      this.archivos.forEach((archivo) => {
        formularioDeDatos.append('File', archivo)
      })
      this.http
        .post(
          `${environment.apiUrl}/egresoHostal/Upload/EgresoHostal`,
          formularioDeDatos
        )
        .subscribe((res) => {
          this.loading = false
          this.snackBar.open(`Imagen subida  con exito`, 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
        })
    } catch (e) {
      this.loading = false
      this.snackBar.open(`Imagen subida sin exito`, 'Cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
      console.log('ERROR AL SUBIR IMG', e)
    }
  }
  //=====> Envia formulario de actualizacion
  onSubmit() {
    if (confirm('Seguro que desea actualizar ingresos de Hostal :')) {
      this.hostalService
        .getByIdEgresoSinBase64(this.idEgresoHostal)
        .pipe(first())
        .subscribe((x: any) => {
          const nombreArchivo = x[0].archivos
          const JsonNombreArchivo = JSON.parse(nombreArchivo)
          if (this.dataArrayImg[0] === undefined) {
            this.addressForm.value.archivos = JsonNombreArchivo
          } else {
            this.addressForm.value.archivos = this.dataArrayImg
          }
          switch (this.addressForm.status) {
            case 'VALID':
              this.subirIMG()
              this.hostalService
                .updateEgresoHostal(this.idEgresoHostal, this.addressForm.value)
                .pipe(first())
                .subscribe(
                  (data) => {
                    this.snackBar.open(
                      'Egreso Hostal editado con exito',
                      'Cerrar',
                      { duration: 2000, verticalPosition: 'top' }
                    )
                    this.addressForm.reset()
                    this.hostalService.closeDialogModal()
                    window.location.reload()
                  },
                  (error) => {
                    this.snackBar.open(
                      'No se pudo editar el Egreso de Hostal, favor contactar a Soporte.',
                      'Cerrar',
                      {
                        duration: 2000,
                        verticalPosition: 'top'
                      }
                    )
                  }
                )
              break
            case 'INVALID':
              this.snackBar.open(
                'El formulario debe ser completado.',
                'Cerrar',
                {
                  duration: 2000,
                  verticalPosition: 'top'
                }
              )
              break

            default:
              break
          }
        })
    }
  }
}