import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '@environments/environment';
import { DetalleTransferencia } from '../../../../_models/inventario/transferencia/DetalleTransferencia';

@Injectable({
  providedIn: 'root'
})
export class DetalleTransferenciaService {

  constructor(private snackBar: MatSnackBar, private http: HttpClient) { 

  }

  createDetalleTransferencia(detalleTransferencia: DetalleTransferencia){
    console.log("detallee",detalleTransferencia)
    return this.http.post(`${environment.apiUrl}/transferencia/crear/`,detalleTransferencia);
}
}
