import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper'
import { HttpClient } from '@angular/common/http'
import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { MatSnackBar } from '@angular/material/snack-bar'
import { DomSanitizer } from '@angular/platform-browser'
import { Empresa } from '@app/_models/shared/empresa'
import { Sucursal } from '@app/_models/shared/sucursal'
import { Usuario } from '@app/_models/shared/usuario'
import { HostalService } from '@app/_pages/hostal/hostal.service'
import { EmpresaSharedService } from '@app/_pages/shared/shared-services/empresa-shared.service'
import { SucursalSharedService } from '@app/_pages/shared/shared-services/sucursal-shared.service'
import { environment } from '@environments/environment'
import { first } from 'rxjs/operators'

@Component({
  selector: 'app-hostal-ingresos-acciones',
  templateUrl: './hostal-ingresos-acciones.component.html',
  styleUrls: ['./hostal-ingresos-acciones.component.scss'],
  providers: [
    { provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true } }
  ]
})
export class HostalIngresosAccionesComponent implements OnInit {
  @Output() formularioListo = new EventEmitter<string>()
  usuario: Usuario = JSON.parse(localStorage.getItem('usuario') + '')

  panelOpenState = false
  clickButton = true
  idIngresoHostal: any
  sucursales: Sucursal[]
  tiposIngresos: any[] = []
  defTiposIngreso: string[]
  tiposCliente: string[]
  referenciaCliente: string[]
  tiposPagos: string[]
  estadoPagos: string[]
  tipoDocumento: string[]
  dataArrayImg = []
  idEmpresa = 1;
  empresa = new Empresa();
  tipoIngreso: string
  formattedValue: string;
  monto: any

  public archivos: any = []
  public previsualizacion: string
  public loading: boolean

  addressForm = this.fb.group({
    idSucursal: [null],
    descripcion: [''],
    fecha: [''],
    monto: [''],
    cliente: [''],
    tipoCliente: [''],
    tipoPago: [''],
    referenciaCliente: ['', ],
    estadoPago: ['',],
    nDocumento: [''],
    nAutorizacion: [''],
    tipoDocumento: [null,],
    tipoIngreso: [null],
    archivos: null
  })

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private hostalService: HostalService,
    private sucursalService: SucursalSharedService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private empresaService: EmpresaSharedService,
  ) {
    //this.sucursales = this.sucursalService.sucursalListValue
    this.defTiposIngreso = [
      'Alojamiento',
      'Desayuno',
      'Almuerzo',
      'Cena',
      'Consumo Bebidas',
      'Consumo Varios'
    ]
    this.tiposCliente = ['Particular', 'Empresa']
    this.referenciaCliente = ['Llamada', 'Booking', 'Correo', 'PaginaWeb', 'Facebook', 'Directo a Hostal']
    this.tiposPagos = [
      'Efectivo',
      'Tarjeta de debito',
      'Tarjeta de credito',
      'Transferencia',
      'Cheque'
    ]
    this.estadoPagos = ['PENDIENTE', 'PAGADO']
    this.tipoDocumento = ['Boleta', 'Factura']
  }

  ngOnInit(): void {
    this.idIngresoHostal = localStorage.getItem('idIngresoHostal')
    this.getIngresoHostal()
    this.getEmpresa(this.idEmpresa);
  }

  getEmpresa(id: number): any {
    this.empresaService
      .getByIdWithSucursales(id)
      .pipe(first())
      .subscribe((x) => {
        x.Sucursals = Object.values(x.Sucursals).sort((a, b) => (a.razonSocial > b.razonSocial) ? 1 : -1);
        this.empresa = x;
      });
  }

  get f() {
    return this.addressForm.controls
  }

  closeDialog() {
    this.hostalService.closeDialogModal()
    window.location.reload()
  }

  //====> Llama a la API
  getIngresoHostal() {
    this.hostalService
      .getByIdIngreso(this.idIngresoHostal)
      .pipe(first())
      .subscribe((x: any) => {
        //console.log(x[0])
        if (x[0].id == this.idIngresoHostal) {
          this.f.descripcion.setValue(x[0].descripcionIngreso)
          this.f.fecha.setValue(x[0].fecha)
          this.f.monto.setValue(x[0].monto.toLocaleString('es-CL'))
          this.f.cliente.setValue(x[0].cliente)
          this.f.tipoCliente.setValue(x[0].tipoCliente)
          this.f.estadoPago.setValue(x[0].estadoPago)
          this.f.tipoPago.setValue(x[0].tipoPago)
          this.f.tipoDocumento.setValue(x[0].tipoDocumento)
          this.f.referenciaCliente.setValue(x[0].referenciaCliente)
          this.f.nDocumento.setValue(x[0].nDocumento)
          this.f.nAutorizacion.setValue(x[0].nAutorizacion)
          this.f.idSucursal.setValue(x[0].idSucursal)
          this.f.tipoIngreso.setValue(x[0].tipoIngreso)
          this.f.archivos.setValue(x[0].archivos)
        }
        this.previsualizacion = x[0].archivos
      })
  }
  //Actualiza imagen en el fomulario
  agregarIMG() {
    if (confirm('Seguro que desea actulizar IMG de Ingreso')) {
      this.hostalService
        .getByIdIngresoSinBase64(this.idIngresoHostal)
        .pipe(first())
        .subscribe((x: any) => {
          if (x[0].id == this.idIngresoHostal) {
            const data = x[0]
            this.addressForm.value.descripcion = data.descripcion
            this.addressForm.value.fecha = data.fecha
            this.addressForm.value.monto = data.monto
            this.addressForm.value.cliente = data.cliente
            this.addressForm.value.tipoCliente = data.tipoCliente
            this.addressForm.value.referenciaCliente = data.referenciaCliente
            this.addressForm.value.nDocumento = data.nDocumento
            this.addressForm.value.tipoDocumento = data.tipoDocumento
            this.addressForm.value.nAutorizacion = data.nAutorizacion
            this.addressForm.value.idSucursal = data.idSucursal
            this.addressForm.value.tipoIngreso = data.tipoIngreso
            this.addressForm.value.archivos = this.dataArrayImg
            this.hostalService
              .updateIngresoHostal(this.idIngresoHostal, this.addressForm.value)
              .pipe(first())
              .subscribe(
                (data) => {
                  this.snackBar.open(
                    'Imagen actulizada correctamente!.',
                    'Cerrar',
                    {
                      duration: 2000,
                      verticalPosition: 'top'
                    }
                  )
                  this.addressForm.reset()
                  this.hostalService.closeDialogModal()
                  window.location.reload()
                },
                (error) => {
                  this.snackBar.open(
                    'No se pudo editar el Ingreso de Hostal, favor contactar a Soporte.',
                    'Cerrar',
                    {
                      duration: 2000,
                      verticalPosition: 'top'
                    }
                  )
                }
              )
          }
        })
    }
  }

  capturarFile(event) {
    this.archivos = []
    this.dataArrayImg = []
    const archivoCapturado = event.target.files[0]
    const extension = archivoCapturado.name.split('.').pop()
    const nuevoNombre = 'ingresoHostal' + Date.now() + '.' + extension
    this.dataArrayImg.push(nuevoNombre)
    const archivoNuevo = new File([archivoCapturado], nuevoNombre, {
      type: archivoCapturado.type
    })
    this.extraerBase64(archivoNuevo).then((imagen: any) => {
      this.previsualizacion = imagen.base
    })
    this.archivos.push(archivoNuevo)
    this.clickButton = false
  }

  extraerBase64 = async ($event: any) =>
    new Promise((resolve, reject) => {
      try {
        const unsafeImg = window.URL.createObjectURL($event)
        const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg)
        console.log(image)
        const reader = new FileReader()
        reader.readAsDataURL($event)
        reader.onload = () => {
          resolve({
            base: reader.result
          })
        }
        reader.onerror = (error) => {
          resolve({
            base: null
          })
        }
      } catch (e) {
        return null
      }
    })

  subirIMG() {
    try {
      this.loading = true
      const formularioDeDatos = new FormData()
      this.archivos.forEach((archivo) => {
        formularioDeDatos.append('File', archivo)
      })
      this.http
        .post(
          `${environment.apiUrl}/ingresoHostal/Upload/IngresoHostal`,
          formularioDeDatos
        )
        .subscribe((res) => {
          this.loading = false
          this.snackBar.open(`Imagen subida  con exito`, 'Cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
        })
    } catch (e) {
      this.loading = false
      this.snackBar.open(`Imagen subida sin exito`, 'Cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
      console.log('ERROR AL SUBIR IMG', e)
    }
  }

  //=====> Envia formulario de actualizacion
  onSubmit() {
    if (confirm('Seguro que desea actualizar ingresos de Hostal :')) {
      this.hostalService
        .getByIdIngresoSinBase64(this.idIngresoHostal)
        .pipe(first())
        .subscribe((x: any) => {
          const nombreArchivo = x[0].archivos
          const JsonNombreArchivo = JSON.parse(nombreArchivo)
          if (this.dataArrayImg[0] === undefined) {
            this.addressForm.value.archivos = JsonNombreArchivo
          } else {
            this.addressForm.value.archivos = this.dataArrayImg
          }
         // console.log(this.addressForm.value)
          switch (this.addressForm.status) {
            case 'VALID':
             this.subirIMG()
              this.hostalService
                .updateIngresoHostal(
                  this.idIngresoHostal,
                  this.addressForm.value
                )
                .pipe(first())
                .subscribe(
                  (data) => {
                    this.snackBar.open(
                      'Ingreso Hostal editado con exito',
                      'Cerrar',
                      { duration: 2000, verticalPosition: 'top' }
                    )
                    this.addressForm.reset()
                    this.hostalService.closeDialogModal()
                    window.location.reload()
                  },
                  (error) => {
                    this.snackBar.open(
                      'No se pudo editar el Ingreso de Hostal, favor contactar a Soporte.',
                      'Cerrar',
                      {
                        duration: 2000,
                        verticalPosition: 'top'
                      }
                    )
                  }
                )
              break
            case 'INVALID':
              this.snackBar.open(
                'El formulario debe ser completado.',
                'Cerrar',
                {
                  duration: 2000,
                  verticalPosition: 'top'
                }
              )
              break

            default:
              break
          }
        })
    }
  }
  //===> Checkbox
  chequearTipoIngreso(e: MatCheckboxChange) {
    if (e.checked) {
      this.tiposIngresos.push(e.source.name)
    }
    if (!e.checked) {
      this.tiposIngresos = this.tiposIngresos.filter(
        (data) => data !== e.source.name
      )
    }
  }
}
