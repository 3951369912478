<h1 class="cliente">
    Cliente: {{ nombreClienteLocal }} </h1>
<mat-grid-list cols="2" class="grid">
    <!-- ********************** Formulario Contratos ********************** -->
    <mat-grid-tile class="grid-body">
        <form [formGroup]="addressFormContrato" novalidate class="mat-elevation-z10">
            <mat-card class="shipping-card">
                <mat-card-header>
                    <h1> Agregar contrato</h1>
                </mat-card-header>
                <br><br>
                <mat-card-content>
                    <div class="row">
                        <!-- select fecha -->
                        <div class="col">
                            <mat-form-field class="full-width">
                                <mat-label>Seleccionar fecha</mat-label>
                                <input matInput formControlName="fechaContrato" [matDatepicker]="picker" />
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-error *ngIf="addressFormContrato.controls['fechaContrato'].hasError('required')">
                                    fecha es <strong>requerido</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <!-- sucursales -->
                        <div class="col">
                            <mat-form-field class="full-width">
                                <mat-select placeholder="Sucursales" formControlName="idSucursal">
                                    <mat-option *ngFor="let s of empresa.Sucursals" value="{{ s.id }}">
                                        {{ s.razonSocial }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="addressFormContrato.controls['idSucursal'].hasError('required')">
                                    sucursal es <strong>requrido</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <!-- Monto -->
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input matInput placeholder="Monto Total Servicio" formControlName="montoContrato" type="number" />
                                <mat-error *ngIf="addressFormContrato.controls['montoContrato'].hasError('required')">
                                    este campo es <strong>requerido</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <!-- N° contrato -->
                        <div class="col">
                            <mat-form-field class="full-width">
                                <input matInput placeholder="Número contrato" formControlName="nContrato" type="number" />
                                <mat-error *ngIf="addressFormContrato.controls['nContrato'].hasError('required')">
                                    este campo es <strong>requerido</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <!-- Subir PDF -->
                        <div class="col">
                            <label for="pdfContrato" class="custom-upload-label"> </label>
                            <button mat-raised-button color="primary" class="custom-upload-button" (click)="fileInput.click()">
                              Seleccionar PDF
                            </button>
                            
                            <input type="file" #fileInput (change)="onFileChange($event)" accept=".pdf" style="display: none;" />
                          </div>
                          
                          <div class="row">
                            <div *ngIf="pdfPreview">
                              <embed [src]="pdfPreview" type="application/pdf" width="200" height="300">
                            </div>
                          </div>
                        </div>
                </mat-card-content>
                <!-- botón enviar -->
                <button mat-raised-button color="primary" (click)="validarContrato()">
                            <mat-icon>send</mat-icon> Enviar
                    </button>
            </mat-card>
        </form>
    </mat-grid-tile>

    <!-- ********************** Formulario Cuotas ********************** -->
    <mat-grid-tile>
        <form [formGroup]="addressFormCuotas" novalidate class="mat-elevation-z10">
            <mat-dialog-content>
                <mat-card class="shipping-card">
                    <mat-card-header>
                        <h1>
                            Contrato : {{ contrato.id }} Monto total: {{ contrato.montoContrato }}
                        </h1>
                    </mat-card-header>
                    <br><br>
                    <mat-card-content>
                        <div class="row">
                            <!-- Rut Cliente -->
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <mat-label>Seleccionar fecha</mat-label>
                                    <input matInput formControlName="fechaCuota" [matDatepicker]="picker2" />
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                    <mat-error *ngIf="addressFormCuotas.controls['fechaCuota'].hasError('required')">
                                        fecha es <strong>requerido</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <mat-form-field class="full-width">
                                    <input matInput placeholder="Monto Cuota" formControlName="montoCuota" type="number" />
                                    <mat-error *ngIf="addressFormCuotas.controls['montoCuota'].hasError('required')">
                                        este campo es <strong>requerido</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <div>
                                    <!-- botón enviar -->
                                    <button mat-raised-button color="primary" (click)="agregarCuotas()">
                                        <mat-icon>paid</mat-icon> Agregar
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!-- Tabla -->
                        <div class="row">
                            <div class="col">
                                <h1>Cuotas Registradas</h1>
                                <div class="table-responsive">
                                    <table mat-table [dataSource]="dataSource" class="mat-elevation-z10">

                                        <ng-container matColumnDef="fecha">
                                            <th mat-header-cell *matHeaderCellDef> Fecha </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ element.fechaPago | date: 'dd/MM/yyyy' }}
                                            </td>
                                        </ng-container>

                                        <ng-container matColumnDef="montoCuotaTabla">
                                            <th mat-header-cell *matHeaderCellDef> Monto </th>
                                            <td mat-cell *matCellDef="let element">
                                                {{ element.montoCuota }}
                                            </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

                                        <!-- Row shown when there is no matching data. -->
                                        <tr class="mat-row" *matNoDataRow>
                                            <td class="mat-cell" colspan="4">No existen coincidencias</td>
                                        </tr>
                                    </table>
                                </div>
                                <h1>Monto Por Asignar : {{ saldoPendiente }}</h1>
                            </div>
                        </div>
                    </mat-card-content>
                    <!-- botón enviar -->
                    <button mat-raised-button color="primary" (click)="guardarContrato()">
                        <mat-icon>note_add</mat-icon> Generar Contrato
                </button>
                </mat-card>
            </mat-dialog-content>
        </form>
    </mat-grid-tile>
</mat-grid-list>