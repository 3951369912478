<div class="mat-elevation-z8">
  <mat-card>
    <mat-toolbar color="primary">
      <span>Solicitudes O/C</span>
      <button
        style="margin-left: 1rem; margin-bottom: 3px"
        mat-raised-button
        color="accent"
        (click)="actualizar()"
      >
        Actualizar tabla <mat-icon>autorenew_white_18dp</mat-icon>
      </button>
      <br />

      <button
        mat-raised-button
        color="accent"
        style="margin-left: 1rem; margin-bottom: 3px"
        (click)="exportAsXLSX()"
      >
        Exportar a Excel <mat-icon>description_white_18dp</mat-icon>
      </button>

      <div class="spacer"></div>

      <div class="button-container">
        <button [disabled]="rolUsuario !='Administrador'" (click)="estadoAutorizado()" mat-raised-button color="accent" style="margin-right: 1rem; margin-bottom: 3px" >
          Autorizada <mat-icon><span class="material-symbols-outlined">
            task_alt
            </span></mat-icon>

        </button>

        <button [disabled]="rolUsuario !='Administrador'" (click)="estadoRechazado()" mat-raised-button color="warn" style="margin-right: 1rem; margin-bottom: 3px" >
          Rechazada <mat-icon> <span class="material-symbols-outlined">
            disabled_by_default
            </span> </mat-icon>
        </button>
      </div>

    </mat-toolbar>
  </mat-card>
  <br />

  <mat-form-field appearance="standard">
    <mat-label>Buscar</mat-label>
    <input
      matInput
      (keyup)="applyFilter($event)"
      placeholder="Ingresa Nombre"
      #input
    />
  </mat-form-field>

  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matTableExporter
    #exporter="matTableExporter"
    matSortActive="id"
    matSortDirection="desc"
    class="mat-elevation-z10"
  >
    <!-- SELECCCION -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="showRowJSON($event, row)"

          [checked]="selection.isSelected(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>
<!-- (change)="$event ? selection.toggle(row) : null" -->
    <!-- id Columna -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>id</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>

    <!-- Nombre  Columna -->
    <ng-container matColumnDef="nombreTransferencia">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
      <td mat-cell *matCellDef="let element">
        {{ element.nombreTransferencia }}
      </td>
    </ng-container>

    <!-- rut Columna -->
    <ng-container matColumnDef="rut">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Rut</th>
      <td mat-cell *matCellDef="let element">
        {{ element.rut }}
      </td>
    </ng-container>

    <!-- Numero Cuenta Columna -->
    <ng-container matColumnDef="NumeroCuenta">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Numero Cuenta</th>
      <td mat-cell *matCellDef="let element">
        {{ element.NumeroCuenta }}
      </td>
    </ng-container>

    <!-- jefeAutoriza Columna -->
    <ng-container matColumnDef="jefeAutoriza">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Solicitado A</th>
      <td mat-cell *matCellDef="let element">
        {{ element.jefeAutoriza }}
      </td>
    </ng-container>

    <!-- Campo Fecha -->
    <ng-container matColumnDef="fechaSolicitud" class="mat-column-date">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        class="limit-width-180"
      >
        <mat-form-field appearance="fill" class="limit-width-180">
          <mat-label>Fecha</mat-label>
          <mat-date-range-input [formGroup]="formFilter" [rangePicker]="picker">
            <input
              matStartDate
              formControlName="start"
              placeholder="Start date"
            />
            <input matEndDate formControlName="end" placeholder="End date" />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error
            *ngIf="formFilter.controls.start.hasError('matStartDateInvalid')"
            >Invalid start date
          </mat-error>
          <mat-error
            *ngIf="formFilter.controls.end.hasError('matEndDateInvalid')"
            >Invalid end date
          </mat-error>
        </mat-form-field>
      </th>
      <td class="centrado" mat-cell *matCellDef="let element">
        {{ element.fechaSolicitud | date : 'dd/MM/yyyy' }}
      </td>
    </ng-container>

    <!-- Estado de la solicitud-->
    <ng-container matColumnDef="estado">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
      <td mat-cell *matCellDef="let element">
        <ng-container
          *ngIf="element.estado == 'Autorizada' || element.estado == 'Aceptada'"
        >
          {{ element.estado }}
          <mat-icon style="-webkit-text-fill-color: green"
            >check_box_white_18dp</mat-icon
          >
        </ng-container>
        <ng-container *ngIf="element.estado == 'Pendiente'">
          {{ element.estado }}
          <mat-icon style="-webkit-text-fill-color: rgb(239, 108, 8)"
            >pending_actions_18dp</mat-icon
          >
        </ng-container>
        <ng-container *ngIf="element.estado == 'Rechazada'">
          {{ element.estado }}
          <mat-icon style="-webkit-text-fill-color: rgb(216, 58, 37)"
            >cancel_white_18dp</mat-icon
          >
        </ng-container>
        <ng-container
          *ngIf="element.estado == 'Pagado' || element.estado == 'Saldado'"
        >
          {{ element.estado }}
          <mat-icon style="-webkit-text-fill-color: rgb(37, 115, 216)"
            >check_box_white_18dp</mat-icon
          >
        </ng-container>
        <ng-container *ngIf="element.estado == 'Fuera de Plazo'">
          {{ element.estado }}
          <mat-icon style="-webkit-text-fill-color: rgb(239, 108, 8)"
            >pending_actions_18dp</mat-icon
          >
        </ng-container>
      </td>
    </ng-container>

    <!-- montoPago Columna -->
    <ng-container matColumnDef="montoPago">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Monto</th>
      <td mat-cell *matCellDef="let element">
        <span matPrefix>$&nbsp;</span
        >{{ element.montoPago | currency : 'CLP' : '' }}
      </td>
    </ng-container>

    <!-- Correo  Columna -->
    <ng-container matColumnDef="correo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Correo</th>
      <td mat-cell *matCellDef="let element">
        {{ element.correo }}
      </td>
    </ng-container>

    <!-- razonRechazo  Columna -->
    <ng-container matColumnDef="razonRechazo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Razon Rechazo</th>
      <td mat-cell *matCellDef="let element">
        {{ element.razonRechazo }}
      </td>
    </ng-container>
    <!-- Motivo Pago -->
    <ng-container matColumnDef="motivoPago">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Motivo Pago</th>
      <td mat-cell *matCellDef="let element">
        {{ element.motivoPago }}
      </td>
    </ng-container>

    <!-- Perfil del Usario -->
    <ng-container matColumnDef="perfilUsuario">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Solicitado Por</th>
      <td mat-cell *matCellDef="let element">
        {{ element.perfilUsuario }}
      </td>
    </ng-container>

    <!-- Jefe Autoriza/Rechza -->
    <ng-container matColumnDef="nombreAutoriza">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Autoriza/Rechaza
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.nombreAutoriza }}
      </td>
    </ng-container>

    <!-- Acciones Columna -->
    <ng-container matColumnDef="acciones">
      <th mat-header-cell *matHeaderCellDef style="text-align: center">
        Acciones
      </th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-mini-fab
          color="primary"
          style="margin-left: 1rem; margin-bottom: 2px"
          (click)="accionesPagoCuentaBancaria(element.id)"
          
        >
          <mat-icon>save_as_white_18dp</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row-with-bottom-line"></tr>
  </table>

  <mat-paginator
    [pageSizeOptions]="[10, 20, 30]"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
    <!-- Alerta "Por pendientes" -->
    <div class="alert" *ngIf="mostrarAlertaPorEmitir">
      Aún existen solicitudes no autorizadas. Por favor, revisar.
      De no ser autorizadas dentro de 30 dias se eliminaran automaticamente
    </div>
    
</div>
