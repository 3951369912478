import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { environment } from '@environments/environment'
import { BehaviorSubject } from 'rxjs';
import { modeloSolicitudBancaria } from '@app/_models/consolidadoBancario/solicitudBancaria'
import { modeloCuentasBancaria } from '@app/_models/consolidadoBancario/cuentasBancaria'
import { HomeAccionesComponent } from './consolidado-bancario-home/home-table/home-acciones/home-acciones.component'
import { PagoAccionesComponent } from './consolidado-bancario-pago/pago-table/pago-acciones/pago-acciones.component'
import { SolicitudTableAccionesComponent } from './consolidado-bancario-solicitud/solictud-table/solicitud-table-acciones/solicitud-table-acciones.component'
//import { GastosFijosCalendarioComponent } from './gastos-fijos/gastos-fijos-calendario/gastos-fijos-calendario.component'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { Observable } from 'rxjs'

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const EXCEL_EXTENSION = '.xlsx'

@Injectable({
  providedIn: 'root'
})

export class ConsolidadoBancarioService {
  constructor(private http: HttpClient, private dialog: MatDialog) {}

  private porEmitirSubject = new BehaviorSubject<boolean>(false);
  porEmitir$ = this.porEmitirSubject.asObservable();

  setPorEmitir(porEmitir: boolean) {
    this.porEmitirSubject.next(porEmitir);
  }

  registrarSolicitudBancaria(modelo: modeloSolicitudBancaria): any {
    return this.http.post(`${environment.apiUrl}/solicitudBancaria`, modelo)
  }

  //Llamar tabla de Solicitud Bancaria
  getAllSolicitudBacaria(): Observable<any> {
    return this.http.get<[]>(`${environment.apiUrl}/solicitudBancaria`)
  }

  registrarCuentaBancaria(modelo: modeloCuentasBancaria): any {
    return this.http.post(`${environment.apiUrl}/formCuentaBancaria`, modelo)
  }

  //Llamar tabla de Cuenta Bancaria
  getAllCuentaBancaria(): any {
    return this.http.get<any[]>(`${environment.apiUrl}/formCuentaBancaria`)
  }

  // Metodo que permite abrir un Dialog (Modal)
  openDialogEditFormCuentaBancaria(): void {
    const dialogRef = this.dialog.open(HomeAccionesComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }
  // Metodo que permite abrir un Dialog (Modal)
  openDialogEditPagoCuentaBancaria(): void {
    const dialogRef = this.dialog.open(PagoAccionesComponent, {})
     //window.location.reload()
    dialogRef.afterClosed().subscribe((res) => {})
  }
  // Metodo que permite abrir un Dialog (Modal)
  openDialogEditSolicitdBancaria(): void {
    const dialogRef = this.dialog.open(SolicitudTableAccionesComponent, {})
    dialogRef.afterClosed().subscribe((res) => {})
  }

  getById(id: any) {
    return this.http.get<any>(`${environment.apiUrl}/formCuentaBancaria/${id}`)
  }
  getByIdPagoBancario(id: any) {
    console.log(id);
    return this.http.get<any>(`${environment.apiUrl}/solicitudBancaria/${id}`)
  }
  /**getByRutEmpresaBancaria(id: any) {
    return this.http.get<any>(`${environment.apiUrl}/formCuentaBancaria/${id}`)
  }**/


  closeDialogModal() {
    this.dialog.closeAll()
    localStorage.removeItem('idformCuentaBancaria')
  }

  //Editar cuenta Bancaria

  updateCuentaBancaria(id: any, params: any[]): any {

    return this.http.put(
      `${environment.apiUrl}/formCuentaBancaria/${id}`,
      params
    )

  }
  //Editar Pago Bancario
  updatePagoBancario(id: any, params: any[]): any {
    console.log("params",params)

    return this.http.put(
      `${environment.apiUrl}/solicitudBancaria/${id}`,
      params
    )


  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json)
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data']
    }
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    })
    this.saveAsExcelFile(excelBuffer, excelFileName)
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE })
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION
    )
  }
}
