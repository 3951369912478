import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HostalService } from '../../hostal.service';
import { EmpresaSharedService } from '@app/_pages/shared/shared-services/empresa-shared.service';
import { Usuario } from '@app/_models/shared/usuario';
import { EgresoHostal } from '@app/_models/hostal/egresoHostal';
import { Sucursal } from '@app/_models/shared/sucursal';
import { Empresa } from '@app/_models/shared/empresa';
import { first } from 'rxjs/operators';
import { formatNumber, restrictNumeric } from '@app/helpers/helper';

@Component({ 
  selector: 'app-hostal-egresos-credito-formulario',
  templateUrl: './hostal-egresos-credito-formulario.component.html',
  styleUrls: ['./hostal-egresos-credito-formulario.component.scss']
})
export class HostalEgresosCreditoFormularioComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private cdref: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private hostalService: HostalService,
    private empresaService: EmpresaSharedService,
  ) {
    this.regiones = [
      'Región de Arica y Parinacota',
      'Región de Tarapacá',
      'Región de Antofagasta',
      'Región de Atacama',
      'Región de Coquimbo',
      'Región de Valparaíso',
      'Región Metropolitana de Santiago',
      'Región del Libertador General Bernardo OHiggins',
      'Región del Maule',
      'Región del Biobío',
      'Región de La Araucanía',
      'Región de Los Ríos',
      'Región de Los Lagos',
      'Región de Aysén',
      'Región de Magallanes'
    ]
   }

  usuario: Usuario = JSON.parse(localStorage.getItem('usuario') + '')
  egresoHostal: EgresoHostal = new EgresoHostal
  sucursales: Sucursal[]
  form!: FormGroup
  public loading: boolean
  numeroCuota: string
  monto: string
  empresa = new Empresa()
  idEmpresa = 1
  usuarioResponsable:String
  regiones:string[] = []


  formCredito = this.fb.group({
    idSucursal: [null, Validators.required],
    fecha: [null, Validators.required],
    tipoEgreso: [null, Validators.required],

    descripcionCredito: [null, Validators.required],
    regiones: [null, Validators.required],
    proyecto: [null],

    numeroCuota: [null],
    monto: [null, Validators.required],
    
    tasaInteres: [null],
    costoAmortizacion: [null],  
  })

  

  ngOnInit(): void {
    this.getEmpresa(this.idEmpresa)
    this.usuarioResponsable = this.usuario.nombre + this.usuario.apellido
  }
  ngAfterContentChecked() {
    this.cdref.detectChanges()
  }

  onSubmit() {
    this.form = this.fb.group(this.formCredito.value)
    if (this.formCredito.status == 'VALID') {
      this.egresoHostal.fecha = this.form.value.fecha
      this.egresoHostal.tipoEgreso = this.form.value.tipoEgreso
      this.egresoHostal.monto = this.form.value.monto.replace(/\./g, '')
      this.egresoHostal.numeroCuota = this.form.value.numeroCuota
      this.egresoHostal.descripcion = this.form.value.descripcionCredito
      this.egresoHostal.idSucursal = this.form.value.idSucursal
      this.egresoHostal.idUsuario = this.usuario.id
      this.egresoHostal.proyecto = this.form.value.proyecto
      this.egresoHostal.region = this.form.value.region
      this.egresoHostal.costoAmortizacion = this.form.value.costoAmortizacion
      this.egresoHostal.tasaInteres = this.form.value


      console.log(this.egresoHostal)
      this.hostalService.createCredito(this.egresoHostal).subscribe(
        (data:any) =>{
          this.snackBar.open('Agregado con exito', 'cerrar', {
            duration: 2000,
            verticalPosition: 'top'
          })
          window.location.reload()
        },
        (error:any) =>{
          this.snackBar.open(error, 'cerrar', {
            duration: 3000,
            verticalPosition: 'top'
          })
        }
      )
    } else {
      this.snackBar.open('Formulario incompleto.', 'Cerrar', {
        duration: 2000,
        verticalPosition: 'top'
      })
    }
  }

  getEmpresa(id: number): any {
    this.empresaService
      .getByIdWithSucursales(id)
      .pipe(first())
      .subscribe((x) => {
        x.Sucursals = Object.values(x.Sucursals).sort((a, b) =>
          a.razonSocial > b.razonSocial ? 1 : -1
        ) //sort ordena alfabeticamente las sucursales
        this.empresa = x
      })
  }

  onKeyDown(event: any) {
    if (!restrictNumeric(event)) {
      event.preventDefault()
    }
  }
  montoChange(valor: number) {
    this.monto = formatNumber(valor)
  }

}
